import { Form, Row, Col } from 'react-bootstrap'
import style from './StampSelect.module.css'
import { useState, useEffect } from 'react'
const Stamp = (props) => {
  const [selected, setSelected] = useState(props.selectedStamp)
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  useEffect(() => {
    if (props.Error !== '') {
      setError(true)
      setErrMsg(props.Error)
    } else {
      setError(false)
    }
  }, [props.Error])
  const Handler = (stamp, index) => {
    const id = stamp.id
    props.stampHandler(id)
    setSelected(id)
  }

  return (
    <>
      <Form.Group>
        <Row>
          <Col className={style.titleRow}>
            <Form.Label className={style.titleLabel}>
              {props.label}
              {!props.required
                ? null
                : (
                  <span id="email-error" className={style.errorAsterisk}>
                    *
                  </span>
                )}
            </Form.Label>
          </Col>
          <Col sm="10">
            <Col className={style.logos}>
              {/*  */}
              {props.data.map((stamp, index) => {
                return (
                  <div
                    key={index}
                    className={selected === stamp.id ? style.imgContainerSelected : style.imgContainer}
                    onClick={() => {
                      setErrMsg('')
                      return Handler(stamp, index)
                    }}>
                    <img className={style.img} src={stamp.thumbnail_url} alt="" />
                  </div>
                )
              })}
            </Col>
            <Row className={style.errContainer}>
              {!error
                ? null
                : (
                  <span id='integer-error' className={style.Error}>
                    {errMsg}
                  </span>
                )}
            </Row>
          </Col>
        </Row>
      </Form.Group>

      <hr />
    </>
  )
}

export default Stamp
