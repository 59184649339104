import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Description from '../../Atoms/InputFields/Description'
import DragDrop from '../../Atoms/InputFields/DragDrop'
import Title from '../../Atoms/InputFields/Title'
import Points from '../../Atoms/InputFields/Points'
import SaveBtn from '../../Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import CancelBtn from '../../Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import { useNavigate } from 'react-router-dom'
import apiServices from '../../../Utiles/API.service'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import { toast } from 'react-toastify'
import {addBtnAccessHandler} from '../../../Helpers/Helper'


// Add new component component
const NewRewards = (props) => {
  // title, points, description, logoDrag state
  const [title, setTitle] = useState('')
  const [errTitle, setErrTitle] = useState('')
  const [points, setPoints] = useState('')
  const [errPoints, setErrPoints] = useState('')
  const [description, setDescription] = useState('')
  const [errDescription, setErrDescription] = useState('')
  const [dragAction, setDragAction] = useState(false)
  const [image, setImage] = useState('')
  const [imageAction, setImageAction] = useState(false)
  const [errImage, setErrImage] = useState('')
  const [file, setFile] = useState('')
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const navigate = useNavigate()
  const headers = { Token: window.localStorage.getItem('authToken') }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  // handel drag event
  const handelDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragAction(true)
    } else if (e.type === 'dragleave') {
      setDragAction(false)
    }
  }

  // trigger when file is dropped
  // this function reset the dragAction to false
  const handelDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragAction(false)
  }

  // trigger when file is selected with clicked
  const handelChanges = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0])
      setImage(URL.createObjectURL(e.target.files[0]))
    }
    if (!image) {
      setImageAction(true)
      setErrImage('')
    } else if (image) {
      setImageAction(false)
    }
  }

  // handel title event
  const handelTitle = (e) => {
    setTitle(e.target.value)
    setErrTitle('')
  }

  // handel points event
  const handelPoints = (e) => {
    setPoints(e)
    setErrPoints('')
  }

  // handel description event
  const handelDescription = (e) => {
    setDescription(e.target.value)
    setErrDescription('')
  }

  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault()
    if (file) {
      const formData = new FormData()
      formData.append('title', title && title !== '' ? title : '')
      formData.append('points', points && points !== '' ? points : '')
      formData.append('description', description && description !== '' ? description : '')
      formData.append('logo', file && file !== '' ? file : '')

      apiServices.post('api/v1/coupon/', formData, headers, showLoader, (responseData, responseError) => {
        if (!responseError) {
          navigate('/admin/coupon')
          setTitle('')
          setPoints('')
          setDescription('')
          setImage()
          setImageAction(false)
          setFile('')
          toast.success('Reward added successfully.', {
            autoClose: 4000
          })
        } else if (responseError) {
          if (responseError.title && responseError.title.length !== 0) {
            setErrTitle(responseError.title[0])
          }
          if (responseError.logo && responseError.logo.length !== 0) {
            setErrImage(responseError.logo[0])
          }
          if (responseError.description && responseError.description.length !== 0) {
            setErrDescription(responseError.description[0])
          }
          if (responseError.points && responseError.points.length !== 0) {
            setErrPoints(responseError.points[0])
          }
        }
      })
    } else {
      const formData = new FormData()
      formData.append('title', title && title !== '' ? title : '')
      formData.append('points', points && points !== '' ? points : '')
      formData.append('description', description && description !== '' ? description : '')
      apiServices.post('api/v1/coupon/', formData, headers, showLoader, (responseData, responseError) => {
        if (!responseError) {
          navigate('/admin/coupon')
          setTitle('')
          setPoints('')
          setDescription('')
          setImage()
          setImageAction(false)
          setFile('')
          toast.success('Reward added successfully.', {
            autoClose: 4000
          })
        } else if (responseError) {
          if (responseError.title && responseError.title.length !== 0) {
            setErrTitle(responseError.title[0])
          }
          if (responseError.logo && responseError.logo.length !== 0) {
            setErrImage(responseError.logo[0])
          }
          if (responseError.description && responseError.description.length !== 0) {
            setErrDescription(responseError.description[0])
          }
          if (responseError.points && responseError.points.length !== 0) {
            setErrPoints(responseError.points[0])
          }
        }
      })
    }
  }

  // handel cancel event
  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/coupon')
    setTitle('')
    setPoints('')
    setDescription('')
    setImage()
    setImageAction(false)
  }

  // remove image
  const removeImage = (e) => {
    e.preventDefault()
    setFile('')
    setImage()
    setImageAction(false)
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Container className="formContainer">
        <Form className="m-2">
          <Title name="Title" onTitleChange={handelTitle} title={title} required={required} Error={errTitle} />
          <DragDrop
            onDrag={handelDrag}
            onDrop={handelDrop}
            onChange={handelChanges}
            image={image}
            dragAction={dragAction}
            removeImage={removeImage}
            imageAction={imageAction}
            file={file}
            name="Logo"
            required={required}
            Error={errImage}
          />

          <Points name="Points" onPointsChange={handelPoints} points={points} required={required} Error={errPoints} />

          <Description name="Description" onDescriptionChange={handelDescription} description={description} required={required} Error={errDescription} />

          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <CancelBtn handelCancel={handelCancel} />
              <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'reward')} />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}
export default NewRewards
