import React from 'react'
import Styles from './Pagination.module.css'
import ReactPaginate from 'react-paginate'

const PaginationData = (props) => {
  return (
    <div className={Styles.paginationContainer}>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next"
        onPageChange={props.onPageChange}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        forcePage={props.selectedPage - 1}
        pageCount={props.pageCount}
        previousLabel="previous"
        renderOnZeroPageCount={null}
        containerClassName={Styles.pagination}
        activeClassName={Styles.active}
      />
    </div>
  )
}
export default PaginationData
