import { React, useEffect, useState } from 'react'
import { AddButton } from '../../Components/Atoms/Buttons/AddButton/AddButton'
import { DownloadButton } from '../../Components/Atoms/Buttons/DownloadButton/DownloadButton'
import TableCard from '../../Components/Organisms/TableCard/TableCard'
import FormTitle from '../../Components/Molecules/FormTitle/FormTitle'
import apiServises from '../../Utiles/API.service'
import Style from './Category.module.css'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'
import { addBtnAccessHandler } from '../../Helpers/Helper'
import Tooltip from '../../Components/Molecules/Tooltip/Tooltip'

const HeaderList = [{ value: 'Name', type: 'name' }, { value: 'Journeys Completed', type: 'journey_completed_count' }, { value: 'Actions' }]

const Category = () => {
  const [pagination, setPagination] = useState([])
  const [pageCount, setpageCount] = useState(0)
  const [list, setList] = useState('')
  const [categoriesData, setCategoriesData] = useState({ results: [] })
  const [page_size, setPage_Size] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [endIndex, setEndIndex] = useState(1)
  const [startIndex, setStartIndex] = useState(0)
  const [totalItems, setTotalItems] = useState('')
  const [showLoaderState, onShowLoaderState] = useState(false)
  const searchHandler = (event) => {
    setList(event)
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  const payload = { search: list }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const headers = { Token: window.localStorage.getItem('authToken') }
  useEffect(() => {
    localStorage.setItem('header', 'Categories')
  }, [])
  useEffect(() => {
    const getPaginateData = async () => {
      await apiServises.get('api/v1/category/', payload, headers, showLoader, (responseData, errorData) => {
        setCategoriesData(responseData)
        setTotalItems(responseData.count)
        const total = responseData.count
        setpageCount(Math.ceil(total / page_size))
      })
    }
    getPaginateData()
  }, [list])

  const getCategoriesData = async (currentPage) => {
    await apiServises.get(`api/v1/category/?page=${currentPage}&page_size=${page_size}`, payload, headers, showLoader, (responseData, errorData) => {
      setCategoriesData(responseData)
      return categoriesData
    })
  }

  const handlePageClick = async (total) => {
    const currentPage = total.selected + 1

    const commentsFormServer = await getCategoriesData(currentPage)
    setPagination(commentsFormServer)
    setStartIndex((currentPage - 1) * categoriesData.results.length + 1)
    setEndIndex((currentPage - 1) * categoriesData.results.length + categoriesData.results.length)
  }

  const sIndex = (currentPage - 1) * categoriesData.results.length
  const eIndex = sIndex + categoriesData.results.length
  useEffect(() => {
    setEndIndex(eIndex)
    setStartIndex(sIndex)
  }, [eIndex, sIndex])
  const pageHandler = (e) => {
    setPage_Size(e)
  }

  const sortingAsc = (type) => {
    apiServises.get(`api/v1/category/?ordering=${type}`, payload, headers, showLoader, (responseData, errorData) => {
      setCategoriesData(responseData)
    })
  }

  const sortingDesc = (type) => {
    apiServises.get(`api/v1/category/?ordering=-${type}`, payload, headers, showLoader, (responseData, errorData) => {
      setCategoriesData(responseData)
    })
  }
  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Categories", path: "/admin/category", disable: "true" }]} />
      <div className={Style.Container}>
        <div className={Style.tableHead}>
          <AddButton url="create" disableAccess={addBtnAccessHandler(uData, 'category')} />
          <Tooltip direction="bottom" content={"Download Categories"}>
            <DownloadButton searchQuery={list} />
          </Tooltip>

        </div>

        <TableCard
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalItems}
          data={categoriesData.results}
          searchHandler={searchHandler}
          headerList={HeaderList}
          handler={pageHandler}
          totalItems={totalItems}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          sortAsc={sortingAsc}
          sortDesc={sortingDesc}
          selectedPage={currentPage}
        />
      </div>
    </>
  )
}

export default Category
