import React from 'react'
import { Button } from 'react-bootstrap'
import Styles from './ViewBtn.module.css'
import { useNavigate } from 'react-router-dom'

export const ViewBtn = (props) => {
  const navigate = useNavigate()

  const viewUrl = () => {
    const currentUrl = window.location.pathname.split('/')

    const endpoint = `/admin/${currentUrl[2]}/${props.id}/`
    return endpoint
  }

  const viewHandler = (e) => {
    e.preventDefault()

    navigate(viewUrl())
  }
  return (
    <div className={Styles.btnDiv}>
      <Button className={Styles.btn} onClick={viewHandler}>
        <a href='#'>
          <i className='fa fa-eye '></i>
        </a>
      </Button>
    </div>
  )
}
