import Styles from './DownloadButton.module.css'
import { backendHost as API_URL } from '../../../../config'
export const DownloadButton = (props) => {

  const headers = { Token: window.localStorage.getItem('authToken') }
  const base_url = API_URL.getAPIUrl()
  
  const url = window.location.pathname.split('/')
  const endpoint = `api/v1/download-${url[2]}-data/?token=${headers.Token}&search=${props.searchQuery && props.searchQuery !== undefined && props.searchQuery !== null?props.searchQuery:''}`
 
  
  return (<>
    <a className={Styles.btn} href={`${(base_url)}${endpoint}`} ><i className="fa fa-light fa-file-arrow-down"></i></a>
    </>
    )
  }