import React from 'react'
const Feeds = () => {
  return (
    <>
      <div>Feeds</div>
    </>
  )
}

export default Feeds
