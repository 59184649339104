import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Title from '../../../Components/Atoms/InputFields/Title'
import CancelBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import SaveBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import ApiService from '../../../Utiles/API.service'
import { useNavigate } from 'react-router-dom'
import { editCategory ,addBtnAccessHandler} from '../../../Helpers/Helper'
import Styles from '../Category.module.css'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import { toast } from 'react-toastify'
// Add new component component
const EditCategories = (props) => {
  const navigate = useNavigate()
  const [editTitle, setEditTitle] = useState('')
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [Err, setErr] = useState('')
  const payload = null
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  // handel title event
  const handelTitle = (e) => {
    setEditTitle(e.target.value)
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault()
    const headers = {
      Token: window.localStorage.getItem('authToken'),
      'Content-Type': 'text/plain'
    }
    const formData3 = new FormData()
    formData3.append('name', editTitle)
    ApiService.patch(editCategory(), formData3, headers, showLoader, (responseData, responseError) => {
      try {
        if (!responseError) {
          setEditTitle('')
          navigate('/admin/category')
          toast.success('Category updated successfully.', {
            autoClose: 4000
          })
        } else if (responseError.name && responseError.name.length !== 0) {
          setErr(responseError.name[0])
        }
      } catch (e) { }
    })
  }

  // handel cancel event
  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/category')
    setEditTitle('')
  }

  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }

  useEffect(() => {
    ApiService.get(editCategory(), payload, headers, showLoader, (responseData, responseError) => {
      try {
        setEditTitle(responseData.name)
      } catch (e) { }
    })
  }, [])

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Categories", path: "/admin/category" }, { title: "Edit Category", path: "/admin/category/:id/edit", disable: "true" }]} />
      <div className={Styles.Container}>
        <Container className="formContainer">
          <Form className="m-2">
            <Title name="Title" onTitleChange={handelTitle} title={editTitle} required={required} Error={Err} />
            <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <CancelBtn handelCancel={handelCancel} />
                <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'category')} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}
export default EditCategories
