import React, { useEffect, useState } from 'react'
import TableCard from '../../Components/Organisms/TableCard/TableCard'
import { AddButton } from '../../Components/Atoms/Buttons/AddButton/AddButton'
import { DownloadButton } from '../../Components/Atoms/Buttons/DownloadButton/DownloadButton'
import ApiService from '../../Utiles/API.service'
import Style from './Journeys.module.css'
import FormTitle from '../../Components/Molecules/FormTitle/FormTitle'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'
import { addBtnAccessHandler } from '../../Helpers/Helper'
import Tooltip from '../../Components/Molecules/Tooltip/Tooltip'
const HeaderList = [
  { value: 'Name', type: 'title' },
  { value: 'Movement', type: 'movement' },
  // { value: "AR", type: "is_ar" },
  { value: 'Active', type: 'is_active' },
  { value: 'ActiveTime', type: 'active_time' },
  { value: '#Completed', type: 'journey_completed_count' },
  { value: '#Attempted', type: 'journey_attempted_count' },
  { value: '#Likes', type: 'like_count' },
  { value: 'Actions' }
]

const Rewards = () => {
  const [list, setList] = useState('')
  const [rewardData, setRewardData] = useState({ results: [] })
  const [filterData, setFilterData] = useState({ results: [] })
  const [pagination, setPagination] = useState([])
  const [pageCount, setpageCount] = useState(0)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [page_size, setPage_Size] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [endIndex, setEndIndex] = useState(1)
  const [startIndex, setStartIndex] = useState(0)
  const [totalItems, setTotalItems] = useState('')
  const [filter, setFilter] = useState('none')

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  const journeyDataHandler = (filter) => {
    ApiService.get(`api/v1/journey/?is_active=${filter === 'none' ? "" : filter}`, payload, header, showLoader, (responseData, errorData) => {
      setRewardData({ results: responseData.results })
      const total = responseData.count
      setpageCount(Math.ceil(total / page_size))
    })
  }

  useEffect(() => {
    journeyDataHandler(filter)
  }, [filter])

  const searchHandler = (event) => {
    setList(event)
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  const payload = { search: list }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const header = { Token: window.localStorage.getItem('authToken') }
  useEffect(() => {
    localStorage.setItem('header', 'Journeys')
  })
  useEffect(() => {
    const getPaginateData = async () => {
      await ApiService.get('api/v1/journey/', payload, header, showLoader, (responseData, errorData) => {
        try {
          setRewardData(responseData)
          setTotalItems(responseData.count)

          const total = responseData.count
          setpageCount(Math.ceil(total / page_size))
        } catch {
          return errorData
        }
      })
    }
    getPaginateData()
  }, [list])

  const getJourneyData = async (currentPage) => {

    await ApiService.get(`api/v1/journey/?page=${currentPage}&page_size=${page_size}&is_active=${filter === 'none' ? "" : filter}`, payload, header, showLoader, (responseData, errorData) => {
      setRewardData(responseData)
      setFilterData({ results: [] })
      setFilter(filter==='none'?'none':filter)
      return rewardData
    })
  }
  const handlePageClick = async (total) => {
    setRewardData({ results: [] })
    setFilter(filter==='none'?'none':filter)
    const currentPage = total.selected + 1

    const commentsFormServer = await getJourneyData(currentPage)
    setPagination(commentsFormServer)
    setStartIndex((currentPage - 1) * rewardData.results.length + 1)
    setEndIndex((currentPage - 1) * rewardData.results.length + rewardData.results.length)
  }
  const sIndex = (currentPage - 1) * rewardData.results.length
  const eIndex = sIndex + rewardData.results.length
  useEffect(() => {
    setEndIndex(eIndex)
    setStartIndex(sIndex)
  }, [eIndex, sIndex])
  const pageHandler = (e) => {
    setPage_Size(e)
  }

  const sortingAsc = (type) => {
    ApiService.get(`api/v1/journey/?ordering=${type}`, payload, header, showLoader, (responseData, errorData) => {
      setRewardData(responseData)
    })
  }

  const sortingDesc = (type) => {
    ApiService.get(`api/v1/journey/?ordering=-${type}`, payload, header, showLoader, (responseData, errorData) => {
      setRewardData(responseData)
    })
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      { }
      <FormTitle title={[{ title: "Journeys", path: "/admin/journey", disable: "true" }]} handleFilter={handleFilter} filter={filter} showFilter={true} />
      <div className={Style.Container}>
        <div className={Style.tableHead}>
          <AddButton url="create" disableAccess={addBtnAccessHandler(uData, 'journey')} />
          <Tooltip direction="bottom" content={"Download Journeys"}>
            <DownloadButton searchQuery={list} />
          </Tooltip>
        </div>
        <TableCard
          startIndex={startIndex}
          endIndex={endIndex}
          total={page_size}
          data={filterData.results.length !== 0 ? filterData.results : rewardData.results}
          searchHandler={searchHandler}
          headerList={HeaderList}
          handler={pageHandler}
          totalItems={totalItems}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          sortAsc={sortingAsc}
          sortDesc={sortingDesc}
          selectedPage={currentPage}
        />
      </div>
    </>
  )
}

export default Rewards
