import React from 'react'
import { Button } from 'react-bootstrap'
import Styles from './AddButton.module.css'
import { useNavigate } from 'react-router-dom'
export const AddButton = (props) => {
  const navigate = useNavigate()
  return (
    <Button
      className={Styles.btn}
      disabled={props.disableAccess}
      onClick={() => {
        navigate(props.url)
      }}>
      Add New
    </Button>
  )
}
