import { Button } from 'react-bootstrap'
import Styles from './EditBtn.module.css'
import { useNavigate } from 'react-router-dom'

export const EditBtn = (props) => {
  const navigate = useNavigate()

  return (
    <>
      <Button
        className={Styles.btn}
        disabled={props.disableAccess}
        onClick={(e) => {
          props.editBtnHandler(e, navigate, props.id)
        }}>
        <a href='#'>
          <i className='fas fa-edit '></i>
        </a>
      </Button>
    </>
  )
}
