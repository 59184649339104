import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Description from '../../../Components/Atoms/InputFields/Description'
import DragDrop from '../../../Components/Atoms/InputFields/DragDrop'
import Title from '../../../Components/Atoms/InputFields/Title'
import SaveBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import CancelBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import DropDown from '../../../Components/Atoms/InputFields/DropDown'
import MultiDragDrop from '../../../Components/Atoms/InputFields/MultiDragDrop'
import ApiServices from '../../../Utiles/API.service'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Experiences.module.css'
import { toast } from 'react-toastify'
import { SearchableDropdown } from '../../../Components/Molecules/searchable-dropdown/SearchableDropdown'
import { addBtnAccessHandler } from '../../../Helpers/Helper'
// Add new Objective component
const NewObjective = (props) => {
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const navigate = useNavigate()

  // title, points, description, logoDrag state
  const [objectiveTitle, setObjectiveTitle] = useState('')
  const [errObjectiveTitle, setErrObjectiveTitle] = useState('')
  const [objectiveAuthor, setObjectiveAuthor] = useState('')
  const [errObjectiveAuthor, setErrObjectiveAuthor] = useState('')
  const [errGoodMsg, setErrGoodMsg] = useState('')
  const [errBadMsg, setErrBadMsg] = useState('')
  const [objectiveGoodMessage, setObjectiveGoodMessage] = useState('')
  const [objectiveBadMessage, setObjectiveBadMessage] = useState('')
  const [description, setDescription] = useState('')
  const [errDescription, setErrDescription] = useState('')
  const [dragAction, setDragAction] = useState(false)
  const [imageAction, setImageAction] = useState(false)
  const [images, setImages] = useState('')
  const [file, setFile] = useState([])
  const [image, setImage] = useState([])
  const [errCoverImage, setErrCoverImage] = useState('')
  const [imgFile, setImgFile] = useState('')
  const [coverImg, setCoverImg] = useState('')
  const [coverFile, setCoverFile] = useState('')
  const [imageActionCover, setImageActionCover] = useState('')
  const [author, setAuthor] = useState(null)
  const [journey, setJourney] = useState(null)
  const [errJourney, setErrJourney] = useState('')
  const [journeyData, setJourneyData] = useState([])
  const [journeyDataDefault, setJourneyDataDefault] = useState([])
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [errImagesArr, setErrImagesErr] = useState('')

  useEffect(() => {
    if (file.length === 0) {
      setImageAction(false)
    }
  }, [file])

  const headers = { Token: window.localStorage.getItem('authToken') }
  useEffect(() => {
    ApiServices.get('api/v1/journey-summary/', null, headers, showLoader, (responseData, errorData) => {
      setJourneyData(responseData)
    })
  }, [])
  let uData = JSON.parse(localStorage.getItem('UserData'))
  // handel drag event
  const handelDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragAction(true)
    } else if (e.type === 'dragleave') {
      setDragAction(false)
    }
  }

  // trigger when file is dropped
  // this function reset the dragAction to false
  const handelDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragAction(false)
  }

  // trigger when file is selected with clicked
  const handelCoverImage = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setCoverFile(e.target.files[0])
      setCoverImg(URL.createObjectURL(e.target.files[0]))
    }
    if (!coverImg) {
      setImageActionCover(true)
    } else if (coverImg) {
      setImageActionCover(false)
    }
  }
  const handelChangesMulti = (event) => {
    event.preventDefault()
    const selectedFiles = event.target.files
    setErrImagesErr('')
    const selectedFilesArray = Array.from(selectedFiles)

    setFile((prevImg)=>prevImg.concat(selectedFilesArray))
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file)
    })
    setImage((prevImg)=>prevImg.concat(imagesArray))
    if (image) {
      setImageAction(true)
    } else if (!image) {
      setImageAction(false)
    }
  }

  const removeImage = (event, indexToRemove) => {
    event.preventDefault()
    setImage(image.filter((_, event) => event !== indexToRemove))
    setFile(file.filter((_, event) => event !== indexToRemove))
  }

  // handel title events
  const handelTitle = (e) => {
    e.preventDefault()
    setErrObjectiveTitle('')
    setObjectiveTitle(e.target.value)
  }
  const handleAuthor = (e) => {
    e.preventDefault()
    setObjectiveAuthor(e.target.value)
    setErrObjectiveAuthor('')
  }
  const handelGoodMessage = (e) => {
    e.preventDefault()
    setObjectiveGoodMessage(e.target.value)
    setErrGoodMsg('')
  }
  const handleBadMessage = (e) => {
    e.preventDefault()
    setErrBadMsg('')
    setObjectiveBadMessage(e.target.value)
  }

  // handel description event
  const handelDescription = (e) => {
    setErrDescription('')
    setDescription(e.target.value)
  }

  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('image', coverFile)
    ApiServices.post('api/v1/image/', formData, headers, showLoader, async (responseData, responseError) => {
      if (!responseError) {
        const cover_id = responseData.id
        Promise.all(file.map(processImages)).then(function (results) {
          objectivePost(cover_id, results)
        })
      } else if (responseError) {
        const cover_id = null
        Promise.all(file.map(processImages)).then(function (results) {
          objectivePost(cover_id, results)
        })
        if (responseError.image.length !== 0) {
          setErrCoverImage(responseError.image[0])
        }
      }
    })
  }

  function processImages(f, index) {
    let finalData = 0

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('image', f)
      ApiServices.post('api/v1/image/', formData, headers, showLoader, (responseData1, responseError) => {
        if (responseData1) {
          finalData = responseData1.id
          resolve(finalData)
        }
        if (responseError) {
          if (responseError.image.length !== 0) {
            setErrImagesErr(responseError.image[0])
          }
        }
      })
    })
  }

  const objectivePost = (cover_id, fileIds) => {
    const imgArr = fileIds && fileIds.length !== 0 ? fileIds : null
    const coverId = !cover_id ? null : cover_id
    const payload = {
      journey: journey !== null && journey.value ? journey.value : null,
      title: objectiveTitle,
      cover_photo: coverId,
      description,
      author_name: objectiveAuthor,
      author,
      good_message: null,
      bad_message: null,
      images: imgArr
    }
    ApiServices.post('api/v1/web-objective/', payload, headers, showLoader, (journeyResponse, responseError) => {
      if (!responseError) {
        setObjectiveTitle('')
        setObjectiveAuthor('')
        setObjectiveGoodMessage('')
        setObjectiveBadMessage('')
        setDescription('')
        setImages()
        setImgFile('')
        setCoverImg('')
        setCoverFile('')
        setImageAction(false)
        setImageActionCover(false)
        navigate('/admin/objective')
        toast.success('Experience added successfuly.', {
          autoClose: 4000
        })
      } else if (responseError) {
        if (responseError.title && responseError.title.length !== 0) {
          setErrObjectiveTitle(responseError.title[0])
        }
        if (responseError.description && responseError.description.length !== 0) {
          setErrDescription(responseError.description[0])
        }
        if (responseError.author_name && responseError.author_name.length !== 0) {
          setErrObjectiveAuthor(responseError.author_name[0])
        }
        if (responseError.journey && responseError.journey.length !== 0) {
          setErrJourney(responseError.journey[0])
        }
        if (responseError.good_message && responseError.good_message.length !== 0) {
          setErrGoodMsg(responseError.good_message[0])
        }
        if (responseError.bad_message && responseError.bad_message.length !== 0) {
          setErrBadMsg(responseError.bad_message[0])
        }
        if (responseError.images && responseError.images.length !== 0) {
          setErrImagesErr(responseError.images[0])
        }
        if (responseError.cover_photo && responseError.cover_photo.length !== 0) {
          setErrCoverImage(responseError.cover_photo[0])
        }
      }
    })
  }

  // handel cancel event
  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/objective/')
    setObjectiveTitle('')
    setObjectiveAuthor('')
    setObjectiveGoodMessage('')
    setObjectiveBadMessage('')
    setDescription('')
    setImages('')
    setCoverImg('')
    setImageAction(false)
    setImageActionCover(false)
  }
  // remove cover image
  const removeCoverImage = (e) => {
    e.preventDefault()
    setCoverImg('')
    setCoverFile('')
    setImageActionCover(false)
  }
  const fetchJourneyList = (inputValue, callback) => {
    if (!inputValue) {
      callback([])
    } else {
      const options = []
      journeyData.filter((e) => {
        if (e.title.toLowerCase().includes(inputValue.toLowerCase()))
          options.push({
            label: e.title,
            value: e.id
          })
        setTimeout(() => { callback(options) }, 1000)
      })
      callback[options]

    }
  }
  const journeyDropdownHandler = (e) => {
    setJourney(e)
    setErrJourney('')
  }
  const defaultJourneyList = () => {
    journeyData.map((e) => {
      journeyDataDefault.push({
        label: e.title,
        value: e.id
      })
    })
  }
  useEffect(() => {
    defaultJourneyList()
  }, [journeyData])
  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Experiences", path: "/admin/objective" }, { title: "Add New Experience", path: "/admin/objective/create", disable: "true" }]}></FormTitle>
      <div className={Style.Container}>
        <Container className="formContainer">
          <Form className="m-2">
            <SearchableDropdown label={'Journey'} fetchList={fetchJourneyList} didSelect={journeyDropdownHandler} Values={journey} defaultList={journeyDataDefault} required={required} Error={errJourney} />
            <Title name="Title" onTitleChange={handelTitle} title={objectiveTitle} required={required} Error={errObjectiveTitle} />
            <DragDrop
              onDrag={handelDrag}
              onDrop={handelDrop}
              onChange={handelCoverImage}
              image={coverImg}
              dragAction={dragAction}
              removeImage={removeCoverImage}
              imageAction={imageActionCover}
              file={coverFile}
              name="Cover Photo"
              required={required}
              Error={errCoverImage}
            />

            <MultiDragDrop
              onDrag={handelDrag}
              onDrop={handelDrop}
              onChange={handelChangesMulti}
              image={image}
              dragAction={dragAction}
              removeImage={removeImage}
              imageAction={imageAction}
              file={file}
              name="Images"
              required={required}
              Error={errImagesArr}
            />
            <Description name="Description" onDescriptionChange={handelDescription} description={description} required={required} Error={errDescription} />
            <Title name="Author" onTitleChange={handleAuthor} title={objectiveAuthor} required={required} Error={errObjectiveAuthor} />
              <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <CancelBtn handelCancel={handelCancel} />
                <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'experience')} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}
export default NewObjective
