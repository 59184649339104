import React, { useEffect, useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import Styles from '../../Experiences/View/ViewObjective.module.css'
import { viewUrl } from '../../../Helpers/Helper'
import ApiService from '../../../Utiles/API.service'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Users.module.css'

const ViewUser = () => {
  const [viewData, setViewData] = useState({})
  const [address, setAddress] = useState({})
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [gender, setGender] = useState({})
  const [schoolYear, setSchoolYear] = useState({})
  const [ethni, setEthni] = useState({})
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const uData = JSON.parse(localStorage.getItem('UserData'))
  useEffect(() => {
    if ( uData.is_admin === false) {
      window.location.replace('/admin/home/')
    }
  }, [])
  const payload = null
  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }

  useEffect(() => {
    ApiService.get(viewUrl(), payload, headers, showLoader, (responseData, errorData) => {
      setAddress(responseData.address)
      delete responseData.address
      setViewData(responseData)
      setGender(responseData.gender)
      setSchoolYear(responseData.year_in_school)
      setEthni(responseData.ethnicity)
    })
  }, [])

  const navigate = useNavigate()

  const handelBackBtn = () => {
    navigate('/admin/user')
  }



  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Users", path: "/admin/user" }, { title: "View User", path: "/admin/user/:id/", disable: "true" }]} />
      <div className={Style.Container}>
        <Container className={Styles.Container}>
          <Form.Group className={Styles.titleRow}>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Role</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.is_admin === true ? 'Admin'
                        : (viewData.groups && viewData.groups.length !== 0 ? viewData.groups[0].name
                          :'User'
                          )
                      }</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>First Name</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.first_name}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Last Name</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.last_name}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>username</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.username}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Email</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.email}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Phone</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.phone_number}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Address1</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{!address ? '' : address.address_1 == null ? '' : address.address_1}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Address2</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{!address ? '' : address.address_2 == null ? '' : address.address_2}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Zip code</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.zip_code}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Gender</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{!gender ? '' : gender.name == null ? '' : gender.name}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Year in school</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{!schoolYear ? '' : schoolYear.name == null ? '' : schoolYear.name}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Ethnicity</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{!ethni ? '' : ethni.name == null ? '' : ethni.name}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Points</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.score}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Photo</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                {!viewData.profile_photo ? null : <img src={viewData.profile_photo.thumbnail} alt="" className={Styles.imgContainer} />}
              </Col>
            </Row>

            <hr />

            <Button variant="" type="submit" className={Styles.backBtn} onClick={handelBackBtn}>
              Back
            </Button>
          </Form.Group>
        </Container>
      </div>
    </>
  )
}

export default ViewUser
