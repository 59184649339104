import { React, useState, useEffect } from 'react'
import { Container, Form, Row, Col, FormGroup } from 'react-bootstrap'
import CancelBtn from '../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import SaveBtn from '../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import Title from '../../Components/Atoms/InputFields/Title'
import { useNavigate } from 'react-router-dom'
import DropDown from '../../Components/Atoms/InputFields/DropDown'
import DragDrop from '../../Components/Atoms/InputFields/DragDrop'
import Styles from './ManageProfile.module.css'
import FormTitle from '../../Components/Molecules/FormTitle/FormTitle'
import ApiServices from '../../Utiles/API.service'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'
import { toast } from 'react-toastify'
import { injectStyle } from 'react-toastify/dist/inject-style'
import Email from '../../Components/Atoms/InputFields/Email'
import Username from '../../Components/Atoms/InputFields/Username'
import Zip from '../../Components/Atoms/InputFields/Zip'
import { passwordValidation, validateLogin, newPasswordValidation, confirmPasswordValidation, usernameValidation, phoneNumberValidation, zipValidation } from '../../Helpers/Helper'

if (typeof window !== 'undefined') {
  injectStyle()
}

const ManageProfile = () => {
  const navigate = useNavigate()

  const data = JSON.parse(localStorage.getItem('UserData'))

  const [dropDownData, setDropDownData] = useState('')
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [username, setUsername] = useState('')
  const [zip, setZip] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [changePassword, setChangePassword] = useState()
  const [gender, setGender] = useState()
  const [genderData, setGenderData] = useState([])
  const [address1, setAddress1] = useState(null)
  const [address2, setAddress2] = useState(null)
  const [image, setImage] = useState('')
  const [file, setFile] = useState('')
  const [imageAction, setImageAction] = useState(false)
  const [dragAction, setDragAction] = useState(false)
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [required, setRequired] = useState(true)
  const [showLoaderState, setShowLoaderState] = useState(true)
  const [passwordErr, setPasswordErr] = useState('')
  const [emailErr, setEmailErr] = useState('')
  const [oldPasswordErr, setOldPasswordErr] = useState('')
  const [newPasswordErr, setNewPasswordErr] = useState('')
  const [usernameErr, setUsernameErr] = useState('')
  const [phoneNumberErr, setPhoneNumberErr] = useState('')
  const [zipErr, setZipErr] = useState('')
  const [profileErr, setProfileErr] = useState('')
  const [genderErr, setGenderErr] = useState('')
  const [firstNameErr, setFirstNameErr] = useState('')
  const [lastNameErr, setLastNameErr] = useState('')
  const [address_1Err, setAddress_1Err] = useState('')
  const [address_2Err, setAddress_2Err] = useState('')

  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }
  let payload = null
  const showLoader = (val) => {
    setShowLoaderState(val)
  }
  // api call for list of gender
  useEffect(() => {
    ApiServices.get('api/v1/users-common-dropdown-list/', payload, headers, showLoader, (responseData, errorData) => {
      setGenderData(responseData.gender)
    })
  }, [])
  useEffect(() => {
    localStorage.setItem('header', 'Manage Profile')
  })
  // get call for fetch login user details
  useEffect(() => {
    ApiServices.get(`api/v1/web/user/${data.id}/`, payload, headers, showLoader, (responseData, responseError) => {
      setFirstName(responseData.first_name)
      setLastName(responseData.last_name)
      setUsername(responseData.username)
      setEmail(responseData.email)
      setAddress1(responseData.address.address_1)
      setAddress2(responseData.address.address_2)
      setZip(responseData.zip_code)
      setPhoneNumber(responseData.phone_number)
      setImage(responseData.profile_photo.thumbnail)

      setDropDownData(responseData.gender.id)
    })
  }, [])

  const handelFirstName = (e) => {
    e.preventDefault()
    setFirstName(e.target.value)
    setFirstNameErr('')
  }

  const handelLastName = (e) => {
    e.preventDefault()
    setLastName(e.target.value)
    setLastNameErr('')
  }

  const handelUsername = (e) => {
    e.preventDefault()
    setUsernameErr(usernameValidation(e.target))
    setUsername(e.target.value)
  }

  const handelEmail = (e) => {
    e.preventDefault()
    setEmailErr(validateLogin(e.target))
    setEmail(e.target.value)
  }

  const handelAddress1 = (e) => {
    e.preventDefault()
    setAddress1(e.target.value)
    setAddress_1Err('')
  }

  const handelAddress2 = (e) => {
    e.preventDefault()
    setAddress2(e.target.value)
    setAddress_2Err('')
  }

  const handelZip = (e) => {
    e.preventDefault()
    setZipErr(zipValidation(e.target))
    setZip(e.target.value)
  }

  const handelPhoneNumber = (e) => {
    e.preventDefault()
    setPhoneNumberErr(phoneNumberValidation(e.target))
    setPhoneNumber(e.target.value)
  }

  const handelChangePassword = (e) => {
    e.preventDefault()
    setChangePassword(e.target.value)
    setOldPasswordErr(passwordValidation(e.target))
  }

  const onGenderChange = (e) => {
    setGender(e)
    setGenderErr('')
  }

  const handelDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragAction(true)
    } else if (e.type === 'dragleave') {
      setDragAction(false)
    }
  }
  const handelDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragAction(false)
  }
  const handelChanges = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0])
      setImage(URL.createObjectURL(e.target.files[0]))
    }
    if (!image) {
      setImageAction(true)
      setProfileErr('')
    } else if (image) {
      setImageAction(false)
      setProfileErr('')
    }
  }

  const handelNewPassword = (e) => {
    e.preventDefault()
    setNewPasswordErr(newPasswordValidation(e.target))
    setNewPassword(e.target.value)
  }

  const handelConfirmPassword = (e) => {
    e.preventDefault()
    setPasswordErr(confirmPasswordValidation(e.target))
    setConfirmPassword(e.target.value)
  }

  const removeImage = (e) => {
    e.preventDefault()
    setFile('')
    setImage()
    setImageAction(false)
  }
  // if Editing
  const isEdit = false

  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/home')
    setFirstName('')
    setLastName('')
    setEmail('')
    setUsername('')
    setAddress1('')
    setAddress2('')
    setGender()
    setPhoneNumber()
    setZip('')
    setImage('')
    setImageAction(false)
  }

  // patch api call for edit profile details
  const editProfile = () => {
    const headers = {
      Token: window.localStorage.getItem('authToken'),
      'Content-Type': 'application/json'
    }

    if (file) {
      const formData = new FormData()
      formData.append('image', file)
      ApiServices.post('api/v1/image/', formData, headers, showLoader, (responseData, responseError) => {
        try {
          payload = {
            first_name: firstName,
            last_name: lastName,
            username,
            email,
            phone_number: phoneNumber,
            gender,
            address_1: address1,
            address_2: address2,
            zip_code: zip,
            profile_photo: responseData.id
          }
          ApiServices.patch(`api/v1/web/user/${data.id}/`, payload, headers, showLoader, (responseData, responseError) => {
            if (responseData) {
              toast.success('Profile details updated successfully', {
                autoClose: 4000
              })
              navigate('/admin/home')
              localStorage.setItem('UserData', JSON.stringify(responseData))
            }
            if (responseError.username && responseError.username.length !== 0) {
              setUsernameErr(responseError.username[0])
            }
            if (responseError.email && responseError.email.length !== 0) {
              setEmailErr(responseError.email[0])
            }
            if (responseError.phone_number && responseError.phone_number.length !== 0) {
              setPhoneNumberErr(responseError.phone_number[0])
            }
            if (responseError.first_name && responseError.first_name.length !== 0) {
              setFirstNameErr(responseError.first_name[0])
            }
            if (responseError.last_name && responseError.last_name.length !== 0) {
              setLastNameErr(responseError.last_name[0])
            }
            if (responseError.address_1 && responseError.address_1.length !== 0) {
              setAddress_1Err(responseError.address_1[0])
            }
            if (responseError.address_2 && responseError.address_2.length !== 0) {
              setAddress_2Err(responseError.address_2[0])
            }
            if (responseError.zip_code && responseError.zip_code.length !== 0) {
              setZipErr(responseError.zip_code[0])
            }
            if (responseError.profile_photo && responseError.profile_photo.length !== 0) {
              setProfileErr(responseError.profile_photo[0])
            }
            if (responseError.gender && responseError.gender.length !== 0) {
              setGenderErr(responseError.gender[0])
            }
          })
        } catch (error) {
          payload = {
            first_name: firstName,
            last_name: lastName,
            username,
            email,
            phone_number: phoneNumber,
            gender,
            address_1: address1,
            address_2: address2,
            zip_code: zip,
            profile_photo: ''
          }
          ApiServices.patch(`api/v1/web/user/${data.id}/`, payload, headers, showLoader, (responseData, responseError) => {
            if (responseData) {
              toast.success('Profile details updated successfully', {
                autoClose: 4000
              })
              navigate('/admin/home')
              localStorage.setItem('UserData', JSON.stringify(responseData))
            }
            if (responseError.username && responseError.username.length !== 0) {
              setUsernameErr(responseError.username[0])
            }
            if (responseError.email && responseError.email.length !== 0) {
              setEmailErr(responseError.email[0])
            }
            if (responseError.phone_number && responseError.phone_number.length !== 0) {
              setPhoneNumberErr(responseError.phone_number[0])
            }
            if (responseError.first_name && responseError.first_name.length !== 0) {
              setFirstNameErr(responseError.first_name[0])
            }
            if (responseError.last_name && responseError.last_name.length !== 0) {
              setLastNameErr(responseError.last_name[0])
            }
            if (responseError.address_1 && responseError.address_1.length !== 0) {
              setAddress_1Err(responseError.address_1[0])
            }
            if (responseError.address_2 && responseError.address_2.length !== 0) {
              setAddress_2Err(responseError.address_2[0])
            }
            if (responseError.zip_code && responseError.zip_code.length !== 0) {
              setZipErr(responseError.zip_code[0])
            }
            if (responseError.profile_photo && responseError.profile_photo.length !== 0) {
              setProfileErr(responseError.profile_photo[0])
            }
            if (responseError.gender && responseError.gender.length !== 0) {
              setGenderErr(responseError.gender[0])
            }
          })
        }
      })
    } else {
      payload = {
        first_name: firstName,
        last_name: lastName,
        username,
        email,
        phone_number: phoneNumber,
        gender,
        address_1: address1,
        address_2: address2,
        zip_code: zip
      }
      ApiServices.patch(`api/v1/web/user/${data.id}/`, payload, headers, showLoader, (responseData, responseError) => {
        if (responseData) {
          toast.success('Profile details updated successfully', {
            autoClose: 4000
          })
          navigate('/admin/home')
          localStorage.setItem('UserData', JSON.stringify(responseData))
        }
        if (responseError.username && responseError.username.length !== 0) {
          setUsernameErr(responseError.username[0])
        }
        if (responseError.email && responseError.email.length !== 0) {
          setEmailErr(responseError.email[0])
        }
        if (responseError.phone_number && responseError.phone_number.length !== 0) {
          setPhoneNumberErr(responseError.phone_number[0])
        }
        if (responseError.first_name && responseError.first_name.length !== 0) {
          setFirstNameErr(responseError.first_name[0])
        }
        if (responseError.last_name && responseError.last_name.length !== 0) {
          setLastNameErr(responseError.last_name[0])
        }
        if (responseError.address_1 && responseError.address_1.length !== 0) {
          setAddress_1Err(responseError.address_1[0])
        }
        if (responseError.address_2 && responseError.address_2.length !== 0) {
          setAddress_2Err(responseError.address_2[0])
        }
        if (responseError.zip_code && responseError.zip_code.length !== 0) {
          setZipErr(responseError.zip_code[0])
        }
        if (responseError.profile_photo && responseError.profile_photo.length !== 0) {
          setProfileErr(responseError.profile_photo[0])
        }
        if (responseError.gender && responseError.gender.length !== 0) {
          setGenderErr(responseError.gender[0])
        }
      })
    }
  }

  const updatePassword = () => {
    const headers = {
      Token: window.localStorage.getItem('authToken'),
      'Content-Type': 'application/json'
    }

    payload = {
      old_password: changePassword,
      new_password1: newPassword,
      new_password2: confirmPassword
    }

    ApiServices.post('api/v1/change/password/', payload, headers, showLoader, (responseData, responseError) => {
      if (responseData) {
        toast.success(responseData.detail, {
          autoClose: 4000
        })
        navigate('/admin/home')
      }
      if (responseError.old_password && responseError.old_password.length !== 0) {
        setOldPasswordErr(responseError.old_password[0])
      }
      if (responseError.new_password1 && responseError.new_password1.length !== 0) {
        setNewPasswordErr(responseError.new_password1[0])
      }
      if (responseError.new_password2 && responseError.new_password2.length !== 0) {
        setPasswordErr(responseError.new_password2[0])
      }
    })
  }

  const handelSubmit = (e) => {
    e.preventDefault()
    if (
      (newPassword !== null && newPassword !== '' && newPassword !== undefined) ||
      (confirmPassword !== null && confirmPassword !== '' && confirmPassword !== undefined) ||
      (changePassword !== '' && changePassword !== null && changePassword !== undefined)
    ) {
      updatePassword()
    } else {
      editProfile()
    }
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Manage Profile", path: "/admin/profile/", disable: "true" }]} />
      <div className={Styles.Container}>
        <Container className="formContainer">
          <Form className="m-2">
            <Title name="First Name" onTitleChange={handelFirstName} title={firstName} required={required} Error={firstNameErr} />
            <Title name="Last Name" onTitleChange={handelLastName} title={lastName} required={required} Error={lastNameErr} />
            <Username name="Username" onTitleChange={handelUsername} title={username} required={required} Error={usernameErr} />
            <Email name="Email Address" onTitleChange={handelEmail} title={email} required={required} Error={emailErr} />
            <Title name="Address1" onTitleChange={handelAddress1} title={address1} Error={address_1Err} />
            <Title name="Address2" onTitleChange={handelAddress2} title={address2} Error={address_2Err} />
            <Zip name="ZIP" onTitleChange={handelZip} title={zip} required={required} Error={zipErr} type="string"/>
            <Zip name="Phone Number" onTitleChange={handelPhoneNumber} title={phoneNumber} required={required} Error={phoneNumberErr} type="number" />
            <DragDrop
              onDrag={handelDrag}
              onDrop={handelDrop}
              onChange={handelChanges}
              image={image}
              dragAction={dragAction}
              removeImage={removeImage}
              imageAction={imageAction}
              file={file}
              name="Photo"
              isEdit={isEdit}
              required={required}
              Error={profileErr}
            />
            <div>
              <img src={image} className={Styles.imgContainer} alt=""></img>
            </div>
            <DropDown
              name="Gender"
              dropDefault={dropDownData}
              // dropDefault="Select gender"
              type={'manageProfile'}
              data={genderData}
              handler={onGenderChange}
              showDefaultOption={false}
              required={required}
              ErrorGender={genderErr}
            />
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.PasswordLabel} type="password">
                  Change Password
                </Form.Label>
              </Col>
            </Row>
            <FormGroup className={Styles.titleRow}>
              <Form.Label className={Styles.titleLabel}>Old Password </Form.Label>
              <Form.Control
                className={Styles.titleInput}
                type="password"
                placeholder="Old Password"
                name={changePassword}
                onChange={(e) => {
                  handelChangePassword(e)
                }}
              />
            </FormGroup>
            {oldPasswordErr === '' ? null : <span className={[Styles.Error, Styles.loginError].join(' ')}>{oldPasswordErr}</span>}{' '}
            <FormGroup className={Styles.titleRow}>
              <Form.Label className={Styles.titleLabel}>New Password </Form.Label>
              <Form.Control
                className={Styles.titleInput}
                type="password"
                placeholder="New Password"
                name={newPassword}
                onChange={(e) => {
                  handelNewPassword(e)
                }}
              />
            </FormGroup>
            {newPasswordErr === '' ? null : <span className={[Styles.Error, Styles.loginError].join(' ')}>{newPasswordErr}</span>}{' '}
            <FormGroup className={Styles.titleRow}>
              <Form.Label className={Styles.titleLabel}>Confirm Password </Form.Label>
              <Form.Control
                className={Styles.titleInput}
                type="password"
                placeholder="Confirm Password"
                name={confirmPassword}
                onChange={(e) => {
                  handelConfirmPassword(e)
                }}
              />
            </FormGroup>
            {passwordErr === '' ? null : <span className={[Styles.Error, Styles.loginError].join(' ')}>{passwordErr}</span>}{' '}
            <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <CancelBtn handelCancel={handelCancel} />
                <SaveBtn handelSubmit={handelSubmit} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}
export default ManageProfile
