import axios from 'axios'
import { backendHost as API_URL } from './../config'
import { toast } from 'react-toastify'
// global.navigate = (link) => this.props.history.push({ pathname: link });
// console.log(API_URL.getAPIUrl())

const ApiService = {
  get: (url, payload, headers, showLoader, callback) => {
    showLoader(true)
    axios
      .get(API_URL.getAPIUrl() + url, {
        params: payload,
        headers:
          headers === null
            ? {}
            : {
              Authorization: `Token ${headers.Token}`
            }
      })
      .then((responseData) => {
        showLoader(false)
        if (responseData) {
          callback && callback(responseData.data, null)
        }
      })
      .catch((error) => {
        showLoader(false)
        console.log(error, JSON.stringify(error))

        if (error.response) {
          if (error.response.status === 403) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error(error.response.data.detail)
            return
          }
          if (error.response.status === 500) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('Something went wrong please log in again or visit later')
            return
          } if (error.response.status === 404) {
            window.location.replace('/Not-Found')
            return
          }
          if (error.response.data.detail !== 'Not found.') {
            toast.error(error.response.data.detail)
            // global.navigate("/");
          }
          callback && callback(null, error.response.data)
        }
      })
  },

  post: (url, data, headers, showLoader, callback) => {
    showLoader(true)
    // console.log(API_URL.getAPIUrl())

    axios
      .post(API_URL.getAPIUrl() + url, data, {
        headers:
          headers === null
            ? {}
            : {
              Authorization: `Token ${headers.Token}`
            }
      })
      .then((responseData) => {
        showLoader(false)
        callback && callback(responseData.data, null, data)
      })
      .catch((error) => {
        showLoader(false)
        console.log(JSON.stringify(error))
        if (error.response) {
          if (error.response.status === 403) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('User token expired, log in again')
            return
          }
          if (error.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('User is not authorized to access the content, log in with valid credentials')
            return
          }
          if (error.response.status === 500) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('Something went wrong please log in again or visit later')
            return
          }
          if (error.response.status === 404) {
            window.location.replace('/Not-Found')
            return
          }
          if (error.response.data.detail === 'Not found.') {
            toast.error(error.response.data.detail)
            // global.navigate("/");
          }
          callback && callback(null, error.response.data, data)
        }
      })
  },
  del: (url, payload, headers, showLoader, callback) => {
    showLoader(true)
    axios
      .delete(API_URL.getAPIUrl() + url, {
        params: payload,
        headers:
          headers === null
            ? {}
            : {
              Authorization: `Token ${headers.Token}`
            }
      })
      .then((responseData) => {
        showLoader(false)
        callback && callback(responseData.data, null)
      })
      .catch((error) => {
        showLoader(false)
        console.log(JSON.stringify(error))
        if (error.response) {
          if (error.response.status === 403) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('User token expired, log in again')
            return
          }
          if (error.response.status === 500) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('Something went wrong please log in again or visit later')
            return
          }
          if (error.response.status === 404) {
            window.location.replace('/Not-Found')
            return
          }
          callback && callback(null, error.response.data)
        }
      })
  },
  patch: (url, data, headers, showLoader, callback) => {
    showLoader(true)
    axios
      .patch(API_URL.getAPIUrl() + url, data, {
        headers:
          headers === null
            ? {}
            : {
              Authorization: `Token ${headers.Token}`
            }
      })
      .then((responseData) => {
        showLoader(false)
        callback && callback(responseData.data, null, data)
      })
      .catch((error) => {
        showLoader(false)
        console.log(JSON.stringify(error))
        if (error.response) {
          if (error.response.status === 403) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('User token expired, log in again')
            return
          }
          if (error.response.status === 500) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('Something went wrong please log in again or visit later')
            return
          }
          if (error.response.status === 404) {
            window.location.replace('/Not-Found')
            return
          }
          callback && callback(null, error.response.data, data)
        }
      })
  },

  put: (url, data, headers, showLoader, callback) => {
    showLoader(true)
    axios
      .put(API_URL.getAPIUrl() + url, data, {
        headers:
          headers === null
            ? {}
            : {
              Authorization: `Token ${headers.Token}`
            }
      })
      .then((responseData) => {
        showLoader(false)
        callback && callback(responseData.data, null)
      })
      .catch((error) => {
        showLoader(false)
        console.log(JSON.stringify(error))
        if (error.response) {
          if (error.response.status === 403) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('User token expired, log in again')
            return
          }
          if (error.response.status === 500) {
            setTimeout(() => {
              localStorage.clear()
              window.location.replace('/')
            }, 2000)
            toast.error('Something went wrong please log in again or visit later')
            return
          }
          if (error.response.status === 404) {
            window.location.replace('/Not-Found')
            return
          }
          if (error.response.data.detail) {
            toast.error(error.response.data.detail)
            // global.navigate("/");
          }
          callback && callback(null, error.response.data)
        }
      })
  }
}

export default ApiService
