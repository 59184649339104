import React from 'react'

import { Routes, Route } from 'react-router-dom'
import Questions from '../Pages/Questions/Questions'
import AddQuestion from '../Pages/Questions/Add-Question/AddQuestion'
import EditQuestion from '../Pages/Questions/Edit/EditQuestion'
import ViewQuestion from '../Pages/Questions/View/ViewQuestion'
import { NotFound } from '../Pages/NotFound/NotFound'
const QuestionsRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Questions />}></Route>
        <Route path="create" element={<AddQuestion />}></Route>
        <Route path=":id/edit" element={<EditQuestion />}></Route>
        <Route path=":id" element={<ViewQuestion />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default QuestionsRoute
