import React from 'react'
import style from './ConnectionLost.module.css'
export const ConnectionLost = () => {
    return (
        <>
            <div className={style.container}>
                <h1 className={style.error}>OOPS !</h1>
                <h2 className={style.error}>Network Connection Lost.</h2>
                <h3 className={style.linkHeading}>Please Check your Internet Connection and try again .</h3>
            </div>
        </>
    )
}
