import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import SaveBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import CancelBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import Description from '../../../Components/Atoms/InputFields/Description'
import DropDown from '../../../Components/Atoms/InputFields/DropDown'
import DragDrop from '../../../Components/Atoms/InputFields/DragDrop'
import MultiDragDrop from '../../../Components/Atoms/InputFields/MultiDragDrop'
import Title from '../../../Components/Atoms/InputFields/Title'
import AddressAuto from '../../../Components/Atoms/InputFields/AddressAutoComplete'
import StartCloseTime from '../../../Components/Molecules/StartCloseTime/StartCloseTime'
import { useNavigate } from 'react-router-dom'
import apiServices from '../../../Utiles/API.service'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Stamp from '../../../Components/Molecules/StampSelect/StampSelect'
import { CouponSearch } from '../../../Components/Molecules/CouponSearch/CouponSearch'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import Style from '../Journeys.module.css'
import { toast } from 'react-toastify'
import { SearchableDropdown } from '../../../Components/Molecules/searchable-dropdown/SearchableDropdown'
import {addBtnAccessHandler} from '../../../Helpers/Helper'

const NewJourneys = (props) => {
  const [title, setTitle] = useState('')
  const [errTitle, setErrTitle] = useState('')
  const [address, setAddress] = useState('')
  const [errAddress, setErrAddress] = useState('')
  const [addressText, setAddressText] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [description, setDescription] = useState('')
  const [errDescription, setErrDescription] = useState('')
  const [dragAction, setDragAction] = useState(false)
  const [image, setImage] = useState([])
  const [file, setFile] = useState([])
  const [errImageArray, setErrImageArray] = useState('')
  const [imageAction, setImageAction] = useState(false)
  const [imageActionCover, setImageActionCover] = useState(false)
  const [imageCover, setImageCover] = useState('')
  const [fileCover, setFileCover] = useState('')
  const [errCoverImg, setErrCoverImg] = useState('')
  const [category, setCategory] = useState(null)
  const [errCategory, setErrCategory] = useState('')
  const [categoryData, setCategoryData] = useState([])
  const [categoryDataDefault, setCategoryDataDefault] = useState([])
  const [activeTimeData, setActiveTimeData] = useState([])
  const [activeTimeDataDefault, setActiveTimeDataDefault] = useState([])
  const [errActiveTime, setErrActiveTime] = useState('')
  const [movementData, setMovementData] = useState([])
  const [movementDataDefault, setMovementDataDefault] = useState([])
  const [errorMovement, setErrMovement] = useState('')
  const [coupon, setCoupon] = useState([])
  const [couponDefault, setCouponDefault] = useState([])
  const [couponId, setCouponId] = useState([])
  const [errCoupon, setErrCoupon] = useState('')
  const [activeTime, setActiveTime] = useState(null)
  const [movement, setMovement] = useState(null)
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [rewardsValues, setRewardsValues] = useState([])
  const [journey_times, setJourneyTimes] = useState([
    {
      day: 'Monday',
      start_time: '',
      end_time: ''
    },
    {
      day: 'Tuesday',
      start_time: '',
      end_time: ''
    },
    {
      day: 'Wednesday',
      start_time: '',
      end_time: ''
    },
    {
      day: 'Thursday',
      start_time: '',
      end_time: ''
    },
    {
      day: 'Friday',
      start_time: '',
      end_time: ''
    },
    {
      day: 'Saturday',
      start_time: '',
      end_time: ''
    },
    {
      day: 'Sunday',
      start_time: '',
      end_time: ''
    }
  ])
  const [stamp, setStamp] = useState([])
  const [stampId, setStampId] = useState('')
  const [errStamp, setErrStamp] = useState('')
  const navigate = useNavigate()
  const payload = null
  const headers = { Token: window.localStorage.getItem('authToken') }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }

  useEffect(() => {
    apiServices.get('api/v1/coupon-list/', payload, headers, showLoader, (responseData, errorData) => {
      if (!errorData) {
        setCoupon(responseData)
      } else {
        if (errorData) {
          toast.error(errorData, {
            autoClose: 4000
          })
        }
      }
    })
  }, [])
  useEffect(() => {
    apiServices.get('api/v1/stamp-list/', payload, headers, showLoader, (responseData, errorData) => {
      if (!errorData) {
        setStamp(responseData)
      } else {
        if (errorData) {
          toast.error(errorData, {
            autoClose: 4000
          })
        }
      }
    })
  }, [])
  useEffect(() => {
    apiServices.get('api/v1/web-journey-common-dropdown-list/', payload, headers, showLoader, (responseData, errorData) => {
      if (!errorData) {
        setCategoryData(responseData.category!== null ?responseData.category:[])
        setMovementData(responseData.movement!== null ?responseData.movement:[])
        setActiveTimeData(responseData.active_time!== null ?responseData.active_time:[])
      } else if (errorData) {
        toast.error(errorData, {
          autoClose: 4000
        })
      }
    })
  }, [])

  useEffect(() => {
    if (file.length === 0) {
      setImageAction(false)
    }
  }, [file])
  let uData = JSON.parse(localStorage.getItem('UserData'))
  const stampHandler = (e) => {
    setStampId(e)
    setErrStamp('')
  }

  const handelDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragAction(true)
    } else if (e.type === 'dragleave') {
      setDragAction(false)
    }
  }
  const handelDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragAction(false)
  }

  const handelChangesMulti = (event) => {
    event.preventDefault()
    const selectedFiles = event.target.files
    setErrImageArray('')
    const selectedFilesArray = Array.from(selectedFiles)

    setFile((prevImg)=>prevImg.concat(selectedFilesArray))
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file)
    })
    setImage((prevImg)=>prevImg.concat(imagesArray))
    if (image) {
      setImageAction(true)
    } else if (!image) {
      setImageAction(false)
    }
  }
    const handelChangesCover = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setFileCover(e.target.files[0])
      setImageCover(URL.createObjectURL(e.target.files[0]))
    }
    if (image) {
      setImageActionCover(true)
    } else if (!image) {
      setImageActionCover(false)
    }
  }
  const handelAddress = (e) => {
    setAddress(e.formatted_address)
    setLatitude(e.geometry.location.lat())
    setLongitude(e.geometry.location.lng())

  }

  const onchangeText = (e) => {
    setAddressText(e)
    setAddress(e)
    setErrAddress('')
  }

  const handelTitle = (e) => {
    setTitle(e.target.value)
    setErrTitle('')
  }
  const handelDescription = (e) => {
    setDescription(e.target.value)
    setErrDescription('')
  }

  const journeyPost = (cover_id, fileIds) => {
    const imgArr = fileIds && fileIds.length !== 0 ? fileIds : null
    const coverId = !cover_id ? null : cover_id
    const payload = {
      title,
      category: category !== null && category.value ? category.value : null,
      cover_photo: coverId,
      images: imgArr,
      active_time: activeTime !== null && activeTime.value ? activeTime.value : null,
      movement: movement !== null && movement.value ? movement.value : null,
      journey_times,
      description,
      stamp: stampId,
      coupons: couponId,
      address,
      longitude,
      latitude
    }

    apiServices.post('api/v1/journey/', payload, headers, showLoader, (journeyResponse, responseError) => {
      if (!responseError) {
        setTitle('')
        setDescription('')
        setImage([])
        setImageAction(false)
        setFile([])
        setFileCover('')
        setImageCover('')
        setCoupon([])
        setCouponId([])
        setRewardsValues([])
        setStamp([])
        setStampId('')
        navigate('/admin/journey')
        toast.success('Journey added successfully.', {
          autoClose: 4000
        })
      } else {
        if (responseError.title && responseError.title.length !== 0) {
          setErrTitle(responseError.title[0])
        }
        if (responseError.address && responseError.address.length !== 0) {
          setErrAddress(responseError.address[0])
        }
        if (responseError.active_time && responseError.active_time.length !== 0) {
          setErrActiveTime(responseError.active_time[0])
        }
        if (responseError.movement && responseError.movement.length !== 0) {
          setErrMovement(responseError.movement[0])
        }
        if (responseError.description && responseError.description.length !== 0) {
          setErrDescription(responseError.description[0])
        }
        if (responseError.images && responseError.images.length !== 0) {
          setErrImageArray(responseError.images[0])
        }
        if (responseError.category && responseError.category.length !== 0) {
          setErrCategory(responseError.category[0])
        }
        if (responseError.coupons && responseError.coupons.length !== 0) {
          setErrCoupon(responseError.coupons[0])
        }
        if (responseError.stamp && responseError.stamp.length !== 0) {
          setErrStamp(responseError.stamp[0])
        }
        if (responseError.cover_photo && responseError.cover_photo.length !== 0) {
          setErrCoverImg(responseError.cover_photo[0])
        }
      }
    })
  }

  const handelSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('image', fileCover)
    apiServices.post('api/v1/image/', formData, headers, showLoader, async (responseData, responseError) => {
      if (!responseError) {
        const cover_id = responseData.id
        Promise.all(file.map(processImages)).then(function (results) {
          journeyPost(cover_id, results)
        })
      } else {
        const cover_id = null
        Promise.all(file.map(processImages)).then(function (results) {
          journeyPost(cover_id, results)
        })
        if (responseError.image.length !== 0) {
          setErrCoverImg(responseError.image[0])
        }
      }
    })
  }
  function processImages(f, index) {
    let finalData = 0

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('image', f)
      apiServices.post('api/v1/image/', formData, headers, showLoader, (responseData1, responseError) => {
        if (!responseError) {
          finalData = responseData1.id
          resolve(finalData)
        } else if (responseError.image.length !== 0) {
          setErrImageArray(responseError.image[0])
        }
      })
    })
  }
  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/journey')
    setTitle('')
    setDescription('')
    setImage([])
    setImageCover('')
    setImageAction(false)
    setImageActionCover(false)
  }
  const removeImage = (event, indexToRemove) => {
    event.preventDefault()
    setImage(image.filter((_, event) => event !== indexToRemove))
    setFile(file.filter((_, event) => event !== indexToRemove))
  }

  const removeImageCover = (e) => {
    e.preventDefault()
    setImageCover('')
    setFileCover('')
    setImageActionCover(false)
  }
  const categoryDropdownHandler = (e) => {
    setCategory(e)
    setErrCategory('')
  }
  const activeTimeDropdownHandler = (e) => {
    setActiveTime(e)
    setErrActiveTime('')
  }
  const movementDropdownHandler = (e) => {
    setMovement(e)
    setErrMovement('')
  }

  const journeyHandler = (dataName, dataValue, selectedData) => {
    journey_times.map((obj) => {
      if (dataValue !== '') {
        let today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
        const yyyy = today.getFullYear()

        today = yyyy + '-' + mm + '-' + dd
        if (obj.day === selectedData.day && dataName === 'start_time') {
          obj.start_time = `${today}T${dataValue}:00Z`
        } else if (obj.day === selectedData.day && dataName === 'end_time') {
          obj.end_time = `${today}T${dataValue}:00Z`
        }
      } else return null
    })
  }
  const defaultrewardList = () => {
    coupon.map((e) => {
      couponDefault.push({
        label: e.title,
        value: e.id
      })
    })
  }
  const defaultCategoryList = () => {
    categoryData.map((e) => {
      categoryDataDefault.push({
        label: e.name,
        value: e.id
      })
    })
  }
  const defaultActiveTime = () => {
    activeTimeData.map((e) => {
      activeTimeDataDefault.push({
        label: e.active_time,
        value: e.id
      })
    })
  }
  const defaultMovement = () => {
    movementData.map((e) => {
      movementDataDefault.push({
        label: e.name,
        value: e.id
      })
    })
  }
  useEffect(() => {
    defaultrewardList()
  }, [coupon])
  useEffect(() => {
    defaultMovement()
  }, [movementData])
  useEffect(() => {
    defaultCategoryList()
  }, [categoryData])
  useEffect(() => {
    defaultActiveTime()
  }, [activeTimeData])
  const fetchMovementList = (inputValue, callback) => {
    const url = 'api/v1/coupon/?search='

    if (!inputValue) {
      callback([])
    } else {
      if (coupon !== []) {

        const options = []
        movementData.filter((e) => {
          if (e.name.toLowerCase().includes(inputValue.toLowerCase()))
            options.push({
              label: e.name,
              value: e.id
            })
          setTimeout(() => { callback(options) }, 1000)
        })
        callback[options]
      }
    }
  }
  const fetchActiveTimeList = (inputValue, callback) => {
    const url = 'api/v1/coupon/?search='

    if (!inputValue) {
      callback([])
    } else {
      if (coupon !== []) {

        const options = []
        activeTimeData.filter((e) => {
          if (e.active_time.toString().includes(inputValue))
            options.push({
              label: e.active_time,
              value: e.id
            })
          setTimeout(() => { callback(options) }, 1000)
        })
        callback[options]
      }
    }
  }
  const fetchCategoryList = (inputValue, callback) => {
    const url = 'api/v1/coupon/?search='

    if (!inputValue) {
      callback([])
    } else {
      if (coupon !== []) {

        const options = []
        categoryData.filter((e) => {
          if (e.name.toLowerCase().includes(inputValue.toLowerCase()))
            options.push({
              label: e.name,
              value: e.id
            })
          setTimeout(() => { callback(options) }, 1000)
        })
        callback[options]
      }
    }
  }
  const fetchRewardsList = (inputValue, callback) => {
    const url = 'api/v1/coupon/?search='

    if (!inputValue) {
      callback([])
    } else {
      if (coupon !== []) {

        const options = []
        coupon.filter((e) => {
          if (e.title.toLowerCase().includes(inputValue.toLowerCase()))
            options.push({
              label: e.title,
              value: e.id
            })
          setTimeout(() => { callback(options) }, 1000)
        })
        callback[options]
      }
    }
  }

  const didMovementSelect = (e) => {
    setMovement(e)
    setErrMovement('')
  }

  const didActiveTimeSelect = (e) => {
    setActiveTime(e)
    setErrActiveTime('')
  }

  const didCategorySelect = (e) => {
    // setErrCoupon('')
    // setCouponId(list)
    // setRewardsValues(e)
    setCategory(e)
    setErrCategory('')
  }

  const didRewardSelect = (e) => {
    const list = []
    e.forEach((obj) => {
      list.push(obj.value)
    })
    setErrCoupon('')
    setCouponId(list)
    setRewardsValues(e)
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Journeys", path: "/admin/journey" }, { title: "Add New Journey", path: "/admin/journey/create", disable: "true" }]} />
      <div className={Style.Container}>
        <Container className="formContainer">
          <Form
            className="m-2"
            onSubmit={(e) => {
              return e.key === 'Enter' ? null : handelSubmit
            }}>
            <Title name="Title" onTitleChange={handelTitle} title={title} required={required} Error={errTitle} />
            <SearchableDropdown label={'Category'} fetchList={fetchCategoryList} didSelect={didCategorySelect} Values={category} defaultList={categoryDataDefault} required={required} Error={errCategory} />
            {/* <DropDown
              name="Category"
              dropDefault="Select Category"
              handler={categoryDropdownHandler}
              data={categoryData}
              type={'category'}
              showDefaultOption={true}
              required={required}
              ErrorCategory={errCategory}
            /> */}
            
              <CouponSearch label={'Rewards'} fetchRewardsList={fetchRewardsList} didRewardSelect={didRewardSelect} defaultList={couponDefault} rewardsValues={rewardsValues} required={required} Error={errCoupon} />
            
            <AddressAuto name="Address" defaultVal={address} onAddressChange={handelAddress} required={required} onchangeText={onchangeText} Error={errAddress} />

            <DragDrop
              onDrag={handelDrag}
              onDrop={handelDrop}
              onChange={handelChangesCover}
              image={imageCover}
              dragAction={dragAction}
              removeImage={removeImageCover}
              imageAction={imageActionCover}
              file={fileCover}
              name="Cover photo"
              required={required}
              Error={errCoverImg}
            />
            <Stamp label="Stamp" data={stamp} stampHandler={stampHandler} required={required} Error={errStamp} />
            <MultiDragDrop
              onDrag={handelDrag}
              onDrop={handelDrop}
              onChange={handelChangesMulti}
              image={image}
              dragAction={dragAction}
              removeImage={removeImage}
              imageAction={imageAction}
              file={file}
              name="Photos"
              required={required}
              Error={errImageArray}
            />
            {journey_times.map((day, index) => {
              return <StartCloseTime key={index} data={day} journeyHandler={journeyHandler} />
            })}

            <SearchableDropdown label={'Active Time'} fetchList={fetchActiveTimeList} didSelect={didActiveTimeSelect} Values={activeTime} defaultList={activeTimeDataDefault} required={required} Error={errActiveTime} />
            <SearchableDropdown label={'Movement'} fetchList={fetchMovementList} didSelect={didMovementSelect} Values={movement} defaultList={movementDataDefault} required={required} Error={errorMovement} />
            <Description name="Description" onDescriptionChange={handelDescription} description={description} required={required} Error={errDescription} />
            <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <CancelBtn handelCancel={handelCancel} />
                <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'journey')} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}
export default NewJourneys
