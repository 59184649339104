import style from './JourneysBtn.module.css'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
const Question = ({ isOpen }) => {

  const [headerInfo, setHeaderInfo] = useState('')
  useEffect(() => {
    setHeaderInfo(localStorage.getItem('header'))
  })
  return (
    <>
      {/* Question dropdown option */}
      <div className={(style.linkHover, headerInfo === 'Questions' ? style.selected : null)}>
        <Link
          to={'question'}
          onClick={() => {
            localStorage.setItem('header', 'Questions')
          }}
          className={isOpen ? style.linkActive : style.link}>
          <div className={isOpen ? style.iconActive : style.icon}>{<i className="fa fa-id-card"></i>}</div>
          <div className={isOpen ? style.linkTextActive : style.linkText}>Questions</div>
        </Link>
      </div>
    </>
  )
}
export default Question
