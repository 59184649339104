import React from 'react'
import { Modal } from 'react-bootstrap'
import { Digital } from 'react-activity'
import 'react-activity/dist/library.css'
import styles from './PageLoader.module.css'

const PageLoader = (props) => (
  <Modal id="loaderModal" show={props.show} centered className={styles.modalContainer}>
    <Digital size={50} color="#324767" />
  </Modal>
)

export default PageLoader
