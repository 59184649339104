import React from 'react'
import { Link } from 'react-router-dom'
import style from './NotFound.module.css'
export const NotFound = () => {
    return (
        <>
            <div className={style.container}>
                <h1 className={style.error}>OOPS !</h1>
                <h2 className={style.error}>Requested page can not be found or does not exists.</h2>
                <h3 className={style.linkHeading}>Use following link to visit Homepage.</h3>
                <div>
                    <Link className={style.Link} to={'/admin/home'}>Home</Link>
                </div>
            </div>
        </>
    )
}
