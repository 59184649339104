import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Users from '../Pages/Users/Users'
import AddUser from '../Pages/Users/Add-Users/AddUser'
import EditUser from '../Pages/Users/Edit/EditUser'
import ViewUser from '../Pages/Users/View/ViewUser'
import { NotFound } from '../Pages/NotFound/NotFound'
const UsersRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Users />}></Route>
        <Route path="create" element={<AddUser />}></Route>
        <Route path=":id/edit" element={<EditUser />}></Route>
        <Route path=":id" element={<ViewUser />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default UsersRoute
