import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import DragDrop from '../../Atoms/InputFields/DragDrop'
import CancelBtn from '../../Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import SaveBtn from '../../Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import Title from '../../Atoms/InputFields/Title'
import ApiService from '../../../Utiles/API.service'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import { toast } from 'react-toastify'
import {addBtnAccessHandler} from '../../../Helpers/Helper'

// Add new component component
const NewStamp = (props) => {
  const navigate = useNavigate()
  const showLoader = (val) => {
    onShowLoaderState(val)
  }

  // title, points, description, logoDrag state
  const [dragAction, setDragAction] = useState(false)
  const [name, setName] = useState('')
  const [stamp, setStamp] = useState('')
  const [image, setImage] = useState('')
  const [stampImg, setStampImg] = useState('')
  const [imageAction, setImageAction] = useState(false)
  const [stampFile, setStampFile] = useState('')
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [Err, setErr] = useState('')
  const [imgErr, setImgErr] = useState('')

  // handel drag event
  const handelDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragAction(true)
    } else if (e.type === 'dragleave') {
      setDragAction(false)
    }
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  // trigger when file is dropped
  // this function reset the dragAction to false
  const handelDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragAction(false)
  }

  const handelTitle = (e) => {
    setName(e.target.value)
    setErr('')
  }

  // trigger when file is selected with clicked
  const handelChanges = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setStampFile(e.target.files[0])
      setStampImg(URL.createObjectURL(e.target.files[0]))
    }
    if (!stampImg) {
      setImageAction(true)
      setImgErr('')
    } else if (stampImg) {
      setImageAction(false)
      setImgErr('')
    }
  }

  // for save image URL
  const saveImages = () => {
    const headers = {
      Token: window.localStorage.getItem('authToken'),
      'Content-Type': 'text/plain'
    }
    const formData = new FormData()
    formData.append('image', stampFile)
    ApiService.post('api/v1/image/', formData, headers, showLoader, (responseData, responseError) => {
      try {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('thumbnail_url', responseData.thumbnail)
        ApiService.post('api/v1/stamp/', formData, headers, showLoader, (responseData, responseError) => {
          if (!responseError) {
            navigate('/admin/stamp')
            setName('')
            setStamp('')
            setImage('')
            setImageAction(false)
            setStampFile('')
            toast.success('Stamp created successfully.', {
              autoClose: 4000
            })
          } else {
            if (responseError.name && responseError.name.length !== 0) {
              setErr(responseError.name[0])
            }
            if (responseError.thumbnail_url && responseError.thumbnail_url.length !== 0) {
              setImgErr(responseError.thumbnail_url[0])
            }
          }
        })
      } catch (error) { }
      if (responseError.image.length !== 0) {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('thumbnail_url', '')
        ApiService.post('api/v1/stamp/', formData, headers, showLoader, (responseData, responseError) => {
          if (!responseError) {
            navigate('/admin/stamp')
            setName('')
            setStamp('')
            setImage('')
            setImageAction(false)
            setStampFile('')
            toast.success('Stamp created successfully.', {
              autoClose: 4000
            })
          } else {
            if (responseError.name && responseError.name.length !== 0) {
              setErr(responseError.name[0])
            }
            if (responseError.thumbnail_url && responseError.thumbnail_url.length !== 0) {
              setImgErr(responseError.thumbnail_url[0])
            }
          }
        })
      }
    })
  }

  // handel on submit event
  const handelSubmit = async (e) => {
    e.preventDefault()
    saveImages()
  }

  // handel cancel event
  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/stamp')
    setName('')
    setStamp('')
    setStampImg()
    setImageAction(false)
  }

  // remove image
  const removeImage = (e) => {
    e.preventDefault()
    setStampFile('')
    setStampImg()
    setImageAction(false)
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Container className="formContainer">
        <Form className="m-2">
          <Title name="Name" onTitleChange={handelTitle} title={name} required={required} Error={Err} />
          <DragDrop
            name="Stamp"
            onDrag={handelDrag}
            onDrop={handelDrop}
            onChange={handelChanges}
            image={stampImg}
            dragAction={dragAction}
            removeImage={removeImage}
            imageAction={imageAction}
            file={stampFile}
            required={required}
            Error={imgErr}
          />
          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <CancelBtn handelCancel={handelCancel} />
              <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'stamp')} />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}
export default NewStamp
