import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Styles from './MarkButton.module.css'
import ApiService from '../../../../Utiles/API.service'
import PageLoader from '../../../Molecules/PageLoader/PageLoader'
export const MarkButton = (props) => {
  const [checked, setChecked] = useState(props.checked)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [disable, setDisable] = useState(false)
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const url = window.location.pathname.split('/')
  const headers = { Token: window.localStorage.getItem('authToken') }
  const markHandler = async (data) => {
    const endpoint = `api/v1/${url[2]}/${props.id}/`

    setChecked(!data)
    const payloadActive = { is_retake: !data }
    await ApiService.patch(endpoint, payloadActive, headers, showLoader, (responseData, errorData) => {
      if (responseData) {
        setDisable(false)
      }
      else if (errorData) {
        setDisable(false)
      }
    })
  }
  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Button
        disabled={props.disable}
        className={checked ? Styles.btnActive : Styles.btn}
        onClick={() => {
          return (setDisable(true),
            markHandler(checked))
        }}>
        <i className='fa fa-repeat'></i>
      </Button>
    </>
  )
}
