import React, { useEffect, useState } from 'react'
import TableCard from '../../Components/Organisms/TableCard/TableCard'
import { AddButton } from '../../Components/Atoms/Buttons/AddButton/AddButton'
import { DownloadButton } from '../../Components/Atoms/Buttons/DownloadButton/DownloadButton'
import ApiService from '../../Utiles/API.service'
import Style from './Experiences.module.css'
import FormTitle from '../../Components/Molecules/FormTitle/FormTitle'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'
import { addBtnAccessHandler } from '../../Helpers/Helper'
import Tooltip from '../../Components/Molecules/Tooltip/Tooltip'
const HeaderList = [{ value: 'Name', type: 'title' }, { value: 'Journey', type: 'journey' }, { value: 'AR', type: 'is_ar' }, { value: 'Actions' }]

const Rewards = () => {
  const [list, setList] = useState('')
  const [rewardData, setRewardData] = useState({ results: [] })
  const [pagination, setPagination] = useState([])
  const [pageCount, setpageCount] = useState(0)
  const [page_size, setPage_Size] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [endIndex, setEndIndex] = useState(1)
  const [startIndex, setStartIndex] = useState(0)
  const [totalItems, setTotalItems] = useState('')
  const [showLoaderState, onShowLoaderState] = useState(false)

  const searchHandler = (event) => {
    setList(event)
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  const payload = { search: list }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const header = { Token: window.localStorage.getItem('authToken') }
  useEffect(() => {
    localStorage.setItem('header', 'Experiences')
  })
  useEffect(() => {
    const getPaginateData = async () => {
      ApiService.get('api/v1/web-objective/', payload, header, showLoader, (responseData, errorData) => {
        try {
          setRewardData(responseData)
          setTotalItems(responseData.count)
          const total = responseData.count
          setpageCount(Math.ceil(total / page_size))
        } catch {
          return errorData
        }
      })
    }
    getPaginateData()
  }, [list])

  const getExperiencesData = async (currentPage) => {
    await ApiService.get(`api/v1/web-objective/?page=${currentPage}&page_size=${page_size}`, payload, header, showLoader, (responseData, errorData) => {
      setRewardData(responseData)
      return rewardData
    })
  }

  const handlePageClick = async (total) => {
    setRewardData({ results: [] })
    const currentPage = total.selected + 1
    const commentsFormServer = await getExperiencesData(currentPage)
    setPagination(commentsFormServer)
    setStartIndex((currentPage - 1) * rewardData.results.length + 1)
    setEndIndex((currentPage - 1) * rewardData.results.length + rewardData.results.length)
  }
  const sIndex = (currentPage - 1) * rewardData.results.length
  const eIndex = sIndex + rewardData.results.length
  useEffect(() => {
    setEndIndex(eIndex)
    setStartIndex(sIndex)
  }, [eIndex, sIndex])
  const pageHandler = (e) => {
    setPage_Size(e)
  }

  const sortingAsc = (type) => {
    ApiService.get(`api/v1/web-objective/?ordering=${type}`, payload, header, showLoader, (responseData, errorData) => {
      setRewardData(responseData)
    })
  }

  const sortingDesc = (type) => {
    ApiService.get(`api/v1/web-objective/?ordering=-${type}`, payload, header, showLoader, (responseData, errorData) => {
      setRewardData(responseData)
    })
  }
  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Experiences", path: "/admin/objective", disable: "true" }]} />
      <div className={Style.Container}>
        <div className={Style.tableHead}>
          <AddButton url="create" disableAccess={addBtnAccessHandler(uData, 'experience')} />
          <Tooltip direction="bottom" content={"Download Experiences"}><DownloadButton searchQuery={list} /></Tooltip>
        </div>
        <TableCard
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalItems}
          data={rewardData.results}
          searchHandler={searchHandler}
          headerList={HeaderList}
          handler={pageHandler}
          totalItems={totalItems}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          sortAsc={sortingAsc}
          sortDesc={sortingDesc}
          selectedPage={currentPage}
        />
      </div>
    </>
  )
}

export default Rewards
