import { React, useState } from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap'
import { delElement } from '../../../../Helpers/Helper'
import ApiService from '../../../../Utiles/API.service'
import Styles from './DeleteBtn.module.css'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../../Molecules/PageLoader/PageLoader'

export const DeleteBtn = (props) => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const data = {}
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const headers = { Token: window.localStorage.getItem('authToken') }
  const delHandler = () => {
    const endpoint = delElement(props.id)
    ApiService.del(endpoint, data, headers, showLoader, (responseData, errorData) => {
      navigate(0)
    })
  }
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Button className={Styles.btn} onClick={handleShow}  disabled={props.disableAccess}>
        <i className='fa fa-trash-o'></i>
      </Button>
      <Modal show={show} bs-prefix={Styles.popUpContainer} onHide={handleClose}>
        <Modal.Header className={Styles.popUpHeader} closeButton>
          {/* <Modal.Title>
          </Modal.Title> */}

          <ModalBody>
            {' '}
            <h4> Do you want to delete this {props.item} ?</h4>
          </ModalBody>
        </Modal.Header>
        <Modal.Footer className={Styles.popUpFooter}>
          <Button className={Styles.btnSecondary} onClick={handleClose}>
            No
          </Button>
          <Button
            className={Styles.btnPrimary}
            onClick={() => {
              handleClose()
              delHandler()
            }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
