import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import DragDrop from '../../../Components/Atoms/InputFields/DragDrop'
import Title from '../../../Components/Atoms/InputFields/Title'
import CancelBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import SaveBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import ApiService from '../../../Utiles/API.service'
import { useNavigate } from 'react-router-dom'
import { editCategory,addBtnAccessHandler } from '../../../Helpers/Helper'
import Styles from '../Stamps.module.css'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import { toast } from 'react-toastify'

const payload = null

// Add new component component
const EditStamp = (props) => {
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const navigate = useNavigate()
  const [editTitle, setEditTitle] = useState('')
  const [dragAction, setDragAction] = useState(false)
  const [imageAction, setImageAction] = useState(false)
  const [editFile, setEditFile] = useState('')
  const [editImage, setEditImage] = useState('')
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [required, setRequired] = useState(true)
  const [Err, setErr] = useState('')
  const [imgErr, setImgErr] = useState('')

  const handelDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragAction(true)
    } else if (e.type === 'dragleave') {
      setDragAction(false)
    }
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  // trigger when file is dropped
  // this function reset the dragAction to false
  const handelDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragAction(false)
  }

  // trigger when file is selected with clicked
  const handelChanges = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setEditFile(e.target.files[0])
      setEditImage(URL.createObjectURL(e.target.files[0]))
    }
    if (!editImage) {
      setImageAction(true)
      setImgErr('')
    } else if (editImage) {
      setImageAction(false)
      setImgErr('')
    }
  }

  // handel title event
  const handelTitle = (e) => {
    setEditTitle(e.target.value)
    setErr('')
  }

  const saveImages = () => {
    const headers = {
      Token: window.localStorage.getItem('authToken'),
      'Content-Type': 'text/plain'
    }

    if (editFile) {
      const formData = new FormData()
      formData.append('image', editFile)
      ApiService.post('api/v1/image/', formData, headers, showLoader, (responseData, responseError) => {
        try {
          const formData3 = new FormData()
          formData3.append('name', editTitle)
          formData3.append('thumbnail_url', responseData.thumbnail)
          ApiService.patch(editCategory(), formData3, headers, showLoader, (responseData, responseError) => {
            if (!responseError) {
              navigate('/admin/stamp')
              setEditTitle('')
              setEditImage()
              setImageAction(false)
              setEditFile('')
              toast.success('Stamp updated successfully.', {
                autoClose: 4000
              })
            } else {
              if (responseError.name && responseError.name.length !== 0) {
                setErr(responseError.name[0])
              }
              if (responseError.thumbnail_url && responseError.thumbnail_url.length !== 0) {
                setImgErr(responseError.thumbnail_url[0])
              }
            }
          })
        } catch (e) {
          const formData3 = new FormData()
          formData3.append('name', editTitle)
          formData3.append('thumbnail_url', '')
          ApiService.patch(editCategory(), formData3, headers, showLoader, (responseData, responseError) => {
            if (!responseError) {
              navigate('/admin/stamp')
              setEditTitle('')
              setEditImage()
              setImageAction(false)
              setEditFile('')
              toast.success('Stamp updated successfully.', {
                autoClose: 4000
              })
            } else {
              if (responseError.name && responseError.name.length !== 0) {
                setErr(responseError.name[0])
              }
              if (responseError.thumbnail_url && responseError.thumbnail_url.length !== 0) {
                setImgErr(responseError.thumbnail_url[0])
              }
            }
          })
        }
      })
    } else {
      const formData2 = new FormData()
      formData2.append('name', editTitle)

      ApiService.patch(editCategory(), formData2, headers, showLoader, (responseData, responseError) => {
        try {
          if (!responseError) {
            navigate('/admin/stamp')
            setEditTitle('')
            setEditImage()
            setImageAction(false)
            setEditFile('')
            toast.success('Stamp updated successfully.', {
              autoClose: 4000
            })
          } else {
            if (responseError.name && responseError.name.length !== 0) {
              setErr(responseError.name[0])
            }
            if (responseError.thumbnail_url && responseError.thumbnail_url.length !== 0) {
              setImgErr(responseError.thumbnail_url[0])
            }
          }
        } catch (e) { }
      })
    }
  }

  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault()
    saveImages()
  }

  // handel cancel event
  const handelCancel = (e) => {
    navigate('/admin/stamp')
    e.preventDefault()
    setEditTitle('')
    setEditImage()
    setImageAction(false)
  }

  // remove image
  const removeImage = (e) => {
    e.preventDefault()
    setEditImage()
    setImageAction(false)
  }
  // if Editing
  const isEdit = false

  // edit form get call for data
  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }

  useEffect(() => {
    ApiService.get(editCategory(), payload, headers, showLoader, (responseData, responseError) => {
      try {
        setEditTitle(responseData.name)
        setEditImage(responseData.thumbnail_url)
      } catch (e) { }
    })
  }, [])

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Stamps", path: '/admin/stamp' }, { title: "Edit Stamp", path: '/admin/stamp/:id/edit', disable: "true" }]} />
      <div className={Styles.Container}>
        <Container className="formContainer">
          <Form className="m-2">
            <Title name="Title" onTitleChange={handelTitle} title={editTitle} required={required} Error={Err} />
            <DragDrop
              onDrag={handelDrag}
              onDrop={handelDrop}
              onChange={handelChanges}
              image={editImage}
              dragAction={dragAction}
              removeImage={removeImage}
              imageAction={imageAction}
              file={editFile}
              name="Image"
              isEdit={isEdit}
              required={required}
              Error={imgErr}
            />
            <div>{!editImage ? null : <img src={editImage} className={Styles.imgContainer} alt=""></img>}</div>
            <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <CancelBtn handelCancel={handelCancel} />
                <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'stamp')} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}

export default EditStamp
