import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Container } from 'react-bootstrap'
import Title from '../../Atoms/InputFields/Title'
import SaveBtn from '../../Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import CancelBtn from '../../Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import { useNavigate } from 'react-router-dom'
import apiServices from '../../../Utiles/API.service'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import { toast } from 'react-toastify'
import {addBtnAccessHandler} from '../../../Helpers/Helper'

const NewCategories = (props) => {
  const [name, setName] = useState('')
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [Err, setErr] = useState('')
  const navigate = useNavigate()
  const showLoader = (val) => {
    onShowLoaderState(val)
  }

  // handel title event
  const handelTitle = (e) => {
    setName(e.target.value)
    setErr('')
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  const saveImages = () => {
    const headers = {
      Token: window.localStorage.getItem('authToken'),
      'Content-Type': 'text/plain'
    }
    try {
      const formData = new FormData()
      formData.append('name', name)
      apiServices.post('api/v1/category/', formData, headers, showLoader, (responseData, responseError) => {
        try {
          if (!responseError) {
            navigate('/admin/category')
            setName('')
            toast.success('Category added successfully.', {
              autoClose: 4000
            })
          } else if (responseError.name && responseError.name.length !== 0) {
            setErr(responseError.name[0])
          }
        } catch (e) { }
      })
    } catch (error) {
      console.log(error)
    }
  }

  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault()
    saveImages()
  }

  // handel cancel event
  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/category')
    setName('')
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Container className="formContainer">
        <Form className="m-2">
          <Title name="Name" onTitleChange={handelTitle} title={name} required={required} Error={Err} />
          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <CancelBtn handelCancel={handelCancel} />
              <SaveBtn handelSubmit={handelSubmit}  disableAccess={addBtnAccessHandler(uData,'category')} />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}
export default NewCategories
