import { Form, Row, Col } from 'react-bootstrap'
import style from './Atoms.module.css'
import Autocomplete from 'react-google-autocomplete'
import { useEffect, useState } from 'react'
import { googleMapApiKey as GOOGLE_MAPS_API_KEY } from '../../../config'

const AddressAuto = ({ name, onAddressChange, required, defaultVal, onchangeText, Error }) => {
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  useEffect(() => {
    if (Error !== '') {
      setError(true)
      setErrMsg(Error)
    } else {
      setError(false)
    }
  }, [Error])
  const options = {
    types: ['geocode']
  }
  return (
    <>
      {/* title option, journey dropdown  */}
      <Form.Group className={style.titleRow}>
        <Row>
          <Col>
            <Form.Label className={style.titleLabel}>
              {name}{' '}
              {!required
                ? null
                : (
                  <span id='email-error' className={style.errorAsterisk}>
                    *
                  </span>
                )}{' '}
            </Form.Label>
          </Col>
          <Col sm='10'>
            <Autocomplete
              className={style.titleInput + ' form-control'}
              apiKey={GOOGLE_MAPS_API_KEY}
              value={defaultVal}
              onPlaceSelected={(place) => {
                onAddressChange(place)
              }}
              onChange={(evt) => {
                onchangeText(evt.target.value)
                setError(false)
              }}
              options={options}
            />
            <Row className={style.errContainer}>
              {!error
                ? null
                : (
                  <span id='integer-error' className={style.Error}>
                    {errMsg}
                  </span>
                )}
            </Row>
          </Col>
        </Row>
      </Form.Group>
      <hr />
    </>
  )
}

export default AddressAuto
