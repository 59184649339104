import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Title from '../../../Components/Atoms/InputFields/Title'
import Points from '../../../Components/Atoms/InputFields/Points'
import SaveBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import CancelBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import ApiService from '../../../Utiles/API.service'
import { useNavigate } from 'react-router-dom'
import { editCategory,editMilestone,addBtnAccessHandler } from '../../../Helpers/Helper'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import Style from '../Milestones.module.css'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import { toast } from 'react-toastify'
const EditMilestone = () => {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [points, setPoints] = useState()
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [Err, setErr] = useState('')
  const [pointsErr, setPointsErr] = useState('')

  let uData = JSON.parse(localStorage.getItem('UserData'))
  useEffect(() => {
    if (uData.is_admin === false && uData.groups && uData.groups.length !== 0 && uData.groups[0].name ==='Destination Partner') {
      window.location.replace('/admin/home/')
    }
  }, [])
  const payload = null
  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }

  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  useEffect(() => {
    ApiService.get(editMilestone(), payload, headers, showLoader, (responseData, responseError) => {
      try {
        setTitle(responseData.title)
        setPoints(responseData.points)
      } catch (e) { }
    })
  }, [])
  const handelTitle = (e) => {
    setTitle(e.target.value)
    setErr('')
  }

  const handelPoints = (e) => {
    setPoints(e)
    setPointsErr('')
  }

  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/milestone')
    setTitle('')
    setPoints('')
  }

  const handelSubmit = (e) => {
    e.preventDefault()
    const payload = {
      title,
      points
    }
    const headers = {
      Token: window.localStorage.getItem('authToken'),
      'Content-Type': 'text/plain'
    }
    const showLoader = () => false
    ApiService.patch(editMilestone(), payload, headers, showLoader, (responseData, errorData) => {
      try {
        if (!errorData) {
          navigate('/admin/milestone/')
          setTitle('')
          setPoints('')
          toast.success('Milestone updated successfully', {
            autoClose: 4000
          })
        }
        if (errorData.title && errorData.title.length !== 0) {
          setErr(errorData.title[0])
        }
        if (errorData.points && errorData.points.length !== 0) {
          setPointsErr(errorData.points[0])
        }
      } catch (e) { }
    })
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Milestones", path: "/admin/milestone/" }, { title: "Edit Milestone", path: "/admin/milestone/:id/edit", disable: "true" }]} />
      <div className={Style.Container}>
        <Container className="formContainer">
          <Form className="m-2">
            <Title name="Title" onTitleChange={handelTitle} title={title} required={required} Error={Err} />
            <Points name="Points" onPointsChange={handelPoints} points={points} required={required} Error={pointsErr} />

            <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <CancelBtn handelCancel={handelCancel} />
                <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'milestone')}/>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  )
}
export default EditMilestone
