import { useState, useEffect } from 'react'
import FormTitle from '../../Components/Molecules/FormTitle/FormTitle'
import styles from './Home.module.css'
import { HomeCard } from '../../Components/Organisms/HomeCard/HomeCard'
import experience from '../../Assets/experiences.png'
import journey from '../../Assets/journeys.png'
import reward from '../../Assets/rewards.png'
import user from '../../Assets/users.png'
import arrow from '../../Assets/arrow.svg'
import ApiService from '../../Utiles/API.service'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'


const Home = () => {
  const [totalCount, setTotalCount] = useState(null)
  const [activeUsers, setActiveUsers] = useState(null)
  const [journeyCompleted, setJourneyCompleted] = useState(null)
  const [journeyAttempted, setJourneyAttempted] = useState(null)
  const [experienceCompleted, setExperienceCompleted] = useState(null)
  const [experienceAttempted, setExperienceAttempted] = useState(null)
  const [rewardsRedeemded, setRewardsRedeemed] = useState(null)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [filter, setFilter] = useState('none')

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const payload = {}
  const header = { Token: window.localStorage.getItem('authToken') }

  // Functions to call APIs
  const fetchCardData = () => {

    ApiService.get(`api/v1/user-count/?duration=${filter === 'none' ? "" : filter}`, payload, header, showLoader, (responseData, errorData) => {
      setActiveUsers(responseData.total_active_users_count)
      setTotalCount(responseData.total_users_count)
    })
    ApiService.get(`api/v1/journey-count/?duration=${filter === 'none' ? "" : filter}`, payload, header, showLoader, (responseData, errorData) => {
      setJourneyAttempted(responseData.user_attempted_journey_count)
      setJourneyCompleted(responseData.user_completed_journey_count)
      setExperienceAttempted(responseData.user_attempted_objective_count)
      setExperienceCompleted(responseData.user_completed_objective_count)
      setRewardsRedeemed(responseData.reward_redeemed_count)
    })
  }
  useEffect(() => {
    localStorage.setItem('header', 'Home')
  })
  // api Calls for card data
  useEffect(() => {
    fetchCardData()
  }, [filter])


  const uData = JSON.parse(localStorage.getItem('UserData'))
  // Following return block contains the jsx that is to be rendered onto the browser.
  const headerInfo = localStorage.getItem('header')
  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[""]} headerInfo={headerInfo} handleFilter={handleFilter} filter={filter} />
      {uData.is_admin ===true || uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin'?<div className={styles.homeContainer} >
        <HomeCard className={styles.homeCard} heading={"Users"} firstRowHeading={"Total Count : "} firstRowCount={totalCount} secondRowHeading={'Active Users :'} secondRowCount={activeUsers} img={user} path={'/admin/user'} arrowIcn={arrow} header={'Users'} />
        <HomeCard className={styles.homeCard} heading={"Journeys"} firstRowHeading={"Completed : "} firstRowCount={journeyCompleted} secondRowHeading={'Attempted :'} secondRowCount={journeyAttempted} img={journey} path={'/admin/journey'} arrowIcn={arrow} header={'Journeys'} />
        <HomeCard className={styles.homeCard} heading={"Experiences"} firstRowHeading={"Completed : "} firstRowCount={experienceCompleted} secondRowHeading={'Attempted :'} secondRowCount={experienceAttempted} img={experience} />
        <HomeCard className={styles.homeCard} heading={"Rewards"} firstRowHeading={"Redeemed : "} firstRowCount={rewardsRedeemded} img={reward} />
      </div>:
      <div className={styles.homeContainerNonAdmin}>
       <h1> Welcome to Passport Atlanta Admin Portal</h1>
      </div>}
    </>
  )
}

export default Home
