import React, { useEffect, useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import Styles from './ViewJourney.module.css'
import { viewUrl } from '../../../Helpers/Helper'
import ApiService from '../../../Utiles/API.service'
import Style from '../Journeys.module.css'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'

const ViewJourney = (props) => {
  const [viewData, setViewData] = useState({
    category: {},
    stamp: {},
    journey_times: [],
    images: [],
    movement: {}
  })
  const [showLoaderState, onShowLoaderState] = useState(false)
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const payload = null
  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }
  useEffect(() => {
    ApiService.get(viewUrl(), payload, headers, showLoader, (responseData, errorData) => {
      setViewData(responseData)
    })
  }, [])

  const navigate = useNavigate()

  const handelBackBtn = () => {
    navigate('/admin/journey')
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Journeys", path: "/admin/journey" }, { title: "View Journey", path: "/admin/journey/:id/", disable: "true" }]} />
      <div className={Style.Container}>
        <Container className={Styles.Container}>
          <Form.Group className={Styles.titleRow}>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Title</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.title}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Category</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{!viewData.category ? null : viewData.category.name}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Address</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.address}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Description</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.description}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Hours</Form.Label>
              </Col>
              <Col sm={5}>
                <table className={[Styles.hoursTable, 'table table-bordered'].join('')}>
                  <thead className={Styles.tableHead}>
                    <tr>
                      <th className={Styles.thLabel}>Day</th>
                      <th className={Styles.thLabel}>Open Time</th>
                      <th className={Styles.thLabel}>Close Time</th>
                    </tr>
                  </thead>
                  <tbody className={Styles.tableBody}>
                    {viewData.journey_times.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td className={Styles.tdLabel}>{row.day ? row.day : null}</td>
                          <td className={Styles.tdLabel}>{row.start_time ? row.start_time.split('T')[1].split('Z')[0] : null}</td>
                          <td className={Styles.tdLabel}>{row.end_time ? row.end_time.split('T')[1].split('Z')[0] : null}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>

            <hr />

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Cover Photo</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                {!viewData.cover_photo ? null : <img src={viewData.cover_photo.thumbnail} alt="" className={Styles.titleImage1} />}
              </Col>
            </Row>

            <hr />

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Stamp</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                {!viewData.stamp ? null : <img className={Styles.titleImage1} src={viewData.stamp.thumbnail_url} alt="" />}
              </Col>
            </Row>

            <hr />

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Images</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                {viewData.images.map((img, index) => {
                  return <img key={index} src={img.thumbnail} alt="" className={Styles.titleImage1} />
                })}
              </Col>
            </Row>

            <hr />

            <Button variant="" type="submit" className={Styles.backBtn} onClick={handelBackBtn}>
              Back
            </Button>
          </Form.Group>
        </Container>
      </div>
    </>
  )
}

export default ViewJourney
