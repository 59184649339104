import React from 'react'
import Style from './HomeCard.module.css'
import { Link } from 'react-router-dom'
export const HomeCard = (props) => {

    return (
        <div className={Style.cardContainer}>
            <div className={Style.imgContainer}>
                <img src={props.img}></img>
            </div>
            <div className={Style.cardData}>
                <div className={Style.row1}>
                    <p>{props.heading}</p>
                </div>
                <div className={Style.row2}>
                    <div>{props.firstRowHeading}</div>
                    <div>{props.firstRowCount}</div>
                </div>
                {props.secondRowHeading ? (<div className={Style.row3}>
                    <div>{props.secondRowHeading}</div>
                    <div>{props.secondRowCount}</div>
                </div>) : null}
                {props.path ? (<Link className={Style.row3} to={props.path} onClick={() => {
                    localStorage.setItem('header', props.header)
                }}>View More <img src={props.arrowIcn}></img></Link>) : null}

            </div>
        </div >
    )
}