import React, { useState } from 'react'
import TableOrganism from '../Table/Table'
import Styles from './TableCard.module.css'
import PaginationData from '../../Molecules/Pagination/Pagination'
import { Button } from 'react-bootstrap'
const TableCard = (props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const handleSearchLimit = (e) => {
    props.searchHandler(e)
  }
  return (
    <div className={Styles.Card}>
      <div className={Styles.CardHead}>
        <div className={Styles.SearchContainer}>
          Search:&nbsp;&nbsp;
          <input
            className={Styles.SearchBar}
            type="text"
            placeholder=""
            onKeyUp={(e) => {
              if (e.key === 'Backspace' || e.key === 'Delete') {
                setSearchQuery(e.target.value)
                handleSearchLimit(e.target.value)
              } else if (e.target.value === '') {
                setSearchQuery('')
                handleSearchLimit('')
              }
            }}
            onChange={(event) => {
              if (event.target.value.length > 2 && event.target.value.length < 15) {
                setSearchQuery(event.target.value)
              }
              // handleSearchLimit(event)
            }}
          />
          <Button type='submit' className={Styles.SearchBtn} onClick={() => { handleSearchLimit(searchQuery) }}> <i className='fa fa-magnifying-glass'></i></Button>
        </div>
      </div>
      <div className={Styles.CardBody}>
        <TableOrganism data={props.data} headerList={props.headerList} onClicked={props.sortAsc} onClick={props.sortDesc} />
      </div>
      <div className={Styles.CardFooter}>
        {/* <div className={Styles.PaginationData}>
          <label>
            Showing {props.startIndex} to {props.endIndex} of {props.total}{" "}
            entries
          </label>
        </div> */}
        <div className={Styles.pagination}>
          <PaginationData pageCount={props.pageCount} onPageChange={props.onPageChange} selectedPage={props.selectedPage} />
        </div>
      </div>
    </div>
  )
}

export default TableCard
