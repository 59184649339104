import React, { useEffect, useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import Styles from './ViewQuestion.module.css'
import { viewUrl } from '../../../Helpers/Helper'
import ApiService from '../../../Utiles/API.service'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Questions.module.css'

const ViewQuestion = () => {
  const [viewData, setViewData] = useState({
    objective: { title: '' },
    correct_image: { image: '' },
    wrong_image: { image: '' },
    meta_data: {}
  })
  const [showLoaderState, onShowLoaderState] = useState(false)
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const payload = null
  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }
  useEffect(() => {
    ApiService.get(viewUrl(), payload, headers, showLoader, (responseData, errorData) => {
      setViewData(responseData)
    })
  }, [])

  const navigate = useNavigate()

  const handelBackBtn = () => {
    navigate('/admin/question')
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Questions", path: "/admin/question" }, { title: "View Question", path: "/admin/question/:id", disable: "true" }]} />
      <div className={Style.Container}>
        <Container className={Styles.Container}>
          <Form.Group className={Styles.titleRow}>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Experience</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.objective.title}</Form.Label>
              </Col>
            </Row>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Question</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.question}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Image</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                {!viewData.question_file ? null : <img src={viewData.question_file} alt="" className={Styles.imgContainer} />}
              </Col>
            </Row>
            {/* <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Correct Image</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                {!viewData.correct_image ? null : <img src={viewData.correct_image.image} alt="" className={Styles.imgContainer} />}
              </Col>
            </Row>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Wrong Image</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                {!viewData.wrong_image ? null : <img src={viewData.wrong_image.image} alt="" className={Styles.imgContainer} />}
              </Col>
            </Row> */}

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Question Type</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.type}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Points</Form.Label>
              </Col>
              <Col>
                <Form.Label> {viewData.points} </Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Answer Type</Form.Label>
              </Col>
              <Col>
                <Form.Label> {viewData.type} </Form.Label>
              </Col>
            </Row>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Answer File Type</Form.Label>
              </Col>
              <Col>
                <Form.Label> {viewData.answer_file_type} </Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              {viewData.meta_data === undefined || null
                ? Object.keys(viewData.meta_data).map((key) => {
                  return (
                    <>
                      <Col sm={6} md={4}>
                        <Form.Label className={Styles.titleLabel}>Answer</Form.Label>
                      </Col>
                      <Col sm={6} md={4}>
                        <Form.Label className={Styles.titleInput}>{viewData.meta_data[key].answer}</Form.Label>
                      </Col>
                      <Col sm={6} md={4}>
                        <Form.Label className={Styles.titleInput}>{!viewData.meta_data[key].is_true ? 'No' : 'Yes'}</Form.Label>
                      </Col>
                    </>
                  )
                })
                : null}
            </Row>

            {/* <Row className={Styles.rowBody}>
            <Col sm={6} md={4}>
              <Form.Label className={Styles.titleLabel}>Images</Form.Label>
            </Col>
            <Col sm={6} md={8}>
            <Form.Label className={Styles.titleLabel}>{viewData.images.id}</Form.Label>
            </Col>
          </Row> */}

            <hr />

            <Button variant="" type="submit" className={Styles.backBtn} onClick={handelBackBtn}>
              Back
            </Button>
          </Form.Group>
        </Container>
      </div>
    </>
  )
}

export default ViewQuestion
