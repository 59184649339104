import React from 'react'
import Journeys from '../Pages/Journeys/Journeys'
import AddJourney from '../Pages/Journeys/Add-Journey/AddJourney'
import EditJourney from '../Pages/Journeys/Edit/EditJourney'
import ViewJourney from '../Pages/Journeys/View/ViewJourney'
import { Routes, Route } from 'react-router-dom'
import { NotFound } from '../Pages/NotFound/NotFound'
const JourneysRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Journeys />}></Route>
        <Route path="create" element={<AddJourney />}></Route>
        <Route path=":id/edit" element={<EditJourney />}></Route>
        <Route path=":id" element={<ViewJourney />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default JourneysRoute
