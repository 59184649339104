import React, { useEffect, useState } from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import style from './Answer.module.css'
const Answer = (props) => {
  const [answerList, setAnswerList] = useState([])
  const [answer, setAnswer] = useState('')
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  useEffect(() => {
    if (props.Error !== '') {
      setError(true)
      setErrMsg(props.Error)
    } else {
      setError(false)
    }
  }, [props.Error])

  useEffect(() => {
    setAnswerList(props.answerList)
  })
  useEffect(() => {
    return (props.validator(answerList), props.metaHandler(answerList))
  }, [answerList])

  useEffect(() => { }, [props.answerList])
  const checkHandler = (e, index) => {
    const { name } = e.target
    const list = [...answerList]
    if (e.target.checked === true) {
      list[index][name] = e.target.checked
    } else {
      list[index][name] = e.target.checked
    }
    setAnswerList(list)
  }

  const handelAdd = () => {
    if (answerList.length < 5) {
      props.setAnswerListDefault([...answerList, { answer, is_true: false }])
    } else return null
  }
  const handelRemove = (index) => {
    let list = answerList.filter((_, event) => event !== index)
    props.validator(list)
    props.metaHandler(list)
    props.setAnswerListDefault(list)
  }
  const handelChange = (e, index) => {
    setError(false)
    e.target.value = e.target.value ? e.target.value.trimStart() : ''
    const { name, value } = e.target
    const list = [...answerList]
    list[index][name] = value
    setAnswerList(list)
  }

  return (
    <>
      <Form.Group className={style.container}>
        <Row className={style.RowFirst}>
          <Col className={style.label}>
            <label className={style.astrik} htmlFor="ans">
              Answer
              {!props.required
                ? null
                : (
                  <span id="email-error" className={style.errorAsterisk}>
                    {' '}
                    *{' '}
                  </span>
                )}
            </label>
          </Col>
          <Col sm="10">
            {answerList.map((singleAnswer, index) => (
              <div key={index} className={style.ans}>
                <Form.Control name="answer" type="text" id="answer" className={style.inputZone} value={singleAnswer.answer} onChange={(e) => handelChange(e, index)} />
                <div className="addBtnAline">
                  {index === 0
                    ? (
                      <>
                        {' '}
                        <Button type="button" className={style.addBtn} onClick={handelAdd}>
                          <i className={`fa-solid fa-square-plus ${style.icn}`} />
                        </Button>
                        <input
                          type="checkbox"
                          name="is_true"
                          className={style.input}
                          checked={singleAnswer.is_true}
                          onChange={(e) => {
                            checkHandler(e, index)
                          }}></input>
                      </>
                    )
                    : (
                      <>
                        <Button type="button" className={style.addBtn} onClick={() => handelRemove(index)}>
                          <i className={`fa-solid fa-square-minus ${style.icn}`}></i>
                        </Button>
                        <input
                          type="checkbox"
                          name="is_true"
                          className={style.input}
                          checked={singleAnswer.is_true}
                          onChange={(e) => {
                            checkHandler(e, index)
                          }}></input>
                      </>
                    )}

                  {/* <input type="checkbox" className={style.input} onChange={checkHandler} checked={checked ? true : false}></input> */}
                </div>
              </div>
            ))}
            <Row className={style.ans}>
              {!error
                ? null
                : (
                  <span id="integer-error" className={style.Error}>
                    {errMsg}
                  </span>
                )}
            </Row>
          </Col>

        </Row>
      </Form.Group>
    </>
  )
}
export default Answer
