import React from 'react'
import { Link } from 'react-router-dom'
import style from '../SideNav/SideNav.module.css'
import logo from '../../../Assets/default-avatar.png'
import Rewards from '../../Atoms/JourneysBtn/Rewards'
import Journeys from '../../Atoms/JourneysBtn/Journeys'
import Users from '../../Atoms/JourneysBtn/Users'
import Categories from '../../Atoms/JourneysBtn/Categories'
import Stamps from '../../Atoms/JourneysBtn/Stamps'
import Objective from '../../Atoms/JourneysBtn/Experiences'
import Question from '../../Atoms/JourneysBtn/Questions'
import Tooltip from '../../Molecules/Tooltip/Tooltip'
import Milestones from '../../Atoms/JourneysBtn/Milestones'
const SideNav = ({ isOpen }) => {
  const data = JSON.parse(localStorage.getItem('UserData'))
  const headerInfo = localStorage.getItem('header')

  if (data === null) {
    localStorage.clear()
    window.location.replace('/')
    
  }
  return (
    <>
      <div className={style.container}>
        <div className={isOpen ? style.active : style.sideBar}>
          <div className={style.topSection}>
            <div className={style.avatar}>{!data.profile_photo ? <img className={style.avatarIcn} src={logo}></img> : <img className={style.avatarIcn} src={data.profile_photo.image}></img>}</div>
            <Tooltip direction="bottom" content={data.username}>
              {' '}
              {isOpen === false && <h4 className={style.logo}>{data.username.slice(0, 9)}</h4>}
            </Tooltip>
          </div>

          <Categories isOpen={isOpen} />

          <Objective isOpen={isOpen} />

          <Journeys isOpen={isOpen} />
          {data.groups && data.groups.length !== 0 && data.groups[0].name !== 'Destination Partner' || data.is_admin === true?(<Milestones isOpen={isOpen} />):null}
          

          <Question isOpen={isOpen} />

          <Rewards isOpen={isOpen} />

          <Stamps isOpen={isOpen} />
          {data.is_admin && data.is_admin === true?(<Users isOpen={isOpen} />):null}
          
        </div>
      </div>
    </>
  )
}

export default SideNav
