import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Title from '../../Atoms/InputFields/Title'
import Points from '../../Atoms/InputFields/Points'
import SaveBtn from '../../Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import CancelBtn from '../../Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import ApiService from '../../../Utiles/API.service'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import { toast } from 'react-toastify'
import {addBtnAccessHandler} from '../../../Helpers/Helper'

// Add new component component
const NewMilestone = (props) => {
  const navigate = useNavigate()
  // title, points, description, logoDrag state
  const [title, setTitle] = useState('')
  const [points, setPoints] = useState('')
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [Err, setErr] = useState('')
  const [pointsErr, setPointsErr] = useState('')

  let uData = JSON.parse(localStorage.getItem('UserData'))
  
  // handel title event
  const handelTitle = (e) => {
    setTitle(e.target.value)
    setErr('')
  }

  // handel points event
  const handelPoints = (e) => {
    setPoints(e)
    setPointsErr('')
  }

  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault()
    const payload = {
      title,
      points
    }
    const headers = {
      Token: window.localStorage.getItem('authToken'),
      'Content-Type': 'text/plain'
    }
    const showLoader = (val) => {
      onShowLoaderState(val)
    }

    ApiService.post('api/v1/web-milestone/', payload, headers, showLoader, (responseData, errorData) => {
      try {
        if (!errorData) {
          navigate('/admin/milestone/')
          setTitle('')
          setPoints('')
          toast.success('Milestone added successfully', {
            autoClose: 4000
          })
        }
        if (errorData.points && errorData.points.length !== 0) {
          setPointsErr(errorData.points[0])
        }
        if (errorData.title && errorData.title.length !== 0) {
          setErr(errorData.title[0])
        }
      } catch (e) { }
    })
  }

  // handel cancel event
  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/milestone')
    setTitle('')
    setPoints('')
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Container className="formContainer">
        <Form className="m-2">
          <Title name="Title" onTitleChange={handelTitle} title={title} required={required} Error={Err} />{' '}
          <Points name="Points" onPointsChange={handelPoints} points={points} required={required} Error={pointsErr} />{' '}
          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <CancelBtn handelCancel={handelCancel} />
              <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'milestone')}/>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}

export default NewMilestone
