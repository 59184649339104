import React, { useState } from 'react'
import { Col, FormGroup, Row, Button } from 'react-bootstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Styles from './PasswordReset.module.css'
import Logo from '../../Assets/logo.png'
import { validateLogin } from '../../Helpers/Helper'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'
import axios from 'axios'
import { backendHost as API_URL } from '../../config'
import { toast } from 'react-toastify'

const PasswordReset = () => {
  const [emailErr, setEmailErr] = useState('')
  const [showLoader, onShowLodaer] = useState(false)
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!emailErr) {
      onShowLodaer(true)
      const data = {
        email: e.target.email.value
      }
      const header = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }
      axios
        .post(API_URL.getAPIUrl() + 'api/v1/web/forgot/password/', data, header)
        .then((responseData, responseError) => {
          if (!responseError) {
            toast.success('Password reset e-mail has been sent.', {
              autoClose: 4000,
            });
          }
          setTimeout(() => { window.location.replace('/') }, 5000)
        })
        .catch((error) => {
          if (error.response.data.detail && error.response.data.detail.length !== 0) {
            toast.error(error.response.data.email[0])

            if (error.response.status === 401) {
              setTimeout(() => { window.location.replace('/') }, 5000)
              toast.error('Unauthorized, enter valid authentication credentials.', {
                autoClose: 4000
              })
              return
            }
            if (error.response.data.detail === 'Not found.') {
              alert(error.response.data.detail)
              toast.error('Request not found.', {
                autoClose: 4000
              })
            }
          }
          if (error.response.data.email && error.response.data.email.length !== 0) {
            toast.error(error.response.data.email[0], {
              autoClose: 4000
            })
          }
          onShowLodaer(false)
        })
    } else {
      // Toastify Alert
    }
  }
  const onEmailChangeHandler = (e) => {
    setEmailErr(validateLogin(e.target))
  }
  return (
    <>
      {showLoader && <PageLoader show={showLoader} />}

      <section className={Styles.passwordPageContainer}>
        {/* Login Container */}

        <Row className={` ${Styles.passwordContainer} flex-lg-row`}>
          {/* Right Info( Logo and Information Panel) */}

          <Col className={Styles.rightPanel}>
            <div className={Styles.brandLogoContainer}>
              <img className={Styles.brandLogo} src={Logo} alt="Passport-Atlanta-Logo"></img>
            </div>
          </Col>

          {/* Form Panel */}

          <Col className={Styles.leftPanel}>
            <Form onSubmit={handleSubmit}>
              {' '}
              <FormGroup>
                <FloatingLabel className={`mb-3 ${Styles.passwordFloatingLabel}`} controlId="floatingInput" label="E-Mail Address">
                  <Form.Control
                    className={Styles.formCredentialsInput}
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={(e) => {
                      onEmailChangeHandler(e)
                    }}
                  />
                  {!emailErr
                    ? null
                    : (
                      <span id="email-error" className={Styles.Error}>
                        {emailErr}
                      </span>
                    )}
                </FloatingLabel>
              </FormGroup>
              <Button variant="danger outline-none" className={Styles.passwordButton} type="submit">
                Forgot Password
              </Button>{' '}
              <Button
                variant="danger outline-none"
                className={Styles.passwordButton}
                onClick={() => {
                  navigate('/')
                }}>
                Go Back
              </Button>{' '}
            </Form>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default PasswordReset
