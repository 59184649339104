import { Form, Row, Col } from 'react-bootstrap'
import style from './Atoms.module.css'
import { useEffect, useState } from 'react'

const Title = ({ name, onTitleChange, title, required, Error }) => {
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  useEffect(() => {
    if (Error !== '') {
      setError(true)
      setErrMsg(Error)
    } else {
      setError(false)
      setErrMsg('')
    }
  }, [Error])
  return (
    <>
      {/* title option, journey dropdown  */}
      <Form.Group className={style.titleRow}>
        <Row>
          <Col>
            <Form.Label className={style.titleLabel}>
              {name}{' '}
              {!required
                ? null
                : (
                  <span id="email-error" className={style.errorAsterisk}>
                    *
                  </span>
                )}{' '}
            </Form.Label>
          </Col>
          <Col sm="10">
            <Form.Control
              required
              type="text"
              className={style.titleInput}
              // onChange={onTitleChange}
              onChange={(e) => {
                if (e.target.value.length < 100) {
                  e.target.value = e.target.value ? e.target.value.trimStart() : ''
                  onTitleChange(e)
                  setError(false)
                  setErrMsg()
                } else {
                  setError(true)
                  setErrMsg('Field length cannot exceed 100 characters')
                }
              }}
              value={title}
            />
            <Row className={style.errContainer}>
              {!error
                ? null
                : (
                  <span id='integer-error' className={style.Error}>
                    {errMsg}
                  </span>
                )}
            </Row>
          </Col>
        </Row>
      </Form.Group>
      <hr />
    </>
  )
}

export default Title
