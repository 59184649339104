import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Objectives from '../Pages/Experiences/Experiences'
import AddObjective from '../Pages/Experiences/Add-Objective/AddObjective'
import EditObjective from '../Pages/Experiences/Edit/EditObjective'
import ViewObjective from '../Pages/Experiences/View/ViewObjective'
import { NotFound } from '../Pages/NotFound/NotFound'
const ObjectivesRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Objectives />}></Route>
        <Route path="create" element={<AddObjective />}></Route>
        <Route path=":id/edit" element={<EditObjective />}></Route>
        <Route path=":id" element={<ViewObjective />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default ObjectivesRoute
