import React, { useEffect, useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import Styles from './ViewObjective.module.css'
import { viewExperienceUrl } from '../../../Helpers/Helper'
import ApiService from '../../../Utiles/API.service'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Experiences.module.css'

const ViewJourney = () => {
  const [viewData, setViewData] = useState({ journey: [], images: [] })
  const [showLoaderState, onShowLoaderState] = useState(false)
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const payload = null
  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }
  useEffect(() => {
    ApiService.get(viewExperienceUrl(), payload, headers, showLoader, (responseData, errorData) => {
      setViewData(responseData)
    })
  }, [])

  const navigate = useNavigate()

  const handelBackBtn = () => {
    navigate('/admin/objective')
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Experiences", path: "/admin/objective" }, { title: "View Experience", path: "/admin/objective/:id/", disable: "true" }]} />
      <div className={Style.Container}>
        <Container className={Styles.Container}>
          <Form.Group className={Styles.titleRow}>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Title</Form.Label>
              </Col>

              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.title}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Cover Photo</Form.Label>
              </Col>
              {!viewData.cover_photo
                ? null
                : (
                  <Col sm={6} md={8}>
                    {!viewData.cover_photo ? null : <img src={viewData.cover_photo.thumbnail} alt="" className={Styles.imgContainer} />}
                  </Col>
                )}
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Author Name</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.author_name}</Form.Label>
              </Col>
            </Row>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Journey</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.journey.title}</Form.Label>
              </Col>
            </Row>

            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Description</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                <Form.Label className={Styles.titleInput}>{viewData.description}</Form.Label>
              </Col>
            </Row>
            <Row className={Styles.rowBody}>
              <Col sm={6} md={4}>
                <Form.Label className={Styles.titleLabel}>Images</Form.Label>
              </Col>
              <Col sm={6} md={8}>
                {viewData.images.map((img, index) => {
                  return !img ? null : <img src={img.thumbnail} alt="" className={Styles.titleImage1} />
                })}
              </Col>
            </Row>

            <hr />

            <Button variant="" type="submit" className={Styles.backBtn} onClick={handelBackBtn}>
              Back
            </Button>
          </Form.Group>
        </Container>
      </div>
    </>
  )
}

export default ViewJourney
