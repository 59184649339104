import { Form, Row, Col } from 'react-bootstrap'
import style from './Atoms.module.css'
import { useState, useEffect } from 'react'

const Points = ({ name, onPointsChange, points, required, Error }) => {
  const [positive, setPositive] = useState(false)
  const [positiveCheck, setPositiveCheck] = useState()

  useEffect(() => {
    if (Error !== '') {
      setPositive(true)
      setPositiveCheck(Error)
    } else {
      setPositive(false)
    }
  }, [Error])
  return (
    <>
      {/* title option, journey dropdown  */}
      <Form.Group className={style.titleRow}>
        <Row>
          <Col>
            <Form.Label className={style.titleLabel}>
              {name}
              {!required
                ? null
                : (
                  <span id="email-error" className={style.errorAsterisk}>
                    *
                  </span>
                )}
            </Form.Label>
          </Col>
          <Col sm="10">
            <Form.Control
              type="number"
              className={style.titleInput}
              onWheel={(e) => {
                e.target.blur()
              }}
              onChange={(e) => {
                const re = /^\d*[1-9]\d*$/
                if (e.target.value === '' || (re.test(e.target.value) && e.target.value < 9999999)) {
                  onPointsChange(e.target.value)
                  setPositive(false)
                } else if (e.target.value > 9999999) {
                  setPositive(true)
                  onPointsChange('')
                  setPositiveCheck('Value can not be more than 7 digits')
                } else {
                  e.target.value = 0
                  setPositive(true)
                  onPointsChange('')
                  setPositiveCheck('Value can not be zero or less than zero ')
                }
              }}
              value={points}
              min="0"
              max="9999999"
              step="1"
            />
            <Row className={style.errContainer}>
              {!positive
                ? null
                : (
                  <span id="integer-error" className={style.Error}>
                    {positiveCheck}
                  </span>
                )}
            </Row>
          </Col>
        </Row>
      </Form.Group>
      <hr />
    </>
  )
}

export default Points
