import React from 'react'
import EditReward from '../../../Components/Organisms/EditReward/EditReward'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Reward.module.css'
const EditCoupon = () => {
  return (
    <>
      <FormTitle title={[{ title: "Rewards", path: "/admin/coupon" }, { title: "Edit Reward", path: "/admin/coupon/:id/edit", disable: "true" }]} />
      <div className={Style.Container}>
        <EditReward />
      </div>
    </>
  )
}

export default EditCoupon
