import { Form, Row, Col, FloatingLabel } from 'react-bootstrap'
import style from './Atoms.module.css'
import { useState, useEffect } from 'react'

const Description = ({ name, onDescriptionChange, description, required, Error }) => {
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  useEffect(() => {
    if (Error !== '') {
      setError(true)
      setErrMsg(Error)
    } else {
      setError(false)
    }
  }, [Error])
  return (
    <>
      {/* Description option, journey dropdown */}
      <Form.Group className={style.descriptionRow}>
        <Row>
          <Col>
            <Form.Label className={style.descriptionLabel}>
              {name}
              {!required
                ? null
                : (
                  <span id='email-error' className={style.errorAsterisk}>
                    {' '}
                    *{' '}
                  </span>
                )}
            </Form.Label>
          </Col>
          <Col sm='10'>
            <FloatingLabel>
              <Form.Control
                as='textarea'
                className={style.descriptionInput}
                style={{ height: '120px' }}
                // onChange={onDescriptionChange}
                onChange={(e) => {
                  if (e.target.value.length < 1000) {
                    e.target.value = e.target.value
                      ? e.target.value.trimStart()
                      : ''
                    setError(false)
                    onDescriptionChange(e)
                  } else {
                    setError(true)
                    setErrMsg('Field length cannot exceed more than 1000 character')
                  }
                }}
                value={description}
              />
              <Row className={style.errContainer}>
                {!error
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsg}
                    </span>
                  )}
              </Row>
            </FloatingLabel>
          </Col>
        </Row>
      </Form.Group>
      <hr />
    </>
  )
}
export default Description
