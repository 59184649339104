import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import Styles from './CouponSearch.module.css'
import AsyncSelect from 'react-select/async'

export const CouponSearch = (props) => {
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  const defaultStyles = {
    control: (base, state) => ({
      ...base
    }),
    menu: (base) => ({
      ...base
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "150px" // your desired height
    })
  };
  useEffect(() => {
    if (props.Error !== '') {
      setError(true)
      setErrMsg(props.Error)
    } else {
      setError(false)
    }
  }, [props.Error])

  return (
    <>
      <Row>
        <Col className={Styles.titleRow}>
          <Form.Label className={Styles.titleLabel}>
            {props.label}
            {!props.required
              ? null
              : (
                <span id="email-error" className={Styles.errorAsterisk}>
                  {' '}
                  *{' '}
                </span>
              )}
          </Form.Label>
        </Col>
        <Col sm="10" className={Styles.logos}>
          <AsyncSelect
            isMulti
            cacheOptions
            loadOptions={(inputValue, callback) => props.fetchRewardsList(inputValue, callback)}
            maxMenuHeight={70}
            isClearable={true}
            className={Styles.asyncSearch}
            defaultOptions={props.defaultList}
            styles={defaultStyles}
            onChange={(e) => props.didRewardSelect(e)}
            value={props.rewardsValues}
          />
          <Row className={Styles.errContainer}>
            {!error
              ? null
              : (
                <span id='integer-error' className={Styles.Error}>
                  {errMsg}
                </span>
              )}
          </Row>
        </Col>
      </Row>
      <hr />
    </>
  )
}
