import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Categories from '../Pages/Categories/Category'
import AddCategory from '../Pages/Categories/AddCategory/AddCategory'
import EditCategory from '../Pages/Categories/Edit/EditCategory'
import { NotFound } from '../Pages/NotFound/NotFound'
const CategoriesRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Categories />}></Route>
        <Route path="create" element={<AddCategory />}></Route>
        <Route path=":id/edit" element={<EditCategory />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default CategoriesRoute
