import React from 'react'
import NewStamps from '../../../Components/Organisms/AddNewStamps/NewStamps'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Stamps.module.css'

const AddStamp = () => {
  return (
    <>
      <FormTitle title={[{ title: "Stamps", path: '/admin/stamp' }, { title: "Add New Stamp", path: '/admin/stamp/create', disable: "true" }]} />
      <div className={Style.Container}>
        {' '}
        <NewStamps />
      </div>
    </>
  )
}

export default AddStamp
