import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import DragDrop from "../../../Components/Atoms/InputFields/DragDrop";
import Title from "../../../Components/Atoms/InputFields/Title";
import CancelBtn from "../../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn";
import DropDown from "../../../Components/Atoms/InputFields/DropDown";
import SaveBtn from "../../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn";
import ApiService from "../../../Utiles/API.service";
import { useNavigate } from "react-router-dom";
import { validateLogin } from "../../../Helpers/Helper";
import Styles from "../Users.module.css";
import PageLoader from "../../../Components/Molecules/PageLoader/PageLoader";
import FormTitle from "../../../Components/Molecules/FormTitle/FormTitle";
import { toast } from "react-toastify";
import Email from "../../../Components/Atoms/InputFields/Email";
import { PermissionModule } from "../../../Components/Organisms/PermissionModule/PermissionModule";

let payload = null;

// Add new component component
const AddUser = (props) => {
  const showLoader = (val) => {
    onShowLoaderState(val);
  };
  const navigate = useNavigate();
  const [isAdmin, setUserRole] = useState(null);
  const [rewardsHandler, setRewardsHandler] = useState("partial");
  const [stampsHandler, setStampsHandler] = useState("partial");
  const [categoriesHandler, setCategoriesHandler] = useState("partial");
  const [journeysHandler, setJourneysHandler] = useState("partial");
  const [experiencesHandler, setExperiencesHandler] = useState("partial");
  const [questionsHandler, setQuestionsHandler] = useState("partial");
  const [milestonesHandler, setMilestonesHandler] = useState("partial");
  const [errUser, setErrUser] = useState("");
  const [password, setPassword] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [dragAction, setDragAction] = useState(false);
  const [imageAction, setImageAction] = useState(false);
  const [editFile, setEditFile] = useState("");
  const [editImage, setEditImage] = useState("");
  const [showLoaderState, onShowLoaderState] = useState(false);
  const [required, setRequired] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [imageErr, setImageErr] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [user_type, setUserType] = useState(null);

  const uData = JSON.parse(localStorage.getItem('UserData'))
  useEffect(() => {
    if (uData.is_admin === false) {
      window.location.replace('/admin/home/')
    }
  }, [])

  const handelDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragAction(true);
    } else if (e.type === "dragleave") {
      setDragAction(false);
    }
  };

  // trigger when file is dropped
  // this function reset the dragAction to false
  const handelDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragAction(false);
  };

  // trigger when file is selected with clicked
  const handelChanges = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setEditFile(e.target.files[0]);
      setEditImage(URL.createObjectURL(e.target.files[0]));
      setImageErr("");
    }
    if (!editImage) {
      setImageAction(true);
      setImageErr("");
    } else if (editImage) {
      setImageAction(false);
    }
  };

  const handelFirstName = (e) => {
    e.preventDefault();
    setFirstName(e.target.value);
    setFirstNameErr("");
  };

  const handelLastName = (e) => {
    e.preventDefault();
    setLastName(e.target.value);
    setLastNameErr("");
  };

  const handelEmail = (e) => {
    setEmailErr(validateLogin(e.target));
    setEditEmail(e.target.value);
  };

  const roleHandler = (e) => {
    setUserRole(parseInt(e));
    setErrUser("");
  };

  const stampHandler = (e) => {
    setStampsHandler(e.target.value);
  };

  const rewardHandler = (e) => {
    setRewardsHandler(e.target.value);
  };

  const categoryHandler = (e) => {
    setCategoriesHandler(e.target.value);
  };
  const experienceHandler = (e) => {
    setExperiencesHandler(e.target.value);
  };

  const journeyHandler = (e) => {
    setJourneysHandler(e.target.value);
  };

  const questionHandler = (e) => {
    setQuestionsHandler(e.target.value);
  };
  const milestoneHandler = (e) => {
    setMilestonesHandler(e.target.value);
  };

  const saveImages = () => {
    const headers = {
      Token: window.localStorage.getItem("authToken"),
      "Content-Type": "text/plain",
    };
    const user = user_type.map(results => results.id);

    const selectedUser = user.filter(id => id === isAdmin)

    // if (editFile) {
    const formData = new FormData();
    formData.append("image", editFile);
    ApiService.post("api/v1/image/", formData, headers, showLoader, (responseData, responseError) => {
      try {
        payload = {
          first_name: firstName,
          last_name: lastName,
          email: editEmail,
          profile_photo: responseData.id,
          groups: [isAdmin],
          is_admin: selectedUser[0] === "4"||selectedUser[0] === 4 ?true :false,
          user_permissions: {
            stamp: {
              access_level: stampsHandler,
            },
            journey: {
              access_level: journeysHandler,
            },
            reward: {
              access_level: rewardsHandler,
            },
            category: {
              access_level: categoriesHandler,
            },
            question: {
              access_level: questionsHandler,
            },
            experience: {
              access_level: experiencesHandler,
            },
            milestone: {
              access_level: milestonesHandler,
            }
          },
        };
        ApiService.post("api/v1/web/user/", payload, headers, showLoader, (responseData, responseError) => {
          if (!responseError) {
            navigate("/admin/user");
            setFirstName("");
            setLastName("");
            setEditImage();
            setImageAction(false);
            setEditFile("");

            toast.success("User created successfully", {
              autoClose: 4000,
            });
          } else {
            if (responseError.first_name && responseError.first_name.length !== 0) {
              setFirstNameErr(responseError.first_name[0]);
            }
            if (responseError.last_name && responseError.last_name.length !== 0) {
              setLastNameErr(responseError.last_name[0]);
            }
            if (responseError.email && responseError.email.length !== 0) {
              setEmailErr(responseError.email[0]);
            }
            if (responseError.is_admin && responseError.is_admin.length !== 0) {
              setErrUser(responseError.is_admin[0]);
            }
            if (responseError.groups && responseError.groups.length !== 0) {
              toast.error('Please select a User Role Type');
            }
          }
        });
      } catch (e) {
        payload = {
          first_name: firstName,
          last_name: lastName,
          email: editEmail,
          groups: [isAdmin],
          is_admin: selectedUser[0] === "4"||selectedUser[0] === 4 ?true :false,
          profile_photo: null,
          user_permissions: {
            stamp: {
              access_level: stampsHandler,
            },
            journey: {
              access_level: journeysHandler,
            },
            reward: {
              access_level: rewardsHandler,
            },
            category: {
              access_level: categoriesHandler,
            },
            question: {
              access_level: questionsHandler,
            },
            experience: {
              access_level: experiencesHandler,
            },
            milestone: {
              access_level: milestonesHandler,
            }
          },
        };
        ApiService.post("api/v1/web/user/", payload, headers, showLoader, (responseData, responseError) => {
          if (!responseError) {
            navigate("/admin/user");
            setFirstName("");
            setLastName("");
            setEditImage();
            setImageAction(false);
            setEditFile("");

            toast.success("User created successfully", {
              autoClose: 4000,
            });
          } else {
            if (responseError.first_name && responseError.first_name.length !== 0) {
              setFirstNameErr(responseError.first_name[0]);
            }
            if (responseError.last_name && responseError.last_name.length !== 0) {
              setLastNameErr(responseError.last_name[0]);
            }
            if (responseError.email && responseError.email.length !== 0) {
              setEmailErr(responseError.email[0]);
            }
            if (responseError.profile_photo && responseError.profile_photo.length !== 0) {
              setImageErr(responseError.profile_photo[0]);
            }
            if (responseError.is_admin && responseError.is_admin.length !== 0) {
              setErrUser(responseError.is_admin[0]);
            }
            if (responseError.groups && responseError.groups.length !== 0) {
              toast.error('Please select a User Role Type');
            }
          }
        });
      }
    });
  };

  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault();
    saveImages();
  };

  // handel cancel event
  const handelCancel = (e) => {
    navigate("/admin/user");
    e.preventDefault();
    setFirstName("");
    setLastName("");
    setEditImage();
    setImageAction(false);
  };

  // remove image
  const removeImage = (e) => {
    e.preventDefault();
    setEditImage("");
    setEditFile("");
    setImageAction(false);
  };

  //get user Role
  useEffect(() => {
    const headers = {
      Token: window.localStorage.getItem("authToken"),
      "Content-Type": "text/plain",
    };
    ApiService.get("api/v1/group/", payload, headers, showLoader, (responseData, errorData) => {
      setUserType(responseData.results);
    });
  }, []);

  // if Editing
  const isEdit = false;
  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle
        title={[
          { title: "Users", path: "/admin/user" },
          { title: "Add New User", path: "/admin/user/create", disable: "true" },
        ]}
      />
      <div className={Styles.Container}>
        <Container className="formContainer">
          <Form className="m-2">
            <DropDown name="User Role" dropDefault="Select User Role" handler={roleHandler} data={user_type} type={"userRole"} showDefaultOption={true} required={required} ErrorUser={errUser} />
            <Title name="First Name" onTitleChange={handelFirstName} title={firstName} required={required} Error={firstNameErr} />
            <Title name="Last Name" onTitleChange={handelLastName} title={lastName} required={required} Error={lastNameErr} />
            <Email name="Email" onTitleChange={handelEmail} title={editEmail} required={required} Error={emailErr} />{" "}
            {/* <Password name="Password" onPasswordChange={handelPassword} title={editEmail} required={required} /> */}
            <DragDrop
              onDrag={handelDrag}
              onDrop={handelDrop}
              onChange={handelChanges}
              image={editImage}
              dragAction={dragAction}
              removeImage={removeImage}
              imageAction={imageAction}
              file={editFile}
              name="Image"
              isEdit={isEdit}
              required={required}
              Error={imageErr}
            />
            <div>{!editImage ? null : <img src={editImage} className={Styles.imgContainer}></img>}</div>
            {isAdmin === "2" || isAdmin === "3" || isAdmin === 3 || isAdmin === 2 ? (
              <PermissionModule
                userRole={isAdmin}
                rewardHandler={rewardHandler}
                stampHandler={stampHandler}
                categoryHandler={categoryHandler}
                journeyHandler={journeyHandler}
                experienceHandler={experienceHandler}
                questionHandler={questionHandler}
                milestoneHandler={milestoneHandler}
                defaultReward={rewardsHandler !== undefined ? rewardsHandler : "partial"}
                defaultStamp={stampsHandler !== undefined ? stampsHandler : "partial"}
                defaultCategory={categoriesHandler !== undefined ? categoriesHandler : "partial"}
                defaultQuestion={questionsHandler !== undefined ? questionsHandler : "partial"}
                defaultExperience={experiencesHandler !== undefined ? experiencesHandler : "partial"}
                defaultJourney={journeysHandler !== undefined ? journeysHandler : "partial"}
                defaultMilestone={isAdmin === 2 || isAdmin === "2" ? (milestonesHandler !== undefined ? milestonesHandler : "partial") : null}
              />
            ) : null}
            <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <CancelBtn handelCancel={handelCancel} />
                <SaveBtn handelSubmit={handelSubmit} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddUser;
