import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import Styles from './SearchableDropdown.module.css'
import AsyncSelect from 'react-select/async'

export const SearchableDropdown = (props) => {
    const [error, setError] = useState(false)
    const [errMsg, setErrMsg] = useState()

    const defaultStyles = {
        control: (base, state) => ({
            ...base
        }),
        menu: (base) => ({
            ...base
        }),
        menuList: (base) => ({
            ...base,
            minHeight: "200px", // your desired height
            margin: "5px"
        })
    };

    useEffect(() => {
        if (props.Error !== '') {
            setError(true)
            setErrMsg(props.Error)
        } else {
            setError(false)
        }
    }, [props.Error])
    return (
        <>
            <Row>
                <Col className={Styles.titleRow}>
                    <Form.Label className={Styles.titleLabel}>
                        {props.label}
                        {!props.required
                            ? null
                            : (
                                <span id="email-error" className={Styles.errorAsterisk}>
                                    {' '}
                                    *{' '}
                                </span>
                            )}
                    </Form.Label>
                </Col>
                <Col sm="10" className={Styles.logos}>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions={props.defaultList}
                        loadOptions={(inputValue, callback) => props.fetchList(inputValue, callback)}
                        maxMenuHeight={70}
                        isClearable={true}
                        className={Styles.asyncSearch}
                        styles={defaultStyles}
                        onChange={(e) => props.didSelect(e)}
                        value={props.Values}
                    />
                    <Row className={Styles.errContainer}>
                        {!error
                            ? null
                            : (
                                <span id='integer-error' className={Styles.Error}>
                                    {errMsg}
                                </span>
                            )}
                    </Row>
                </Col>
            </Row>
            <hr />
        </>
    )
}
