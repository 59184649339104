import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Rewards from '../Pages/Rewards/Rewards'
import AddReward from '../Pages/Rewards/AddReward/AddReward'
import EditReward from '../Pages/Rewards/Edit/EditReward'
import { NotFound } from '../Pages/NotFound/NotFound'
const CouponsRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Rewards />} />
        <Route path="create" element={<AddReward />} />
        <Route path=":id/edit" element={<EditReward />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default CouponsRoute
