import React, { useState, useEffect } from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import style from './StartCloseTime.module.css'

const StartCloseTime = (props) => {
  const [start_time, setStart_time] = useState('')
  const [end_time, setEnd_time] = useState('')
  useEffect(() => {
    if (props.data.start_time != '' && props.data.start_time != null && props.data.end_time != '' && props.data.end_time != null) {
      setStart_time(props.data.start_time.split('T')[1].split('Z')[0])
      setEnd_time(props.data.end_time.split('T')[1].split('Z')[0])
    }
  }, [props.data.start_time, props.data.end_time])
  return (
    <>
      <Form.Group className={style.scTimeRow} onChange={props.handler}>
        <Row>
          <Col md={2}>
            <Form.Label className={style.scTimeLabel}>{props.data.day}</Form.Label>
          </Col>
          <Col md={2} className={style.scTimeCol}>
            <Form.Control onChange={(e) => props.journeyHandler(e.target.name, e.target.value, props.data)} type="time" className={style.scTimeInput} name="start_time" defaultValue={start_time} />
          </Col>
          <Col md={2} className={style.scTimeCol}>
            <Form.Control onChange={(e) => props.journeyHandler(e.target.name, e.target.value, props.data)} type="time" className={style.scTimeInput} name="end_time" defaultValue={end_time} />
          </Col>
        </Row>
      </Form.Group>
      <hr />
    </>
  )
}

export default StartCloseTime
