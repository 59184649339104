import React, { useEffect, useState } from 'react'
import TableCard from '../../Components/Organisms/TableCard/TableCard'
import { AddButton } from '../../Components/Atoms/Buttons/AddButton/AddButton'
import { DownloadButton } from '../../Components/Atoms/Buttons/DownloadButton/DownloadButton'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'
import ApiService from '../../Utiles/API.service'
import FormTitle from '../../Components/Molecules/FormTitle/FormTitle'
import Style from './Questions.module.css'
import { addBtnAccessHandler } from '../../Helpers/Helper'
import Tooltip from '../../Components/Molecules/Tooltip/Tooltip'
const Questions = () => {
  const [list, setList] = useState('')
  const [page_size, setPage_Size] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [endIndex, setEndIndex] = useState(1)
  const [startIndex, setStartIndex] = useState(0)
  const [totalItems, setTotalItems] = useState('')
  const [pagination, setPagination] = useState([])
  const [pageCount, setpageCount] = useState()
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [questionData, setQuestionData] = useState({ results: [] })

  const HeaderList = [{ value: 'Name', type: 'question' }, { value: 'Experience', type: 'objective' }, { value: 'Points', type: 'points' }, { value: 'Actions' }]
  const searchHandler = (event) => {
    setList(event)
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const payload = { search: list }
  const headers = { Token: window.localStorage.getItem('authToken') }
  useEffect(() => {
    localStorage.setItem('header', 'Questions')
  })
  useEffect(() => {
    const getPaginateData = async () => {
      await ApiService.get('api/v1/question/', payload, headers, showLoader, (responseData, errorData) => {
        try {
          setQuestionData(responseData)
          setTotalItems(responseData.count)
          const total = responseData.count
          setpageCount(Math.ceil(total / page_size))
        } catch {
          return errorData
        }
      })
    }
    getPaginateData()
  }, [list])

  const getQuestionData = async (currentPage) => {
    const response = await ApiService.get(`api/v1/question/?page=${currentPage}&page_size=${page_size}`, payload, headers, showLoader, (responseData, errorData) => {
      setQuestionData(responseData)
      return questionData
    })
  }

  const handlePageClick = async (total) => {
    const currentPage = total.selected + 1

    const commentsFormServer = await getQuestionData(currentPage)
    setPagination(commentsFormServer)
    setStartIndex((currentPage - 1) * questionData.results.length + 1)
    setEndIndex((currentPage - 1) * questionData.results.length + questionData.results.length)
  }
  const sIndex = (currentPage - 1) * questionData.results.length
  const eIndex = sIndex + questionData.results.length

  useEffect(() => {
    setEndIndex(eIndex)
    setStartIndex(sIndex)
  }, [eIndex, sIndex])

  const pageHandler = (e) => {
    setPage_Size(e)
  }

  const sortingAsc = (type) => {
    ApiService.get(`api/v1/question/?ordering=${type}`, payload, headers, showLoader, (responseData, errorData) => {
      setQuestionData(responseData)
    })
  }

  const sortingDesc = (type) => {
    ApiService.get(`api/v1/question/?ordering=-${type}`, payload, headers, showLoader, (responseData, errorData) => {
      setQuestionData(responseData)
    })
  }

  return (
    <>
      {' '}
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Questions", path: "/admin/question", disable: "true" }]} />
      <div className={Style.Container}>
        <div className={Style.tableHead}>
          <AddButton url="create" disableAccess={addBtnAccessHandler(uData, 'question')} />
          <Tooltip direction="bottom" content={"Download Questions"}><DownloadButton searchQuery={list} /></Tooltip>
        </div>
        <TableCard
          startIndex={sIndex}
          endIndex={eIndex}
          total={totalItems}
          data={questionData.results}
          searchHandler={searchHandler}
          headerList={HeaderList}
          handler={pageHandler}
          totalItems={totalItems}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          sortAsc={sortingAsc}
          sortDesc={sortingDesc}
          selectedPage={currentPage}
        />
      </div>
    </>
  )
}

export default Questions
