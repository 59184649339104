import { Form, Col, Row } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import style from './Atoms.module.css'
import { toast } from 'react-toastify'

const ImgUpload = ({ name, onChange, imgSrc, required, Error }) => {
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  useEffect(() => {
    if (Error !== '') {
      setError(true)
      setErrMsg(Error)
    } else {
      setError(false)
    }
  }, [Error])
  const validator = (e) => {
    const filePath = e.target.value;

    // Allowing file type
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i

    if (!allowedExtensions.exec(filePath)) {
      toast.error('Invalid file type')
      e.target.value = ''
      return false
    }
    else {
      onChange(e)
      setError(false)
    }
  }
  return (
    <>
      <Form.Group className={style.titleRow}>
        <Row>
          <Col>
            <Form.Label className={style.titleLabel}>
              {name}{' '}
              {!required
                ? null
                : (
                  <span id="email-error" className={style.errorAsterisk}>
                    *
                  </span>
                )}
            </Form.Label>
          </Col>
          <Col sm="10">
            <input type="file" accept="image/*" multiple={false} onChange={validator} />
            <div>
              <Row className={style.errContainer}>
                {!error
                  ? (
                    !imgSrc
                      ? null
                      : (
                        <img src={imgSrc} className={style.imgContainer}></img>
                      )
                  )
                  : (<span id="integer-error" className={style.Error}>
                    {errMsg}
                  </span>
                  )}
              </Row>

            </div>
          </Col>

        </Row>
      </Form.Group>
      <hr />
    </>
  )
}
export default ImgUpload
