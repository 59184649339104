import React from 'react'
import filterIcn from '../../../Assets/filter.svg'
import style from './FilterDropdown.module.css'
const FilterDropdown = (props) => {
    return (
        <div>
            <img src={filterIcn}></img>
            {props.header === 'Journeys'
                ? (
                    <select className={style.dropdownContainer} value={props.defaultFilter} onChange={props.handler}>
                        <option value={'none'} disabled={true}>Add Filter</option>
                        <option value={true}>Show Active</option>
                        <option value={false}>Show InActive</option>
                        
                    </select>
                )
                : (
                    <select className={style.dropdownContainer} value={props.defaultFilter} onChange={props.handler}>
                        <option value={'none'} disabled={true}>Add Filter</option>
                        <option value={'today'}>Today</option>
                        <option value={'last_week'}>Last Week</option>
                        <option value={'last_month'}>Last Month</option>
                        <option value={'last_quarter'}>Last Quarter</option>
                        <option value={'last_year'}>Last Year</option>
                    </select>
                )
            }


        </div>
    )
}

export default FilterDropdown