import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Description from '../../Atoms/InputFields/Description'
import DragDrop from '../../Atoms/InputFields/DragDrop'
import Title from '../../Atoms/InputFields/Title'
import Points from '../../Atoms/InputFields/Points'
import SaveBtn from '../../Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import CancelBtn from '../../Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import { editCategory,addBtnAccessHandler } from '../../../Helpers/Helper'
import Styles from './EditReward.module.css'
import ApiService from '../../../Utiles/API.service'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import { toast } from 'react-toastify'
// Add new component component
const EditReward = (props) => {
  const navigate = useNavigate()
  const headers = {
    Token: window.localStorage.getItem('authToken'),
    'Content-Type': 'text/plain'
  }
  const payload = null
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  // title, points, description, logoDrag state
  const [editTitle, setEditTitle] = useState('')
  const [errTitle, setErrTitle] = useState('')
  const [editPoints, setEditPoints] = useState('')
  const [errPoints, setErrPoints] = useState('')
  const [editDescription, setEditDescription] = useState('')
  const [errDescription, setErrDescription] = useState('')
  const [dragAction, setDragAction] = useState(false)
  const [imageAction, setImageAction] = useState(false)
  const [errImage, setErrImage] = useState('')
  const [editFile, setEditFile] = useState('')
  const [editImage, setEditImage] = useState('')
  const [required, setRequired] = useState(true)
  const [showLoaderState, onShowLoaderState] = useState(false)


  useEffect(() => {
    ApiService.get(editCategory(), payload, headers, showLoader, (responseData, responseError) => {
      setEditTitle(responseData.title)
      setEditPoints(responseData.points)
      setEditDescription(responseData.description)
      setEditImage(responseData.logo)
    })
  }, [])

  // handel drag event
  const handelDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragAction(true)
    } else if (e.type === 'dragleave') {
      setDragAction(false)
    }
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  // trigger when file is dropped
  // this function reset the dragAction to false
  const handelDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragAction(false)
  }

  // trigger when file is selected with clicked
  const handelChanges = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setEditFile(e.target.files[0])
      setEditImage(URL.createObjectURL(e.target.files[0]))
    }
    if (!editImage) {
      setImageAction(true)
    } else if (editImage) {
      setImageAction(false)
    }
  }

  // handel title event
  const handelTitle = (e) => {
    setEditTitle(e.target.value)
    setErrTitle('')
  }

  // handel points event
  const handelPoints = (e) => {
    setEditPoints(e)
    setErrPoints('')
  }

  // handel description event
  const handelDescription = (e) => {
    setEditDescription(e.target.value)
    setErrDescription('')
  }

  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault()
    if (editFile) {
      const formData = new FormData()
      formData.append('title', editTitle && editTitle !== '' ? editTitle : '')
      formData.append('points', editPoints && editPoints !== '' ? editPoints : '')
      formData.append('description', editDescription && editDescription !== '' ? editDescription : '')
      formData.append('logo', editFile && editFile !== '' ? editFile : '')

      ApiService.patch(editCategory(), formData, headers, showLoader, (responseData, responseError) => {
        if (!responseError) {
          navigate('/admin/coupon')
          setEditTitle('')
          setEditPoints('')
          setEditDescription('')
          setEditImage()
          setImageAction(false)
          setEditFile('')

          toast.success('Reward updated successfully.', {
            autoClose: 4000
          })
        } else if (responseError) {
          if (responseError.title && responseError.title.length !== 0) {
            setErrTitle(responseError.title[0])
          }
          if (responseError.logo && responseError.logo.length !== 0) {
            setErrImage(responseError.logo)
          }
          if (responseError.description && responseError.description.length !== 0) {
            setErrDescription(responseError.description)
          }
          if (responseError.points && responseError.points.length !== 0) {
            setErrPoints(responseError.points)
          }
        }
      })
    } else {
      const formData = new FormData()
      formData.append('title', editTitle && editTitle !== '' ? editTitle : '')
      formData.append('points', editPoints && editPoints !== '' ? editPoints : '')
      formData.append('description', editDescription && editDescription !== '' ? editDescription : '')
      ApiService.patch(editCategory(), formData, headers, showLoader, (responseData, responseError) => {
        if (!responseError) {
          navigate('/admin/coupon')
          setEditTitle('')
          setEditPoints('')
          setEditDescription('')
          setEditImage()
          setImageAction(false)
          setEditFile('')
          toast.success('Reward updated successfully.', {
            autoClose: 4000
          })
        } else if (responseError) {
          if (responseError.title && responseError.title.length !== 0) {
            setErrTitle(responseError.title[0])
          }
          if (responseError.logo && responseError.logo.length !== 0) {
            setErrImage(responseError.logo)
          }
          if (responseError.description && responseError.description.length !== 0) {
            setErrDescription(responseError.description)
          }
          if (responseError.points && responseError.points.length !== 0) {
            setErrPoints(responseError.points)
          }
        }
      })
    }
  }

  // handel cancel event
  const handelCancel = (e) => {
    e.preventDefault()
    navigate('/admin/coupon')
    setEditTitle('')
    setEditPoints('')
    setEditDescription('')
    setEditImage()
    setImageAction(false)
  }

  // remove image
  const removeImage = (e) => {
    e.preventDefault()
    setEditFile()
    setEditImage()
    setImageAction(false)
  }
  // if Editing
  const isEdit = false

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Container className="formContainer">
        <Form className="m-2">
          <Title name="Title" onTitleChange={handelTitle} title={editTitle} required={required} Error={errTitle} />

          <DragDrop
            onDrag={handelDrag}
            onDrop={handelDrop}
            onChange={handelChanges}
            image={editImage}
            dragAction={dragAction}
            removeImage={removeImage}
            imageAction={imageAction}
            file={editFile}
            name="Logo"
            isEdit={isEdit}
            required={required}
            Error={errImage}
          />
          {!editImage ? null : <img src={editImage} className={Styles.imgContainer} alt=""></img>}
          <Points name="Points" onPointsChange={handelPoints} points={editPoints} required={required} Error={errPoints} />

          <Description name="Description" onDescriptionChange={handelDescription} description={editDescription} required={required} Error={errDescription} />

          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <CancelBtn handelCancel={handelCancel} />
              <SaveBtn handelSubmit={handelSubmit} disableAccess={addBtnAccessHandler(uData,'reward')} />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}
export default EditReward
