import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import ApiService from '../../../../Utiles/API.service'
import PageLoader from '../../../Molecules/PageLoader/PageLoader'
export const Switch = (props) => {
  const [checked, setChecked] = useState(false)
  const [activePresent, setActivePresent] = useState(false)
  const [disable, setDisable] = useState(false)
  const [showLoaderState, onShowLoaderState] = useState(false)
  useEffect(() => { return (setChecked(props.checked), setActivePresent(props.active)) }, [props.active, props.checked])
  const showLoader = (val) => {
    onShowLoaderState(val)
  }

  const url = window.location.pathname.split('/')
  const headers = { Token: window.localStorage.getItem('authToken') }
  const switchHandler = (data) => {
    setDisable(true)
    const endpoint = `api/v1/${url[2]}/${props.id}/`
    if (activePresent === true) {
      setChecked(!data)
      const payloadActive = { is_active: !data }
      ApiService.patch(endpoint, payloadActive, headers, showLoader, (responseData, errorData) => {
        setDisable(false)
      })
    } else if (activePresent === false) {
      setChecked(!data)
      const payloadAr = { is_ar: !data }
      ApiService.patch(endpoint, payloadAr, headers, showLoader, (responseData, errorData) => { setDisable(false) })
    }
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Form.Check
        type='switch'
        value={checked}
        checked={checked}
        id='custom-switch'
        onChange={(e) => {
          switchHandler(checked)
        }}
        disabled={props.disableAccess?props.disableAccess:disable}
      />
    </>
  )
}
