import { useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import style from './Atoms.module.css'

const DragDrop = ({ name, removeImage, image, imageAction, onChange, onDrag, file, onDrop, dragAction, isEdit, required, Error }) => {
  const [detailShow, setDetailShow] = useState(false)
  const [val, setVal] = useState('')
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  useEffect(() => {
    if (Error !== '') {
      setError(true)
      setErrMsg(Error)
    } else {
      setError(false)
    }
  }, [Error])

  useEffect(() => {
    if (image.length === 0) {
      setVal('')
    }
  }, [image])

  // const imgSize = imgFile.size / 1000;
  const handleHover = (val) => {
    setDetailShow(val)
  }

  return (
    <>
      {/* Logo option, journey dropdown */}
      <Form.Group className={style.logoRow} onDragEnter={onDrag} onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col md={2}>
            <p className={style.logoLabel}>
              {name}
              {!required
                ? null
                : (
                  <span id="email-error" className={style.errorAsterisk}>
                    *
                  </span>
                )}{' '}
            </p>
          </Col>
          <Col>
            <Col md={12} style={{display: "inline-grid"}}>
              <div className={style.dropZone}>
                <div >
                  <h6 className={imageAction ? style.dzTextimage : style.dzText}>Drop files here or click to upload</h6>
                  <input
                    type="file"
                    accept="image/*"
                    multiple={true}
                    value={val}
                    onChange={(e) => {
                      setError(false)
                      onChange(e)
                    }}
                    className={style.dzHide}
                  />
                </div>
                  {
                <div style={{ border: "none", width: "fit-content", margin: "auto", overflowX: "hidden", height: "fit-content",display:"flex" }} className={imageAction ? style.dzImagePreview && style.dropZone : style.dzHidden}>
                { image.map((f, index) => (
                          <div key={index} className={style.dzImagePreview }>
                            <div className={style.dzImage}>
                              <img className={style.logoUpload} src={image[index]} alt={image[index]} onMouseOver={() => handleHover(true)} onMouseLeave={() => handleHover(false)} />
                            </div>

                            <div className={style.removeImage}>
                              <Link to="#" className={style.removeLink} onClick={(e) => removeImage(e, index)}>
                                Remove image
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                  }
                </div>
            </Col>
            {/* <Col className={style.editImageCol}>
              <img
                src={image}
                alt={imgFile.name}
                className={isEdit ? style.editImage : style.isEdit}
              />
            </Col> */}
            <Row className={style.errContainer}>
              {!error
                ? null
                : (
                  <span id='integer-error' className={style.Error}>
                    {errMsg}
                  </span>
                )}
            </Row>
          </Col>
        </Row>

      </Form.Group>
      <hr />
    </>
  )
}
export default DragDrop
