import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import DragDrop from "../../../Components/Atoms/InputFields/DragDrop";
import Title from "../../../Components/Atoms/InputFields/Title";
import CancelBtn from "../../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn";
import SaveBtn from "../../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn";
import ApiService from "../../../Utiles/API.service";
import { useNavigate } from "react-router-dom";
import { editUserDetails, editUser, validateLogin, usernameValidation, zipValidation, phoneNumberValidation } from "../../../Helpers/Helper";
import Styles from "../Users.module.css";
import PageLoader from "../../../Components/Molecules/PageLoader/PageLoader";
import FormTitle from "../../../Components/Molecules/FormTitle/FormTitle";
import DropDown from "../../../Components/Atoms/InputFields/DropDown";
import { toast } from "react-toastify";
import Username from "../../../Components/Atoms/InputFields/Username";
import Email from "../../../Components/Atoms/InputFields/Email";
import Zip from "../../../Components/Atoms/InputFields/Zip";
import { PermissionModule } from "../../../Components/Organisms/PermissionModule/PermissionModule";
let payload = null;

// Add new component component
const EditUser = (props) => {
  const showLoader = (val) => {
    onShowLoaderState(val);
  };
  const userData = JSON.parse(localStorage.getItem("UserData"));
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [editEmail, setEditEmail] = useState("");
  const [dragAction, setDragAction] = useState(false);
  const [imageAction, setImageAction] = useState(false);
  const [editFile, setEditFile] = useState("");
  const [editImage, setEditImage] = useState("");
  const [showLoaderState, onShowLoaderState] = useState(false);
  const [required, setRequired] = useState(true);
  const [emailErr, setEmailErr] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [zip, setZip] = useState("");
  const [gender, setGender] = useState();
  const [genderData, setGenderData] = useState([]);
  const [dropDownData, setDropDownData] = useState("");
  const [yearInSchool, setYearInSchool] = useState([]);
  const [yearData, setYearData] = useState("");
  const [schoolYear, setSchoolYear] = useState();
  const [ethnicity, setEthnicity] = useState([]);
  const [ethnicityData, setEthnicityData] = useState("");
  const [ethni, setEthni] = useState();
  const [isAdmin, setIsAdmin] = useState(null);
  const [rewardsHandler, setRewardsHandler] = useState("");
  const [stampsHandler, setStampsHandler] = useState("");
  const [categoriesHandler, setCategoriesHandler] = useState("");
  const [journeysHandler, setJourneysHandler] = useState("");
  const [milestonesHandler, setMilestonesHandler] = useState("");
  const [experiencesHandler, setExperiencesHandler] = useState("");
  const [questionsHandler, setQuestionsHandler] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [zipErr, setZipErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [imageErr, setImageErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [schoolErr, setSchoolErr] = useState("");
  const [ethnicityErr, setEthnicityErr] = useState("");
  const [address_1Err, setAddress_1Err] = useState("");
  const [address_2Err, setAddress_2Err] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [userRoleErr, setUserRoleErr] = useState("");
  const [userType, setUserType] = useState("");


  const uData = JSON.parse(localStorage.getItem('UserData'))
  useEffect(() => {
    if (uData.is_admin === false) {
      window.location.replace('/admin/home/')
    }
  }, [])

  const handelDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragAction(true);
    } else if (e.type === "dragleave") {
      setDragAction(false);
    }
  };

  // trigger when file is dropped
  // this function reset the dragAction to false
  const handelDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragAction(false);
  };

  // trigger when file is selected with clicked
  const handelChanges = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setEditFile(e.target.files[0]);
      setEditImage(URL.createObjectURL(e.target.files[0]));
      setImageErr("");
    }
    if (!editImage) {
      setImageAction(true);
    } else if (editImage) {
      setImageAction(false);
    }
  };

  const handelFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameErr("");
  };
  const handelLastName = (e) => {
    setLastName(e.target.value);
    setLastNameErr("");
  };

  const handelUsername = (e) => {
    e.preventDefault();
    setUsernameErr(usernameValidation(e.target));
    setUsername(e.target.value);
  };

  const handelEmail = (e) => {
    setEmailErr(validateLogin(e.target));
    setEditEmail(e.target.value);
  };

  const handelAddress1 = (e) => {
    e.preventDefault();
    setAddress1(e.target.value);
    setAddress_1Err("");
  };

  const handelAddress2 = (e) => {
    e.preventDefault();
    setAddress2(e.target.value);
    setAddress_2Err("");
  };

  const handelZip = (e) => {
    e.preventDefault();
    setZipErr(zipValidation(e.target));
    setZip(e.target.value);
  };

  const handelPhoneNumber = (e) => {
    e.preventDefault();
    setPhoneErr(phoneNumberValidation(e.target));
    setPhoneNumber(e.target.value);
  };

  const onGenderChange = (e) => {
    setGender(e);
    setGenderErr("");
  };

  const onYearChange = (e) => {
    setSchoolYear(e);
    setSchoolErr("");
  };

  const onEthniChange = (e) => {
    setEthni(e);
    setEthnicityErr("");
  };

  const roleHandler = (e) => {
    setIsAdmin(parseInt(e));
    setUserRoleErr("");
  };

  const stampHandler = (e) => {
    setStampsHandler(e.target.value);
  };

  const rewardHandler = (e) => {
    setRewardsHandler(e.target.value);
  };

  const categoryHandler = (e) => {
    setCategoriesHandler(e.target.value);
  };
  const experienceHandler = (e) => {
    setExperiencesHandler(e.target.value);
  };

  const journeyHandler = (e) => {
    setJourneysHandler(e.target.value);
  };

  const questionHandler = (e) => {
    setQuestionsHandler(e.target.value);
  };

  const milestoneHandler = (e) => {
    setMilestonesHandler(e.target.value);
  };

  const saveImages = () => {
    const headers = {
      Token: window.localStorage.getItem("authToken"),
      "Content-Type": "text/plain",
    };
    const user = userType.map(results => results.id);

    const selectedUser = user.filter(id => id === isAdmin)
    if (editFile) {
      const formData = new FormData();
      formData.append("image", editFile);
      ApiService.post("api/v1/image/", formData, headers, showLoader, (responseData, responseError) => {
        try {
          payload = {
            first_name: firstName,
            last_name: lastName,
            email: editEmail,
            phone_number: phoneNumber,
            username,
            address_1: address1,
            address_2: address2,
            zip_code: zip,
            gender,
            year_in_school: schoolYear,
            ethnicity: ethni,
            profile_photo: responseData.id,
            // is_admin: isAdmin
            groups: [isAdmin],
            is_admin: selectedUser[0] === 4 || selectedUser[0] === "4"  ? true : false,

            user_permissions: {
              stamp: {
                access_level: stampsHandler,
              },
              journey: {
                access_level: journeysHandler,
              },
              reward: {
                access_level: rewardsHandler,
              },
              category: {
                access_level: categoriesHandler,
              },
              question: {
                access_level: questionsHandler,
              },
              experience: {
                access_level: experiencesHandler,
              },
              milestone: {
                access_level: milestonesHandler,
              }
            },
          };
          ApiService.patch(editUser(), payload, headers, showLoader, (responseData, responseError) => {
            if (!responseError) {
              navigate("/admin/user");
              setFirstName("");
              setLastName("");
              setEditEmail("");
              setEditImage();
              setImageAction(false);
              setEditFile("");
              setZip("");
              setUsername("");

              toast.success("User details successfully updated", {
                autoClose: 4000,
              });
            }
            if (responseError.first_name && responseError.first_name.length !== 0) {
              setFirstNameErr(responseError.first_name[0]);
            }
            if (responseError.last_name && responseError.last_name.length !== 0) {
              setLastNameErr(responseError.last_name[0]);
            }
            if (responseError.email && responseError.email.length !== 0) {
              setEmailErr(responseError.email[0]);
            }
            if (responseError.username && responseError.username.length !== 0) {
              setUsernameErr(responseError.username[0]);
            }
            if (responseError.address_1 && responseError.address_1.length !== 0) {
              setAddress_1Err(responseError.address_1[0]);
            }
            if (responseError.address_2 && responseError.address_2.length !== 0) {
              setAddress_2Err(responseError.address_2[0]);
            }
            if (responseError.zip_code && responseError.zip_code.length !== 0) {
              setZipErr(responseError.zip_code[0]);
            }
            if (responseError.phone_number && responseError.phone_number.length !== 0) {
              setPhoneErr(responseError.phone_number[0]);
            }

            if (responseError.gender && responseError.gender.length !== 0) {
              setGenderErr(responseError.gender[0]);
            }

            if (responseError.year_in_school && responseError.year_in_school.length !== 0) {
              setSchoolErr(responseError.year_in_school[0]);
            }

            if (responseError.ethnicity && responseError.ethnicity.length !== 0) {
              setEthnicityErr(responseError.ethnicity[0]);
            }

            if (responseError.is_admin && responseError.is_admin.length !== 0) {
              setUserRoleErr(responseError.is_admin[0]);
            }
            if (responseError.groups && responseError.groups.length !== 0) {
              toast.error(responseError.groups[0]);
            }

            if (responseData.id === userData.id) {
              localStorage.setItem("UserData", JSON.stringify(responseData));
            }
          });
        } catch (e) { }
        if (responseError.image.length !== 0) {
          setImageErr(responseError.image[0]);
        }
      });
    } else {
      payload = {
        first_name: firstName,
        last_name: lastName,
        email: editEmail,
        phone_number: phoneNumber,
        username,
        address_1: address1,
        address_2: address2,
        zip_code: zip,
        gender,
        year_in_school: schoolYear,
        ethnicity: ethni,
        groups: [isAdmin],
        is_admin: selectedUser[0] === 4 || selectedUser[0] === "4"  ? true : false,
        // is_admin: isAdmin
        user_permissions: {
          stamp: {
            access_level: stampsHandler,
          },
          journey: {
            access_level: journeysHandler,
          },
          reward: {
            access_level: rewardsHandler,
          },
          category: {
            access_level: categoriesHandler,
          },
          question: {
            access_level: questionsHandler,
          },
          experience: {
            access_level: experiencesHandler,
          },
          milestone: {
            access_level: milestonesHandler,
          }
        },
      };
      ApiService.patch(editUser(), payload, headers, showLoader, (responseData, responseError) => {
        try {
          if (!responseError) {
            navigate("/admin/user");
            setFirstName("");
            setLastName("");
            setEditEmail("");
            setEditImage();
            setImageAction(false);
            setEditFile("");
            setZip("");
            setUsername("");

            toast.success("User details successfully updated.", {
              autoClose: 4000,
            });
          }
          if (responseError.first_name && responseError.first_name.length !== 0) {
            setFirstNameErr(responseError.first_name[0]);
          }
          if (responseError.last_name && responseError.last_name.length !== 0) {
            setLastNameErr(responseError.last_name[0]);
          }
          if (responseError.email && responseError.email.length !== 0) {
            setEmailErr(responseError.email[0]);
          }
          if (responseError.username && responseError.username.length !== 0) {
            setUsernameErr(responseError.username[0]);
          }
          if (responseError.address_1 && responseError.address_1.length !== 0) {
            setAddress_1Err(responseError.address_1[0]);
          }
          if (responseError.address_2 && responseError.address_2.length !== 0) {
            setAddress_2Err(responseError.address_2[0]);
          }
          if (responseError.zip_code && responseError.zip_code.length !== 0) {
            setZipErr(responseError.zip_code[0]);
          }
          if (responseError.phone_number && responseError.phone_number.length !== 0) {
            setPhoneErr(responseError.phone_number[0]);
          }
          if (responseError.gender && responseError.gender.length !== 0) {
            setGenderErr(responseError.gender[0]);
          }

          if (responseError.year_in_school && responseError.year_in_school.length !== 0) {
            setSchoolErr(responseError.year_in_school[0]);
          }

          if (responseError.is_admin && responseError.is_admin.length !== 0) {
            setUserRoleErr(responseError.is_admin[0]);
          }

          if (responseError.ethnicity && responseError.ethnicity.length !== 0) {
            setEthnicityErr(responseError.ethnicity[0]);
          }
          if (responseError.groups && responseError.groups.length !== 0) {
            toast.error(responseError.groups[0]);
          }
          if (responseData.id === userData.id) {
            localStorage.setItem("UserData", JSON.stringify(responseData));
          }
        } catch (e) { }
      });
    }
  };

  // handel on submit event
  const handelSubmit = (e) => {
    e.preventDefault();
    saveImages();
  };

  // handel cancel event
  const handelCancel = (e) => {
    navigate("/admin/user");
    e.preventDefault();
    setFirstName("");
    setLastName("");
    setEditImage();
    setImageAction(false);
  };

  // remove image
  const removeImage = (e) => {
    e.preventDefault();
    setEditImage();
    setEditFile("");
    setImageAction(false);
  };
  // if Editing
  const isEdit = false;

  // edit form get call for data
  const headers = {
    Token: window.localStorage.getItem("authToken"),
    "Content-Type": "text/plain",
  };

  // user get call
  useEffect(() => {
    ApiService.get(editUserDetails(), payload, headers, showLoader, (responseData, responseError) => {
      responseData.groups.length !== 0 && setIsAdmin(responseData.groups[0].id);
      responseData.first_name !== null && setFirstName(responseData.first_name);
      responseData.last_name !== null && setLastName(responseData.last_name);
      responseData.email !== null && setEditEmail(responseData.email);
      responseData.username !== null && setUsername(responseData.username);
      responseData.phone_number !== null && setPhoneNumber(responseData.phone_number);
      responseData.address.address_1 !== null && setAddress1(responseData.address.address_1);
      responseData.address.address_2 !== null && setAddress2(responseData.address.address_2);
      responseData.zip_code !== null && setZip(responseData.zip_code);
      responseData.gender !== null && setDropDownData(responseData.gender.id);
      responseData.year_in_school !== null && setYearData(responseData.year_in_school.id);
      responseData.ethnicity !== null && setEthnicityData(responseData.ethnicity.id);
      responseData.user_permission?.permissions.stamp.access_level && setStampsHandler(responseData.user_permission?.permissions.stamp.access_level);
      responseData.user_permission?.permissions.reward.access_level && setRewardsHandler(responseData.user_permission?.permissions.reward.access_level);
      responseData.user_permission?.permissions.journey.access_level && setJourneysHandler(responseData.user_permission?.permissions.journey.access_level);
      responseData.user_permission?.permissions.category.access_level && setCategoriesHandler(responseData.user_permission?.permissions.category.access_level);
      responseData.user_permission?.permissions.question.access_level && setQuestionsHandler(responseData.user_permission?.permissions.question.access_level);
      responseData.user_permission?.permissions.experience.access_level && setExperiencesHandler(responseData.user_permission?.permissions.experience.access_level);
      setEditImage(responseData.profile_photo === null ? "" : responseData.profile_photo.thumbnail);
      responseData.user_permission?.permissions.reward.access_level && setRewardsHandler(responseData.user_permission?.permissions.reward.access_level);
      responseData.user_permission?.permissions.milestone.access_level && setMilestonesHandler(responseData.user_permission?.permissions.milestone.access_level);
    });
  }, []);
  // for genderList get call
  useEffect(() => {
    ApiService.get("api/v1/users-common-dropdown-list/", payload, headers, showLoader, (responseData, errorData) => {
      setGenderData(responseData.gender);
      setYearInSchool(responseData.year_in_school);
      setEthnicity(responseData.ethnicity);
    });
  }, []);

  //user Role get
  useEffect(() => {
    ApiService.get("api/v1/group/", payload, headers, showLoader, (responseData, errorData) => {
      setUserType(responseData.results);
    });
  }, []);

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle
        title={[
          { title: "Users", path: "/admin/user" },
          { title: "Edit User", path: "/admin/user/:id/edit", disable: "true" },
        ]}
      />
      <div className={Styles.Container}>
        <Container className="formContainer">
          <Form className="m-2">
            <DropDown
              name="User Role"
              // dropDefault="Select User Role"
              dropDefault={isAdmin}
              handler={roleHandler}
              data={userType}
              type={"userRole"}
              showDefaultOption={false}
              required={required}
              ErrorUser={userRoleErr}
            />
            <Title name="First Name" onTitleChange={handelFirstName} title={firstName} required={required} Error={firstNameErr} />
            <Title name="Last Name" onTitleChange={handelLastName} title={lastName} required={required} Error={lastNameErr} />
            <Username name="Username" onTitleChange={handelUsername} title={username} required={required} Error={usernameErr} />
            <Email name="Email" onTitleChange={handelEmail} title={editEmail} required={required} Error={emailErr} />
            <Title name="Address1" onTitleChange={handelAddress1} title={address1} Error={address_1Err} />
            <Title name="Address2" onTitleChange={handelAddress2} title={address2} Error={address_2Err} />
            <Zip name="ZIP" onTitleChange={handelZip} title={zip} required={required} Error={zipErr} type="string" />
            <Zip name="Phone Number" onTitleChange={handelPhoneNumber} title={phoneNumber} required={required} Error={phoneErr} type="Number" />
            <DropDown name="Gender" dropDefault={dropDownData} type={"EditUser"} data={genderData} handler={onGenderChange} showDefaultOption={false} required={required} ErrorGender={genderErr} />
            <DropDown
              name="Year In School"
              dropDefault={yearData}
              type={"yearInSchool"}
              data={yearInSchool}
              handler={onYearChange}
              showDefaultOption={false}
              required={required}
              ErrorSchool={schoolErr}
            />
            <DropDown
              name="Ethnicity"
              dropDefault={ethnicityData}
              type={"yearInSchool"}
              data={ethnicity}
              handler={onEthniChange}
              showDefaultOption={false}
              required={required}
              ErrorSchool={ethnicityErr}
            />
            <DragDrop
              onDrag={handelDrag}
              onDrop={handelDrop}
              onChange={handelChanges}
              image={editImage}
              dragAction={dragAction}
              removeImage={removeImage}
              imageAction={imageAction}
              file={editFile}
              name="Image"
              isEdit={isEdit}
              required={required}
              Error={imageErr}
            />
            <div>{!editImage ? null : <img src={editImage} className={Styles.imgContainer} alt=""></img>}</div>
            {isAdmin === "2" || isAdmin === "3" || isAdmin === 2 || isAdmin === 3 ? (
              <PermissionModule
                userRole={isAdmin}
                rewardHandler={rewardHandler}
                stampHandler={stampHandler}
                categoryHandler={categoryHandler}
                journeyHandler={journeyHandler}
                experienceHandler={experienceHandler}
                questionHandler={questionHandler}
                milestoneHandler={milestoneHandler}
                defaultReward={rewardsHandler !== undefined ? rewardsHandler : "partial"}
                defaultStamp={stampsHandler !== undefined ? stampsHandler : "partial"}
                defaultCategory={categoriesHandler !== undefined ? categoriesHandler : "partial"}
                defaultQuestion={questionsHandler !== undefined ? questionsHandler : "partial"}
                defaultExperience={experiencesHandler !== undefined ? experiencesHandler : "partial"}
                defaultJourney={journeysHandler !== undefined ? journeysHandler : "partial"}
                defaultMilestone={isAdmin === 2 || isAdmin === "2" ? milestonesHandler !== undefined ? milestonesHandler : "partial" : null}
              />
            ) : null}
            <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <CancelBtn handelCancel={handelCancel} />
                <SaveBtn handelSubmit={handelSubmit} />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default EditUser;
