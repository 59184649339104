import React from 'react'
import NewCoupon from '../../../Components/Organisms/AddNewRewards/NewRewards'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Reward.module.css'
const AddCoupon = () => {
  return (
    <>
      <FormTitle title={[{ title: "Rewards", path: "/admin/coupon" }, { title: "Add New Reward", path: "/admin/coupon/create", disable: "true" }]} />
      <div className={Style.Container}>
        <NewCoupon />
      </div>
    </>
  )
}

export default AddCoupon
