import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Feeds from '../Pages/Feeds/Feeds'
import AddFeed from '../Pages/Feeds/Add-Feeds/AddFeed'
import EditFeed from '../Pages/Feeds/Edit/EditFeed'
import { NotFound } from '../Pages/NotFound/NotFound'
const FeedsRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Feeds />}></Route>
        <Route path="create" element={<AddFeed />}></Route>
        <Route path=":id/edit" element={<EditFeed />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default FeedsRoute
