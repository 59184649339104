import { Button } from 'react-bootstrap'
import style from './Button.module.css'

const SaveBtn = ({ handelSubmit, disableAccess}) => {
  return (
    <>
      <Button variant='primary' type='submit' className={style.saveBtn} onClick={handelSubmit}  disabled={disableAccess}>
        Save Changes
      </Button>
    </>
  )
}
export default SaveBtn
