const hostname = window && window.location && window.location.hostname
const backendHost = {
  getAPIUrl: () => {
    if (hostname === 'as-ppa-dev-fe-admin.azurewebsites.net') {
      return 'https://as-ppa-dev-be-api.azurewebsites.net/'
    } else if (hostname === 'as-ppa-qa-fe-admin.azurewebsites.net') {
      return 'https://as-ppa-qa-be-api.azurewebsites.net/'
    } else if (hostname === 'as-ppa-uat-fe-admin.azurewebsites.net') {
      return 'https://as-ppa-uat-be-api.azurewebsites.net/'
    } else if (hostname === 'admin.passportatlanta.com') {
      return 'https://api.passportatlanta.com/'
    } else {
      return 'https://as-ppa-dev-be-api.azurewebsites.net/'
    }
  }
}
const googleMapApiKey = 'AIzaSyBtFRHTQGu5LfnOCfioBnYOUp4sZHPLhZk' 
export { backendHost, googleMapApiKey }
