import React, { useState, useEffect } from "react";
import { Col, FormGroup, Row, Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Styles from "./Login.module.css";
import Logo from "../../Assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { passwordValidation, validateLogin } from "../../Helpers/Helper";
import PageLoader from "../../Components/Molecules/PageLoader/PageLoader";
import axios from "axios";
import { backendHost as API_URL } from "../../config";
import { toast } from "react-toastify";

const Login = (props) => {
  // Defined States and variables
  const [passwordErr, setPasswordErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [showLoader, onShowLodaer] = useState(false);
  const navigate = useNavigate();
  const header = {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };
  // following useEffect is to check if the user is authenticated to access the page content when page is getting mounted.
  useEffect(() => {
    const isAuth = localStorage.getItem("authToken");
    isAuth && isAuth !== "" ? navigate("/admin/home") : navigate("/");
  }, []);

  // following function is called when the user clicks the login button it also contains the validation from front-end side if any field is left empty.
  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.email.value === "") setEmailErr("Email is Required");

    if (e.target.password.value === "") {
      setPasswordErr("Password is required");
    } else if (emailErr === "" && passwordErr === "") {
      onShowLodaer(true);
      const data = {
        // trims the whitespace in front and back of the string
        email: e.target.email.value.trim(),
        password: e.target.password.value.trim(),
      };

      axios
        .post(API_URL.getAPIUrl() + "api/v1/admin-login/", data, header)
        .then((responseData, responseError) => {
          toast.success("Login successfully", {
            autoClose: 4000,
          });
          props.getData(responseData.data);
          onShowLodaer(false);
        })

        .catch((error) => {
          if (
            error.response.data.email &&
            error.response.data.email.length !== 0
          ) {
            toast.error(error.response.data.email[0], {
              autoClose: 4000,
            });
            onShowLodaer(false);
          }
          if (error.response.status === 400) {
            if (
              error.response.data.password &&
              error.response.data.password.length !== 0
            ) {
              setPasswordErr(error.response.data.password[0]);
              onShowLodaer(false);
            }
            if (
              error.response.data.email &&
              error.response.data.email.length !== 0
            ) {
              setEmailErr(error.response.data.email[0]);
              onShowLodaer(false);
            }
          }
          if (
            error.response.data.detail &&
            error.response.data.detail.length !== 0
          ) {
            toast.error(error.response.data.detail[0], {
              autoClose: 4000,
            });
            if (error.response.status === 401) {
              for (error in error.response) {
                setPasswordErr(error.response[error][0]);
              }
              window.location.replace("/");
              return;
            }
            if (error.response.data.detail === "Not found.") {
              alert(error.response.data.detail);
              toast.error("Request not found.", {
                autoClose: 4000,
              });
            }
            onShowLodaer(false);
          }
        });
    }
  };
  // following function is used for updating the email state whenever a change happens in input field
  const onEmailChangeHandler = (e) => {
    setEmailErr(validateLogin(e.target));
  };
  //  Following function is for updating the password state when any kind of change happens in the input field
  const passwordChangeHandler = (e) => {
    setPasswordErr(passwordValidation(e.target));
  };
  // Following return function is for returning the jsx which will be rendered in the browser
  return (
    <>
      {showLoader && <PageLoader show={showLoader} />}
      <section className={Styles.loginPageContainer}>
        {/* Login Container */}

        <Row className={` ${Styles.loginContainer} flex-lg-row`}>
          {/* Right Info( Logo and Information Panel) */}

          <Col className={Styles.rightPanel}>
            <div className={Styles.brandLogoContainer}>
              <img
                className={Styles.brandLogo}
                src={Logo}
                alt='Passport-Atlanta-Logo'></img>
            </div>
          </Col>

          {/* Form Panel */}

          <Col className={Styles.leftPanel}>
            <Form onSubmit={handleSubmit}>
              {" "}
              <FormGroup>
                <FloatingLabel
                  className={`mb-3 ${Styles.loginFloatingLabel}`}
                  controlId='floatingInput'
                  label='E-Mail Address'>
                  <Form.Control
                    className={Styles.formCredentialsInput}
                    type='email'
                    placeholder='Email'
                    name='email'
                    onChange={(e) => {
                      onEmailChangeHandler(e);
                    }}
                  />
                  {!emailErr ? null : (
                    <span id='email-error' className={Styles.Error}>
                      {emailErr}
                    </span>
                  )}
                </FloatingLabel>
              </FormGroup>
              <FormGroup>
                <FloatingLabel
                  className={Styles.loginFloatingLabel}
                  controlId='floatingPassword'
                  label='Password'>
                  <Form.Control
                    className={Styles.formCredentialsInput}
                    type='password'
                    placeholder='Password'
                    name='password'
                    onChange={(e) => {
                      passwordChangeHandler(e);
                    }}
                  />
                  {!passwordErr ? null : (
                    <div
                      className={[Styles.Error, Styles.loginError].join(" ")}>
                      {passwordErr}
                    </div>
                  )}
                </FloatingLabel>{" "}
              </FormGroup>
              <Button
                variant='danger outline-none'
                className={Styles.loginButton}
                type='submit'>
                Login
              </Button>{" "}
            </Form>
            <Link className={Styles.passwordResetLink} to='/passwords/reset'>
              Forgot Your Password ?
            </Link>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Login;
