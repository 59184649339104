import React, { useState } from 'react'
import Header from '../../Organisms/Header/Header'
import Footer from '../../Organisms/Footer/Footer'
import SideNav from '../../Organisms/SideNav/SideNav'
import PageDisplay from '../../Organisms/PageDisplay/PageDisplay'
import Styles from './HomeContainer.module.css'

const HomeContainer = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const toggle = () => {
    setIsActive(!isActive)
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Header toggle={toggle} isActive={isActive} logOut={props.logOut} />
      <section className={Styles.HomeContainer}>
        <SideNav isOpen={isOpen} />
        <PageDisplay isOpen={isOpen} />
        <Footer />
      </section>
    </>
  )
}

export default HomeContainer
