import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Milestones from '../Pages/Milestones/Milestones'
import AddMilestone from '../Pages/Milestones/Add-Milestone/AddMilestone'
import EditMilestone from '../Pages/Milestones/Edit/EditMilestone'
import { NotFound } from '../Pages/NotFound/NotFound'
const MilestonesRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Milestones />}></Route>
        <Route path="create" element={<AddMilestone />}></Route>
        <Route path=":id/edit" element={<EditMilestone />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default MilestonesRoute
