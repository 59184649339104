import style from './JourneysBtn.module.css'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Milestones = ({ isOpen }) => {

    const [headerInfo, setHeaderInfo] = useState('')
    useEffect(() => {
        setHeaderInfo(localStorage.getItem('header'))
    })
    return (
        <>
            <div className={(style.linkHover, headerInfo === 'Milestones' ? style.selected : null)}>
                <Link
                    to={'milestone/'}
                    onClick={() => {
                        localStorage.setItem('header', 'Milestones')
                    }}
                    className={isOpen ? style.linkActive : style.link}>
                    <div className={isOpen ? style.iconActive : style.icon}>{<i className="fa fa-id-card"></i>}</div>
                    <div className={isOpen ? style.linkTextActive : style.linkText}>Milestones</div>
                </Link>
            </div>
        </>
    )
}
export default Milestones
