import React, {useEffect} from 'react'
import NewMilestone from '../../../Components/Organisms/AddNewMilestones/NewMilestone'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Milestones.module.css'
const AddMilestone = () => {
  let uData = JSON.parse(localStorage.getItem('UserData'))
  useEffect(() => {
    if (uData.is_admin === false && uData.groups && uData.groups.length !== 0 && uData.groups[0].name ==='Destination Partner') {
      window.location.replace('/admin/home/')
    }
  }, [])
  return (
    <>
      <FormTitle title={[{ title: "Milestones", path: "/admin/milestone/" }, { title: "Add New Milestone", path: "/admin/milestone/create", disable: "true" }]} />
      <div className={Style.Container}>
        <NewMilestone />
      </div>
    </>
  )
}

export default AddMilestone
