import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './FormTitle.module.css'
import FilterDropdown from '../FilterDropdown/FilterDropdown'
const FormTitle = ({ title, filter, handleFilter,showFilter }) => {
  const header = localStorage.getItem('header')
  let uData = JSON.parse(localStorage.getItem('UserData'))
  return (
    <div className={styles.headerContainer}>
      <div><Link className={title[0] === "" ? styles.subHeaderTextDisable : styles.subHeaderText} onClick={() => { localStorage.setItem('header', 'Home') }} to={'/admin/home'}>Home</Link>
        {title[0] !== "" ? title.map((value, index) => {
          return (<Link className={value.disable ? styles.subHeaderTextDisable : styles.subHeaderText} key={index} to={value.path}>&nbsp;{'>'}&nbsp;{value.title}</Link>)
        }) : null}
      </div>
      {header === "Home" ? (uData.is_admin === true || uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin' ? (<div><FilterDropdown handler={handleFilter} defaultFilter={filter} header={"Home"} /></div>) : null) : null}
      {/* {header === "Journeys" ? (uData.is_admin ===true || uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin'?(<div><FilterDropdown handler={handleFilter} header={ "Journeys"}/></div>):null) : null} */}
      {header === "Journeys" && showFilter && showFilter===true? (<div><FilterDropdown handler={handleFilter} defaultFilter={filter} header={"Journeys"} /></div>) : null}
    </div>
  )
}

export default FormTitle
