import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import style from "./PermissionModule.module.css";

export const PermissionModule = ({
  userRole,
  rewardHandler,
  stampHandler,
  categoryHandler,
  journeyHandler,
  experienceHandler,
  questionHandler,
  milestoneHandler,
  defaultReward,
  defaultStamp,
  defaultCategory,
  defaultExperience,
  defaultQuestion,
  defaultJourney,
  defaultMilestone
}) => {
  return (
    <>
      <Form.Group>
        <Row className={style.radioContainer}>
          <Col className={style.radioLabel}>Rewards</Col>
          <Col className={style.radioOptionContainer} sm="10">
            <input name="rewards" value="partial" className={style.radioInput} type="radio" defaultChecked={defaultReward === "partial" ? true : false} onChange={rewardHandler}></input>
            <label htmlFor="rewards" className={style.radioInputLabel}>
              Partial Access
            </label>
            <input name="rewards" value="full" className={style.radioInput} type="radio" defaultChecked={defaultReward === "full" ? true : false} onChange={rewardHandler}></input>
            <label htmlFor="rewards" className={style.radioInputLabel}>
              Full Access
            </label>
          </Col>
        </Row>
        <hr></hr>
        <Row className={style.radioContainer}>
          <Col className={style.radioLabel}>Stamps</Col>
          <Col className={style.radioOptionContainer} sm="10">
            <input name="stamps" value="partial" className={style.radioInput} type="radio" defaultChecked={defaultStamp === "partial" ? true : false} onChange={stampHandler}></input>
            <label htmlFor="stamps" className={style.radioInputLabel}>
              Partial Access
            </label>
            <input name="stamps" value="full" className={style.radioInput} type="radio" defaultChecked={defaultStamp === "full" ? true : false} onChange={stampHandler}></input>
            <label htmlFor="stamps" className={style.radioInputLabel}>
              Full Access
            </label>
          </Col>
        </Row>
        <hr></hr>
        <Row className={style.radioContainer}>
          <Col className={style.radioLabel}>Category</Col>
          <Col className={style.radioOptionContainer} sm="10">
            <input name="category" value="partial" className={style.radioInput} type="radio" defaultChecked={defaultCategory === "partial" ? true : false} onChange={categoryHandler}></input>
            <label htmlFor="category" className={style.radioInputLabel}>
              Partial Access
            </label>
            <input name="category" value="full" className={style.radioInput} type="radio" defaultChecked={defaultCategory === "full" ? true : false} onChange={categoryHandler}></input>
            <label htmlFor="category" className={style.radioInputLabel}>
              Full Access
            </label>
          </Col>
        </Row>
        <hr></hr>
        <Row className={style.radioContainer}>
          <Col className={style.radioLabel}>Journey</Col>
          <Col className={style.radioOptionContainer} sm="10">
            <input
              disabled={userRole === 3 || userRole === "3"? true : false}
              name="journey"
              value="partial"
              className={style.radioInput}
              type="radio"
              defaultChecked={defaultJourney === "partial" ? true : false}
              onChange={journeyHandler}></input>
            <label htmlFor="journey" className={style.radioInputLabel}>
              Partial Access
            </label>
            <input
              disabled={userRole === 3 || userRole === "3"? true : false}
              name="journey"
              value="full"
              className={style.radioInput}
              type="radio"
              defaultChecked={defaultJourney === "full" ? true : false}
              onChange={journeyHandler}></input>
            <label htmlFor="journey" className={style.radioInputLabel}>
              Full Access
            </label>
          </Col>
        </Row>
        <hr></hr>
        <Row className={style.radioContainer}>
          <Col className={style.radioLabel}>Experience</Col>
          <Col className={style.radioOptionContainer} sm="10">
            <input
              disabled={userRole === 3 || userRole === "3"? true : false}
              name="experience"
              value="partial"
              className={style.radioInput}
              type="radio"
              defaultChecked={defaultExperience === "partial" ? true : false}
              onChange={experienceHandler}></input>
            <label htmlFor="experience" className={style.radioInputLabel}>
              Partial Access
            </label>
            <input
              disabled={userRole === 3 || userRole === "3"? true : false}
              name="experience"
              value="full"
              className={style.radioInput}
              type="radio"
              defaultChecked={defaultExperience === "full" ? true : false}
              onChange={experienceHandler}></input>
            <label htmlFor="experience" className={style.radioInputLabel}>
              Full Access
            </label>
          </Col>
        </Row>
        <hr></hr>
        <Row className={style.radioContainer}>
          <Col className={style.radioLabel}>Questions</Col>
          <Col className={style.radioOptionContainer} sm="10">
            <input
              disabled={userRole === 3 || userRole === "3" ? true : false}
              name="question"
              value="partial"
              className={style.radioInput}
              type="radio"
              defaultChecked={defaultQuestion === "partial" ? true : false}
              onChange={questionHandler}></input>
            <label htmlFor="question" className={style.radioInputLabel}>
              Partial Access
            </label>
            <input
              disabled={userRole === 3 || userRole === "3" ? true : false}
              name="question"
              value="full"
              className={style.radioInput}
              type="radio"
              defaultChecked={defaultQuestion === "full" ? true : false}
              onChange={questionHandler}></input>
            <label htmlFor="question" className={style.radioInputLabel}>
              Full Access
            </label>
          </Col>
        </Row>
        <hr></hr>
        {userRole===2||userRole==='2'?(<><Row className={style.radioContainer}>
          <Col className={style.radioLabel}>Milestones</Col>
          <Col className={style.radioOptionContainer} sm="10">
            <input
              disabled={userRole === 3 || userRole === "3" ? true : false}
              name="milestone"
              value="partial"
              className={style.radioInput}
              type="radio"
              defaultChecked={defaultMilestone === "partial" ? true : false}
              onChange={milestoneHandler}></input>
            <label htmlFor="milestone" className={style.radioInputLabel}>
              Partial Access
            </label>
            <input
              disabled={userRole === 3 || userRole === "3" ? true : false}
              name="milestone"
              value="full"
              className={style.radioInput}
              type="radio"
              defaultChecked={defaultMilestone === "full" ? true : false}
              onChange={milestoneHandler}></input>
            <label htmlFor="milestone" className={style.radioInputLabel}>
              Full Access
            </label>
          </Col>
        </Row>
        <hr></hr></>):null}
        
      </Form.Group>
    </>
  );
};
