import React, { useState, useEffect } from "react";
import { Col, FormGroup, Row, Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Styles from "../Login/Login.module.css";
import Logo from "../../Assets/logo.png";
import { useNavigate } from "react-router-dom";
import {
  newPasswordValidation,
  confirmPasswordValidation,
} from "../../Helpers/Helper";
import PageLoader from "../../Components/Molecules/PageLoader/PageLoader";
import axios from "axios";
import { backendHost as API_URL } from "../../config";
import { toast } from "react-toastify";

const ForgotPassword = (props) => {
  // Defined States and variables
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [showLoader, onShowLodaer] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const url = window.location.href.split("/");

  // following function is called when the user clicks the login button it also contains the validation from front-end side if any field is left empty.
  const handleSubmit = (e, callback) => {
    e.preventDefault();
    if (e.target.newPassword.value === "") {
      setNewPasswordErr("This field may not be blank.");
    }
    if (e.target.confirmPassword.value === "") {
      setPasswordErr("This field may not be blank.");
    }
    if (e.target.newPassword.value !== e.target.confirmPassword.value) {
      setPasswordErr("The two password fields didn't match.");
    }

    if (!passwordErr && !newPasswordErr) {
      onShowLodaer(true);
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      };
      const data = {
        new_password1: password,
        new_password2: confirmPassword,
        uid: url[5],
        token: url[6],
      };
      axios
        .post(
          API_URL.getAPIUrl() + "api/v1/rest-auth/password/reset/confirm/",
          data,
          header
        )
        .then((responseData, responseError) => {
          if (!responseError) {
            toast.success("Password changed successfully !", {
              autoClose: 4000,
            });
          }
          setTimeout(() => {
            window.location.replace("/");
          }, 5000);
        })
        .catch((error) => {
          if (
            error.response.data.detail &&
            error.response.data.detail.length !== 0
          ) {
            toast.error(error.response.data.detail[0]);

            if (error.response.status === 401) {
              window.location.replace("/");
              toast.error(
                "Unauthorized, enter valid authentication credentials.",
                {
                  autoClose: 4000,
                }
              );
            }

            if (error.response.data.detail === "Not found.") {
              alert(error.response.data.detail);
              toast.error("Request not found.", {
                autoClose: 4000,
              });
            }
          }
          if (error.response.status === 400) {
            if (
              error.response.data.token &&
              error.response.data.token.length !== 0
            ) {
              toast.error("Session Expired, please try again", {
                autoClose: 4000,
              });
              setTimeout(() => {
                window.location.replace("/");
              }, 5000);
            }
            if (
              error.response.data.uid &&
              error.response.data.uid.length !== 0
            ) {
              toast.error("Invalid User, please try again", {
                autoClose: 4000,
              });
              setTimeout(() => {
                window.location.replace("/");
              }, 5000);
            }
            if (
              error.response.data.new_password1 &&
              error.response.data.new_password1.length !== 0
            ) {
              setNewPasswordErr(error.response.data.new_password1[0]);
            }

            if (
              error.response.data.new_password2 &&
              error.response.data.new_password2.length !== 0
            ) {
              setPasswordErr(error.response.data.new_password2[0]);
            }
            onShowLodaer(false);
          }
          if (error.response) {
            if (error.response.status === 403) {
              setTimeout(() => {
                localStorage.clear();
                window.location.replace("/");
              }, 2000);
              toast.error("User token expired, log in again");
              return;
            }
            if (error.response.status === 500) {
              setTimeout(() => {
                localStorage.clear();
                window.location.replace("/");
              }, 2000);
              toast.error(
                "Something went wrong please log in again or visit later"
              );
              return;
            }
            if (error.response.status === 404) {
              window.location.replace("/Not-Found");
              return;
            }
            callback && callback(null, error.response.data);
          }
          onShowLodaer(false);
        });
    }
  };

  // following function is used for updating the email state whenever a change happens in input field
  const onNewPasswordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
    setNewPasswordErr(newPasswordValidation(e.target));
  };

  //  Following function is for updating the password state when any kind of change happens in the input field
  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmPassword(e.target.value);
    setPasswordErr(confirmPasswordValidation(e.target));
  };

  // Following return function is for returning the jsx which will be rendered in the browser
  return (
    <>
      {showLoader && <PageLoader show={showLoader} />}
      <section className={Styles.loginPageContainer}>
        {/* Login Container */}

        <Row className={` ${Styles.loginContainer} flex-lg-row`}>
          {/* Right Info( Logo and Information Panel) */}

          <Col className={Styles.rightPanel}>
            <div className={Styles.brandLogoContainer}>
              <img
                className={Styles.brandLogo}
                src={Logo}
                alt='Passport-Atlanta-Logo'></img>
            </div>
          </Col>

          {/* Form Panel */}

          <Col className={Styles.leftPanel}>
            <Form onSubmit={handleSubmit}>
              {" "}
              <FormGroup>
                <FloatingLabel
                  className={`mb-3 ${Styles.loginFloatingLabel}`}
                  controlId='floatingInput'
                  label='New Password'>
                  <Form.Control
                    className={Styles.formCredentialsInput}
                    type='password'
                    placeholder='Enter new password'
                    name='newPassword'
                    onChange={(e) => {
                      onNewPasswordHandler(e);
                    }}
                  />
                  {!newPasswordErr ? null : (
                    <span id='email-error' className={Styles.Error}>
                      {newPasswordErr}
                    </span>
                  )}
                </FloatingLabel>
              </FormGroup>
              <FormGroup>
                <FloatingLabel
                  className={Styles.loginFloatingLabel}
                  controlId='floatingPassword'
                  label='Confirm Password'>
                  <Form.Control
                    className={Styles.formCredentialsInput}
                    type='password'
                    placeholder='Confirm password'
                    name='confirmPassword'
                    onChange={(e) => {
                      confirmedPasswordHandler(e);
                    }}
                  />
                  {passwordErr === "" ? null : (
                    <div
                      className={[Styles.Error, Styles.loginError].join(" ")}>
                      {passwordErr}
                    </div>
                  )}
                </FloatingLabel>{" "}
              </FormGroup>
              <Button
                variant='danger outline-none'
                className={Styles.loginButton}
                type='submit'>
                Reset Password
              </Button>{" "}
            </Form>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ForgotPassword;
