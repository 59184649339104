import { useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import style from './Atoms.module.css'
const DropDown = (props) => {
  const [errorActiveTime, setErrorActiveTime] = useState(false)
  const [errorMovement, setErrorMovement] = useState(false)
  const [errorQuestion, setErrorQuestion] = useState(false)
  const [errQtype, setErrQtype] = useState(false)
  const [errCategory, setErrCategory] = useState(false)
  const [errorJourney, setErrorJourney] = useState(false)
  const [errGender, setErrGender] = useState(false)
  const [errSchool, setErrSchool] = useState(false)
  const [errUserRole, setErrUserRole] = useState(false)
  const [errMsgActiveTime, setErrMsgActiveTime] = useState('')
  const [errMsgMovement, setErrMsgMovement] = useState('')
  const [errMsgQuestion, setErrMsgQuestion] = useState('')
  const [errMsgQtype, setErrMsgQtype] = useState('')
  const [errMsgJourney, setErrMsgJourney] = useState()
  const [errMsgCategory, setErrMsgCategory] = useState('')
  const [errMsgGender, setErrMsgGender] = useState('')
  const [errMsgSchool, setErrMsgSchool] = useState('')
  const [errMsgUserRole, setErrMsgUserRole] = useState('')

  useEffect(() => {
    // for active-time dropdown in journey
    if (props.ErrorActive !== '') {
      setErrorActiveTime(true)
      setErrMsgActiveTime(props.ErrorActive)
    } else {
      setErrorActiveTime(false)
    }
    // for movement dropdown in journey
    if (props.ErrorMovement !== '') {
      setErrorMovement(true)
      setErrMsgMovement(props.ErrorMovement)
    } else {
      setErrorMovement(false)
    }
    if (props.ErrorExp !== '') {
      setErrorQuestion(true)
      setErrMsgQuestion(props.ErrorExp)
    } else {
      setErrorQuestion(false)
    }
    if (props.ErrorQType !== '') {
      setErrQtype(true)
      setErrMsgQtype(props.ErrorQType)
    } else {
      setErrQtype(false)
    }
    if (props.ErrorJourney !== '') {
      setErrorJourney(true)
      setErrMsgJourney(props.ErrorJourney)
    } else {
      setErrorJourney(false)
    }
    if (props.ErrorCategory !== '') {
      setErrCategory(true)
      setErrMsgCategory(props.ErrorCategory)
    } else {
      setErrCategory(false)
    }
    if (props.ErrorGender !== '') {
      setErrGender(true)
      setErrMsgGender(props.ErrorGender)
    } else {
      setErrGender(false)
    }
    if (props.ErrorSchool !== '') {
      setErrSchool(true)
      setErrMsgSchool(props.ErrorSchool)
    } else {
      setErrSchool(false)
    }
    if (props.ErrorUser !== '') {
      setErrUserRole(true)
      setErrMsgUserRole(props.ErrorUser)
    } else {
      setErrSchool(false)
    }
  }, [props.ErrorActive, props.ErrorMovement, props.ErrorExp, props.ErrorQType, props.ErrorJourney, props.ErrorGender, props.ErrorSchool, props.ErrorCategory, props.ErrorUser])
  if (props.type === 'category') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  setErrCategory(false)
                  props.handler(parseInt(e.target.value))
                }}>
                {props.showDefaultOption === true && (
                  <option disabled selected>
                    {props.dropDefault}
                  </option>
                )}
                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.defaultValue === value.id
                      ? (
                        <option selected={true} value={value.id}>
                          {value.name}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.id}>
                          {value.name}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errCategory
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgCategory}
                    </span>
                  )}
              </Row>
            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'journeys') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  setErrorJourney(false)
                  props.handler(parseInt(e.target.value))
                }}>
                {
                  props.showDefaultOption === true && (
                    <option disabled selected>
                      {props.dropDefault}
                    </option>
                  )
                  // :
                  // <option disabled>{props.dropDefault}</option>
                }
                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault.id === value.id
                      ? (
                        <option key={value.id} selected={true} value={value.id}>
                          {value.title}
                        </option>
                      )
                      : (
                        <option selected={false} key={value.id} value={value.id}>
                          {value.title}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errorJourney
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgJourney}
                    </span>
                  )}
              </Row>
            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'activeTime') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  props.handler(parseInt(e.target.value))
                  setErrorActiveTime(false)
                }}>
                {
                  props.showDefaultOption === true && (
                    <option disabled selected>
                      {props.dropDefault}
                    </option>
                  )
                  // :
                  // <option disabled>{props.dropDefault}</option>
                }
                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault.id === value.id
                      ? (
                        <option key={index} selected={true} value={value.id}>
                          {value.active_time}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.id}>
                          {value.active_time}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errorActiveTime
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgActiveTime}
                    </span>
                  )}
              </Row>

            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'movement') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  props.handler(parseInt(e.target.value))
                  setErrMsgMovement(false)
                }}>
                {/* <option>{props.dropDefault}</option> */}
                {
                  props.showDefaultOption === true && (
                    <option disabled selected>
                      {props.dropDefault}
                    </option>
                  )
                  // :
                  // <option disabled>{props.dropDefault}</option>
                }
                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault.id === value.id
                      ? (
                        <option key={index} selected={true} value={value.id}>
                          {value.name}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.id}>
                          {value.name}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errorMovement
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgMovement}
                    </span>
                  )}
              </Row>

            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'experience') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  props.handler(parseInt(e.target.value))
                }}>
                {
                  props.showDefaultOption === true && (
                    <option disabled selected>
                      {props.dropDefault}
                    </option>
                  )
                  // :
                  // <option disabled>{props.dropDefault}</option>
                }
                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault === value.id
                      ? (
                        <option key={index} selected={true} value={value.id}>
                          {value.title}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.id}>
                          {value.title}
                        </option>
                      )
                  })}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'question') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  return props.handler(parseInt(JSON.parse(e.target.value).id), props.arHandler(JSON.parse(e.target.value).is_ar), setErrorQuestion(false))
                }}>
                {
                  props.showDefaultOption === true && (
                    <option disabled selected>
                      {props.dropDefault}
                    </option>
                  )
                  // :
                  // <option disabled>{props.dropDefault}</option>
                }
                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault.id === value.id
                      ? (
                        <option key={index} selected={true} value={JSON.stringify(value)}>
                          {value.title}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={JSON.stringify(value)}>
                          {value.title}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errorQuestion
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgQuestion}
                    </span>
                  )}
              </Row>

            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'question-type') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}{' '}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  props.handler(e.target.value)
                  setErrQtype(false)
                }}>
                {
                  props.showDefaultOption === true && (
                    <option disabled selected>
                      {props.dropDefault}
                    </option>
                  )
                  // :
                  // <option disabled>{props.dropDefault}</option>
                }
                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault === value.value
                      ? (
                        <option key={index} selected={true} value={value.value}>
                          {value.title}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.value}>
                          {value.title}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errQtype
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgQtype}
                    </span>
                  )}
              </Row>

            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'EditUser') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  props.handler(parseInt(e.target.value))
                  setErrGender(false)
                }}>
                {/* <option>{props.dropDefault}</option> */}
                {props.showDefaultOption === true && (
                  <option disabled selected>
                    {props.dropDefault.name}
                  </option>
                )}

                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault === value.id
                      ? (
                        <option key={index} selected={true} value={value.id}>
                          {value.name}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.id}>
                          {value.name}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errGender
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgGender}
                    </span>
                  )}
              </Row>

            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'manageProfile') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  props.handler(parseInt(e.target.value))
                  setErrGender(false)
                }}>
                {/* <option>{props.dropDefault}</option> */}
                {props.showDefaultOption === true && (
                  <option disabled selected>
                    {props.dropDefault}
                  </option>
                )}

                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault === value.id
                      ? (
                        <option key={index} selected={true} value={value.id}>
                          {value.name}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.id}>
                          {value.name}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errGender
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgGender}
                    </span>
                  )}
              </Row>

            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'yearInSchool') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  props.handler(parseInt(e.target.value))
                  setErrSchool(false)
                }}>
                {/* <option>{props.dropDefault}</option> */}
                {props.showDefaultOption === true && (
                  <option disabled selected>
                    {props.dropDefault.name}
                  </option>
                )}

                {!props.data
                  ? null
                  : props.data.map((value, index) => {
                    return props.dropDefault === value.id
                      ? (
                        <option key={index} selected={true} value={value.id}>
                          {value.name}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.id}>
                          {value.name}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errSchool
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgSchool}
                    </span>
                  )}
              </Row>

            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  } else if (props.type === 'userRole') {
    return (
      <>
        <Form.Group className={style.titleRow}>
          <Row>
            <Col>
              <Form.Label className={style.titleLabel}>
                {props.name}
                {!props.required
                  ? null
                  : (
                    <span id='email-error' className={style.errorAsterisk}>
                      *
                    </span>
                  )}
              </Form.Label>
            </Col>
            <Col sm='10'>
              <Form.Select
                className={style.titleInput}
                onChange={(e) => {
                  props.handler(e.target.value)
                  setErrUserRole(false)
                }}>
                {props.showDefaultOption === true && (
                  <option disabled selected>
                    {props.dropDefault}
                  </option>
                )}

                {!props.data
                  ? null
                  : props.data.map((value, index, id) => {
                    return props.dropDefault === value.id
                      ? (
                        <option key={index} selected={true} value={value.id}>
                          {value.name}
                        </option>
                      )
                      : (
                        <option selected={false} key={index} value={value.id}>
                          {value.name}
                        </option>
                      )
                  })}
              </Form.Select>
              <Row className={style.errContainer}>
                {!errUserRole
                  ? null
                  : (
                    <span id='integer-error' className={style.Error}>
                      {errMsgUserRole}
                    </span>
                  )}
              </Row>

            </Col>
          </Row>
        </Form.Group>

        <hr />
      </>
    )
  }
}
export default DropDown
