import React, { useState, useEffect } from 'react'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Questions.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import Title from '../../../Components/Atoms/InputFields/Title'
import ApiService from '../../../Utiles/API.service'
import PageLoader from '../../../Components/Molecules/PageLoader/PageLoader'
import SaveBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/SaveBtn'
import CancelBtn from '../../../Components/Atoms/Buttons/CancelAndSaveButton/CancelBtn'
import Points from '../../../Components/Atoms/InputFields/Points'
import DropDown from '../../../Components/Atoms/InputFields/DropDown'
import Description from '../../../Components/Atoms/InputFields/Description'
import ImgUpload from '../../../Components/Atoms/InputFields/ImgUpload'
import Answer from '../../../Components/Molecules/Answer/Answer'
import { useNavigate } from 'react-router-dom'
import { editCategory,addBtnAccessHandler } from '../../../Helpers/Helper'
import { SearchableDropdown } from '../../../Components/Molecules/searchable-dropdown/SearchableDropdown'
import { toast } from 'react-toastify'

const EditQuestion = () => {
  const [description, setDescription] = useState('')
  const [errDescription, setErrDescription] = useState('')
  const [required, setRequired] = useState(true)
  const [questionTitle, setQuestionTitle] = useState('')
  const [errQuestionTitle, setErrQuestionTitle] = useState('')
  const [title, setTitle] = useState('')
  const [errTitle, setErrTitle] = useState('')
  const [points, setPoints] = useState(null)
  const [errPoints, setErrPoints] = useState('')
  const [showLoaderState, onShowLoaderState] = useState(false)
  const [objData, setObjData] = useState([])
  const [objDataDefault, setObjDataDefault] = useState([])
  const [objective, setObjective] = useState(null)
  const [errObjective, setErrObjective] = useState('')
  const [objectiveIsAr, setObjectiveIsAr] = useState(false)
  const [fileRight, setFileRight] = useState('')
  const [imageActionRight, setImageActionRight] = useState(false)
  const [imageRight, setImageRight] = useState('')
  const [questionImgFile, setQuestionImgFile] = useState()
  const [questionImg, setQuestionImg] = useState('')
  const [questionImageActionRight, setQuestionImageActionRight] = useState(false)
  const [fileWrong, setFileWrong] = useState('')
  const [imageActionWrong, setImageActionWrong] = useState(false)
  const [imageWrong, setImageWrong] = useState('')
  const [questionType, setQuestionType] = useState('')
  const [errQuestionType, setErrQuestionType] = useState('')
  const [metaData, setMetaData] = useState(null)
  const [answerListDefault, setAnswerListDefault] = useState([{ answer: '', is_true: false }])
  const [defaultObjective, setDefaultObjective] = useState('')
  const [allow, setAllow] = useState(false)
  const [errAnswer, setErrAnswer] = useState('')
  const [ansDisable, setAnsDisable] = useState(true)
  const navigate = useNavigate()
  const payload = null
  const headers = { Token: window.localStorage.getItem('authToken') }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  const data = JSON.parse(localStorage.getItem('UserData'))
  const user = data.id

  const qType = [
    { title: 'text', value: 'text' },
    { title: 'image', value: 'image' },
    { title: 'audio', value: 'audio' },
    { title: 'video', value: 'video' }
  ]

  const answerValidator = (arr) => {
    // for (let i = 0; i < arr.length; i++) {
    //   if (arr[i].answer === '') {
    //     setAllow(false)
    //     break
    //   } else if (arr[i].answer !== '') {
    //     setAllow(true)
    //   }
    // }
      if (arr.length >= 3) {
        let checkbox = arr.some((itm) => {
          return (itm.is_true === true)
        })
  
        if (checkbox === true) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].answer === '') {
              setAllow(false)
              break
            } else if (arr[i].answer !== '') {
              setAllow(true)
            }
          }
          setAnsDisable(false)
        } else {
          setAnsDisable(true)
        }
      } else {
        setAnsDisable(true)
      }
    
  }

  useEffect(() => {
    ApiService.get('api/v1/objectives/', payload, headers, showLoader, (responseData, errorData) => {
      setObjData(responseData)
    })
  }, [])
  useEffect(() => {
    ApiService.get(editCategory(), payload, headers, showLoader, (responseData, responseError) => {
      setDescription(responseData.see_more_description)
      setTitle(responseData.see_more_title)
      setQuestionTitle(responseData.question)
      setPoints(responseData.points)
      setDefaultObjective(responseData.objective)
      setMetaData(responseData.meta_data)
      setAnswerListDefault(responseData.meta_data !== undefined || null ? Object.keys(responseData.meta_data).map((key) => responseData.meta_data[key]) : null)
      setObjective(responseData.objective === null ? {} : {
        label: responseData.objective.title,
        value: responseData.objective.id
      })
      setQuestionType(responseData.type)
      if (responseData.question_file) {
        setObjectiveIsAr(true)
        setQuestionImg(responseData.question_file)
      }
    })
  }, [])
  useEffect(() => {
    answerValidator(answerListDefault)
  }, [answerListDefault])

  useEffect(() => {
    if (objectiveIsAr === false) {
      setQuestionImageActionRight(false)
      setQuestionImg('')
      setQuestionImgFile()
    }
  }, [objectiveIsAr])
  let uData = JSON.parse(localStorage.getItem('UserData'))
  const metaHandler = (answerList) => {
    setErrAnswer('')
    for (let i = 0; i < answerList.length; i++) {
      if (allow !== false) {
        if (answerList[i].answer !== '') {
          const result = answerList.reduce(
            (obj, cur, index) => ({
              ...obj,
              ['option_' + (index + 1)]: cur
            }),
            {}
          )
          setMetaData(result)
        }
      } else {
        setMetaData(null)
      }
    }
  }

  const metaString = metaData === null ? '' : JSON.stringify(metaData);
  const handelQuestionTitle = (e) => {
    setQuestionTitle(e.target.value)
    setErrQuestionTitle('')
  }

  // handel title event
  const handelTitle = (e) => {
    setTitle(e.target.value)
    setErrTitle('')
  }

  // handel points event
  const handelPoints = (e) => {
    setPoints(e)
    setErrPoints('')
  }

  // handel description event
  const handelDescription = (e) => {
    setDescription(e.target.value)
    setErrDescription('')
  }

  const apiCallWithoutImg = () => {
    if (!questionImgFile) {
      const payload = {
        question: questionTitle,
        see_more_title: title,
        see_more_description: description,
        points,
        type: questionType,
        objective: objective !== null && objective.value ? objective.value : null,
        meta_data: metaData === undefined ? null : metaData,
        user
      }
      ApiService.patch(editCategory(), payload, headers, showLoader, async (responseData, responseError) => {
        // if there are no errors in the patch call with both correct and wrong image
        if (!responseError) {
          navigate('/admin/question')
          setTitle('')
          setPoints('')
          setDescription('')
          toast.success('Question updated successfully', {
            autoClose: 4000
          })
          navigate('/admin/question')
        }
        // if there are errors then exception handling
        else if (responseError) {
          if (responseError.objective && responseError.objective.length !== 0) {
            setErrObjective(responseError.objective[0])
          }
          if (responseError.question && responseError.question.length !== 0) {
            setErrQuestionTitle(responseError.question[0])
          }
          if (responseError.see_more_title && responseError.see_more_title.length !== 0) {
            setErrTitle(responseError.see_more_title[0])
          }
          if (responseError.see_more_description && responseError.see_more_description.length !== 0) {
            setErrDescription(responseError.see_more_description[0])
          }
          if (responseError.points && responseError.points.length !== 0) {
            setErrPoints(responseError.points[0])
          }
          if (responseError.type && responseError.type.length !== 0) {
            setErrQuestionType(responseError.type[0])
          }
          if (responseError.meta_data && responseError.meta_data.length !== 0) {
            setErrAnswer(responseError.meta_data[0])
          }
        }
      })
    } else if (questionImgFile) {
      const formData = new FormData()
      formData.append('question_file', questionImgFile)
      formData.append('question', questionTitle)
      formData.append('objective', objective !== null && objective.value ? objective.value : '')
      formData.append('points', points)
      formData.append('type', questionType)
      formData.append('meta_data', metaString)
      formData.append('user', user)
      formData.append('see_more_title', title)
      formData.append('see_more_description', description)
      ApiService.patch(editCategory(), formData, headers, showLoader, async (responseData, responseError) => {
        // if there are no errors in the patch call with both correct and wrong image
        if (!responseError) {
          navigate('/admin/question')
          setTitle('')
          setPoints('')
          setDescription('')
          toast.success('Question updated successfully', {
            autoClose: 4000
          })
        }
        // if there are errors then exception handling
        else if (responseError) {
          if (responseError.objective && responseError.objective.length !== 0) {
            setErrObjective(responseError.objective[0])
          }
          if (responseError.question && responseError.question.length !== 0) {
            setErrQuestionTitle(responseError.question[0])
          }
          if (responseError.see_more_title && responseError.see_more_title.length !== 0) {
            setErrTitle(responseError.see_more_title[0])
          }
          if (responseError.see_more_description && responseError.see_more_description.length !== 0) {
            setErrDescription(responseError.see_more_description[0])
          }
          if (responseError.points && responseError.points.length !== 0) {
            setErrPoints(responseError.points[0])
          }
          if (responseError.type && responseError.type.length !== 0) {
            setErrQuestionType(responseError.type[0])
          }
          if (responseError.meta_data && responseError.meta_data.length !== 0) {
            setErrAnswer(responseError.meta_data[0])
          }
        }
      })
    }
  }
  // handel on submit event
  const handleSubmit = (e) => {
    e.preventDefault(),
      apiCallWithoutImg()
  }
  const handelChangesImage = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setQuestionImgFile(e.target.files[0])
      setQuestionImg(URL.createObjectURL(e.target.files[0]))
    }
    if (questionImgFile) {
      setQuestionImageActionRight(true)
    } else if (!questionImgFile) {
      setImageActionRight(false)
    }
  }

  const fetchObjectiveList = (inputValue, callback) => {
    if (!inputValue) {
      callback([])
    } else {
      const options = []
      objData.filter((e) => {
        if (e.title.toLowerCase().includes(inputValue.toLowerCase()))
          options.push({
            label: e.title,
            value: e.id,
            is_ar: e.is_ar
          })
        setTimeout(() => { callback(options) }, 1000)
      })
      callback[options]

    }
  }

  const objectiveDropdownHandler = (e) => {
    if (e !== null) {
      objectiveArHandler(e.is_ar)
    } else {
      objectiveArHandler(false)
    }
    setObjective(e)
    setErrObjective('')

  }

  const defaultObjectiveList = () => {
    objData.map((e) => {
      objDataDefault.push({
        label: e.title,
        value: e.id,
        is_ar: e.is_ar
      })
    })
  }
  useEffect(() => {
    defaultObjectiveList()
  }, [objData])

  const questionTypeDropdownHandler = (e) => {
    setQuestionType(e)
    setErrQuestionType('')
  }

  // handel cancel event
  const handleCancel = (e) => {
    e.preventDefault()
    navigate('/admin/question')
    setTitle('')
    setPoints('')
    setDescription('')
  }
  const objectiveArHandler = (e) => {
    return setObjectiveIsAr(e)
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Questions", path: "/admin/question" }, { title: "Edit Question", path: "/admin/question/:id/edit", disable: "true" }]} />
      <div className={Style.Container}>
        <Container className={Style.formContainer}>
          <SearchableDropdown label={'Experience'} fetchList={fetchObjectiveList} didSelect={objectiveDropdownHandler} Values={objective} defaultList={objDataDefault} required={required} Error={errObjective} />
          {objectiveIsAr === true ? <ImgUpload name="Image" onChange={handelChangesImage} imgSrc={questionImg} /> : null}
          <Title name="Question" title={questionTitle} onTitleChange={handelQuestionTitle} required={required} Error={errQuestionTitle} />

          <Title name="Select More Title" required={required} title={title} onTitleChange={handelTitle} Error={errTitle} />
          <Description name="Description" required={required} onDescriptionChange={handelDescription} description={description} Error={errDescription} />
          <DropDown name="Question type" data={qType} dropDefault={questionType} type="question-type" handler={questionTypeDropdownHandler} required={required} ErrorQType={errQuestionType} />
          <Points name="Points" points={points} required={required} onPointsChange={handelPoints} Error={errPoints} />
          <Answer metaHandler={metaHandler} required={required} answerList={answerListDefault} setAnswerListDefault={setAnswerListDefault} validator={answerValidator} Error={ansDisable === false ? errAnswer : "Minimum 3 Answers with atleast 1 true value required"} />

          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <CancelBtn handelCancel={handleCancel} />
              <SaveBtn handelSubmit={handleSubmit} disableAccess={ansDisable === false ? addBtnAccessHandler(uData, 'question') : ansDisable} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default EditQuestion
