import { React } from 'react'
import Table from 'react-bootstrap/Table'
import Styles from './Table.module.css'
import { EditBtn } from '../../Atoms/Buttons/EditButton/EditBtn'
import { ViewBtn } from '../../Atoms/Buttons/ViewButton/ViewBtn'
import { DeleteBtn } from '../../Atoms/Buttons/DeleteButton/DeleteBtn'
import { MarkButton } from '../../Atoms/Buttons/MarkButton/MarkButton'
import { Switch } from '../../Atoms/Buttons/Switch/Switch'
import { FeaturedButton } from '../../Atoms/Buttons/FeaturedButton/FeaturedButton'
import { editBtnHandler, editBtnHandlerMilestone, tableActionsAccessHandler } from '../../../Helpers/Helper'
import { Button } from 'react-bootstrap'
const TableOrganism = (props) => {
  let uData = JSON.parse(localStorage.getItem('UserData'))
  return (
    <>
      <Table className={Styles.tableContainer}>
        <thead>
          <tr className={Styles.tableHeadRow}>
            {props.headerList.map((heading, index) => {
              if (heading.value === 'Actions') {
                return (
                  <th key={index} style={{ paddingLeft: '1.3em' }}>
                    {heading.value}
                  </th>
                )
              } else if (heading.value === 'Logo') {
                return <th key={index}>{heading.value}</th>
              } else if (heading.value === 'AR') {
                return <th key={index}>{heading.value}</th>
              } else if (heading.value === 'Active') {
                return <th key={index}>{heading.value}</th>
              } else if (heading.value === 'Image') {
                return <th key={index}>{heading.value}</th>
              } else if (heading.value === 'Role') {
                return <th key={index}>{heading.value}</th>
              } else if (heading.value === 'Milestone') {
                return <th key={index}>{heading.value}</th>
              } else if (heading.value === 'Journeys Completed') {
                return (uData.is_admin === true || uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin' ? <th key={index}>{heading.value}</th> : null)
              } else if (heading.value === '#Completed') {
                return (uData.is_admin === true || uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin' ? <th key={index}>{heading.value}</th> : null)
              } else if (heading.value === '#Attempted') {
                return (uData.is_admin === true || uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin' ? <th key={index}>{heading.value}</th> : null)
              } else if (heading.value === '#Likes') {
                return <th key={index}>{heading.value}</th>
              } else {
                return (
                  <th key={index}>
                    <div style={{ display: 'flex' }}>
                      {heading.value}
                      <div className={Styles.mainDiv}>
                        <div className={Styles.divAsc}>
                          <Button className={Styles.ascbtn} onClick={() => props.onClicked(heading.type)}>
                            ▲
                          </Button>
                        </div>
                        <div className={Styles.divDesc}>
                          <Button className={Styles.dscbtn} onClick={() => props.onClick(heading.type)}>
                            ▼
                          </Button>
                        </div>
                      </div>
                    </div>
                  </th>
                )
              }
            })}
          </tr>
        </thead>
        {localStorage.getItem('header') === 'Rewards'
          ? (
            <tbody className={Styles.tableDataRow}>
              {props.data.map((data, index) => {
                return (
                  <tr className={Styles.tableDataRow} key={index}>
                    <td className={Styles.wrapText}>{!data.title ? null : data.title}</td>
                    <td> {!data.logo ? null : <img className={Styles.RowImage} src={data.logo} alt=""></img>}</td>

                    <td>{0 && data.points === 0 && !data.points ? null : data.points}</td>
                    <td className={Styles.wrapText}>{!data.description ? null : data.description}</td>
                    <td>
                      <div className={Styles.btns}>
                        {<EditBtn id={data.id} editBtnHandler={editBtnHandler} disableAccess={tableActionsAccessHandler(uData, data, "reward")} />}
                        {<DeleteBtn id={data.id} item={'Reward'} disableAccess={tableActionsAccessHandler(uData, data, "reward")} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )
          : null}
        {localStorage.getItem('header') === 'Stamps'
          ? (
            <tbody className={Styles.tableDataRow}>
              {props.data.map((data, index) => {
                return (
                  <tr className={Styles.tableDataRow} key={index}>
                    <td className={Styles.wrapText}>{!data.name ? null : data.name}</td>
                    <td>{!data.thumbnail_url ? null : <img className={Styles.RowImage} src={data.thumbnail_url} alt=""></img>}</td>
                    <td>
                      <div className={Styles.btns}>
                        {<EditBtn id={data.id} name={data.name} editBtnHandler={editBtnHandler} disableAccess={tableActionsAccessHandler(uData, data, "stamp")} />}
                        {<DeleteBtn id={data.id} item={'Stamp'} disableAccess={tableActionsAccessHandler(uData, data, "stamp")} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )
          : null}
        {localStorage.getItem('header') === 'Categories'
          ? (
            <tbody className={Styles.tableDataRow}>
              {props.data.map((data, index) => {
                return (
                  <tr className={Styles.tableDataRow} key={index}>
                    <td className={Styles.wrapText}>{!data.name ? null : data.name}</td>
                    {/*  */}
                    {uData.is_admin === true || uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin' ? (<td className={Styles.wrapText}>{0 && !data.journey_completed_count && data.journey_completed_count === 0 ? null : data.journey_completed_count}</td>) : null}
                    <td>
                      <div className={Styles.btns}>
                        {<EditBtn id={data.id} name={data.name} editBtnHandler={editBtnHandler} disableAccess={tableActionsAccessHandler(uData, data, "category")} />}
                        {<DeleteBtn id={data.id} item={'Category'} disableAccess={tableActionsAccessHandler(uData, data, "category")} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )
          : null}
        {localStorage.getItem('header') === 'Journeys'
          ? (
            <tbody className={Styles.tableDataRow}>
              {props.data.map((data, index) => {
                return (
                  <tr className={Styles.tableDataRow} key={index}>
                    <td className={Styles.wrapText}>{!data.title ? null : data.title}</td>
                    <td>{!data.movement ? null : data.movement}</td>
                    <td>{!data.is_active ? <Switch checked={data.is_active} active={true} id={data.id} disableAccess={tableActionsAccessHandler(uData, data, "journey")} /> : <Switch checked={data.is_active} active={true} id={data.id} disableAccess={tableActionsAccessHandler(uData, data, "journey")} />}</td>
                    <td>{!data.active_time ? null : data.active_time}</td>
                    {uData.is_admin === true || uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin' ? <td>{0 && !data.journey_completed_count
                      ? null : data.journey_completed_count
                    }</td> : null}
                    {uData.is_admin === true || uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Sub-Admin' ? <td>{0 && !data.journey_attempted_count
                      ? null : data.journey_attempted_count
                    }</td> : null}
                   <td>{data.likes >= 0 ? data.likes : 0 }</td>
                    <td>
                      <div className={Styles.btns}>
                        {<EditBtn id={data.id} editBtnHandler={editBtnHandler} disableAccess={tableActionsAccessHandler(uData, data, "journey")} />}
                        {<ViewBtn id={data.id} />}
                        {<DeleteBtn id={data.id} item={'Journey'} disableAccess={tableActionsAccessHandler(uData, data, "journey")} />}
                        {<FeaturedButton checked={data.is_featured} active={false} id={data.id} disableAccess={tableActionsAccessHandler(uData, data, "journey")} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>)
          : null}
        {localStorage.getItem('header') === 'Experiences'
          ? (
            <tbody className={Styles.tableDataRow}>
              {props.data.map((data, index) => {
                return (
                  <tr className={Styles.tableDataRow} key={index}>
                    <td className={Styles.wrapText}>{!data.title ? null : data.title}</td>
                    <td>{!data.journey ? null : data.journey.title}</td>
                    <td>{!data.is_ar ? <Switch checked={data.is_ar} active={false} id={data.id} disableAccess={tableActionsAccessHandler(uData, data, "experience")} /> : <Switch checked={data.is_ar} active={false} id={data.id} disableAccess={tableActionsAccessHandler(uData, data, "experience")} />}</td>
                    <td className={Styles.actionsContainer}>
                      <div className={Styles.btns}>
                        {<EditBtn id={data.id} editBtnHandler={editBtnHandler} disableAccess={tableActionsAccessHandler(uData, data, "experience")} />}
                        {<ViewBtn id={data.id} />}
                        {<DeleteBtn id={data.id} item={'Experience'} disableAccess={tableActionsAccessHandler(uData, data, "experience")} />}
                        {<MarkButton checked={data.is_retake} active={false} id={data.id} disable={true} disableAccess={tableActionsAccessHandler(uData, data, "experience")} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )
          : null}
        {localStorage.getItem('header') === 'Milestones'
          ? (
            <tbody className={Styles.tableDataRow}>
              {props.data.map((data, index) => {
                return (
                  <tr className={Styles.tableDataRow} key={index}>
                    <td className={Styles.wrapText}>{!data.title ? null : data.title}</td>
                    <td>{0 && data.points === 0 && !data.points ? null : data.points}</td>

                    <td className={Styles.actionsContainer}>
                      <div className={Styles.btns}>
                        {<EditBtn id={data.id} editBtnHandler={editBtnHandlerMilestone} disableAccess={tableActionsAccessHandler(uData, data, "milestone")} />}
                        {<DeleteBtn id={data.id} item={'Milestone'} disableAccess={tableActionsAccessHandler(uData, data, "milestone")} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )
          : null}
        {localStorage.getItem('header') === 'Questions'
          ? (
            <tbody className={Styles.tableDataRow}>
              {props.data.map((data, index) => {
                return (
                  <tr className={Styles.tableDataRow} key={index}>
                    <td className={Styles.wrapText}>{!data.question ? null : data.question}</td>
                    <td>{!data.objective ? null : data.objective.title}</td>
                    <td>{0 && data.points === 0 && !data.points ? null : data.points}</td>

                    <td className={Styles.actionsContainer}>
                      <div className={Styles.btns}>
                        {<EditBtn id={data.id} editBtnHandler={editBtnHandler} disableAccess={tableActionsAccessHandler(uData, data, "question")} />}
                        {<ViewBtn id={data.id} />}
                        {<DeleteBtn id={data.id} item={'Question'} disableAccess={tableActionsAccessHandler(uData, data, "question")} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )
          : null}
        {localStorage.getItem('header') === 'Users'
          ? (
            <tbody className={Styles.tableDataRow}>
              {props.data.map((data, index) => {
                return (
                  <tr className={Styles.tableDataRow} key={index}>
                    <td>{!data.profile_photo ? null : <img className={Styles.RowImage} src={data.profile_photo.thumbnail} alt=""></img>}</td>
                    <td className={Styles.wrapText}>{!data.first_name ? null : data.first_name}</td>
                    <td className={Styles.wrapText}>{!data.last_name ? null : data.last_name}</td>
                    <td>{!data.email ? null : data.email}</td>
                    {/* <td>{data.is_admin === true ? 'Admin' : 'User'}</td> */}
                    <td>
                      {data.is_admin === true ? 'Admin'
                        : (data.groups && data.groups.length !== 0 ? data.groups[0].name
                          : 'User'
                        )
                      }
                    </td>
                    <td className={Styles.tableMilestone}>{!data.milestone ? '-' : (data.milestone === null ? '-' : data.milestone)}</td>
                    <td className={Styles.tableMilestone}>{!data.score ? '-' : (data.score !== null ? data.score : '-')}</td>
                    <td className={Styles.actionsContainer}>
                      <div className={Styles.btns}>
                        {<EditBtn id={data.id} editBtnHandler={editBtnHandler} />}
                        {<ViewBtn id={data.id} />}
                        {<DeleteBtn id={data.id} item={'User'} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )
          : null}
      </Table>
    </>
  )
}

export default TableOrganism
