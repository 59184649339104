const authGuard = (login, toast) => {
  if (login !== true) {
    return toast.error("Invalid Credentials");
  } else {
    window.location.assign("./Home/Products");
  }
};

const passwordValidation = (values) => {
  let errors = "";
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (values.value === "") {
    errors = "Password is required";
  }
  return errors;
};

const newPasswordValidation = (values) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  let errors = [];
  if (values.value === "") {
    errors.push("new password is required");
  }
  if (values.value && !regex.test(values.value)) {
    if (values.value.length < 8) {
      errors.push("• A minimum length of 8 characters");
    }
    if (!/(?=.*[A-Z])/.test(values.value)) {
      errors.push("• Uppercase letter");
    }
    if (!/(?=.*[a-z])/.test(values.value)) {
      errors.push("• Lowercase letter");
    }
    if (!/(?=.*[@$!%*?&])/.test(values.value)) {
      errors.push("• Special character");
    }
    if (!/(?=.*\d)/.test(values.value)) {
      errors.push("• Numeric digit ");
    }
  }
  return errors.join(" ");
};
const confirmPasswordValidation = (values) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  let errors = [];
  if (values.value === "") {
    errors.push("Confirm password is required");
  }
  if (values.value && !regex.test(values.value)) {
    if (values.value.length < 8) {
      errors.push("• A minimum length of 8 characters");
    }
    if (!/(?=.*[A-Z])/.test(values.value)) {
      errors.push("• Uppercase letter");
    }
    if (!/(?=.*[a-z])/.test(values.value)) {
      errors.push("• Lowercase letter");
    }
    if (!/(?=.*[@$!%*?&])/.test(values.value)) {
      errors.push("• Special character");
    }
    if (!/(?=.*\d)/.test(values.value)) {
      errors.push("• Numeric digit ");
    }
  }
  return errors.join(" ");
};

const validateLogin = (values) => {
  let errors = "";
  const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

  if (values.value === "") {
    errors = "Email is required";
  } else if (!regex.test(values.value)) {
    errors = "Please Enter a valid Email !";
  }

  return errors;
};

const usernameValidation = (values) => {
  let errors = "";
  const regex = /^\S*$/;
  if (values.value === "") {
    errors = "username is required";
  } else if (!regex.test(values.value)) {
    errors = "Please Enter a valid username";
  }
  return errors;
};

const zipValidation = (values) => {
  let errors = "";

  if (values.value === "") {
    errors = "Zip code is required";
  }
  return errors;
};

const phoneNumberValidation = (values) => {
  let errors = "";
  const regex =
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;

  if (values.value === "") {
    errors = "Phone number is required";
  } else if (!regex.test(values.value)) {
    errors = "Please Enter a valid Phone number !";
  }
  return errors;
};

const requiredFields = (values) => {
  let errors = "";
  if (values.value === "") {
    errors = "this field is required";
  }
  return errors;
};
const delElement = (id) => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/${currentUrl[2]}/${id}/`;
  return endpoint;
};

const editUrl = (id) => {
  const currentUrl = window.location.pathname.split("/");

  const endpoint = `api/v1/${currentUrl[2]}/${id}/`;
  return endpoint;
};

const viewUrl = () => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/${currentUrl[2]}/${currentUrl[3]}`;
  return endpoint;
};
const viewExperienceUrl = () => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/web-${currentUrl[2]}/${currentUrl[3]}`;
  return endpoint;
};
const editCategory = () => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/${currentUrl[2]}/${currentUrl[3]}/`;
  return endpoint;
};
const editExperience = () => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/web-${currentUrl[2]}/${currentUrl[3]}/`;
  return endpoint;
};
const editMilestone = () => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/web-${currentUrl[2]}/${currentUrl[3]}/`;
  return endpoint;
};

const editUserDetails = () => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/web/${currentUrl[2]}/${currentUrl[3]}/`;
  return endpoint;
};

const editUser = () => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/web/user/${currentUrl[3]}/`;
  return endpoint;
};
const paginationUrl = () => {
  const currentUrl = window.location.pathname.split("/");
  const endpoint = `api/v1/${currentUrl[2]}`;
  return endpoint;
};

const editBtnHandler = (e, navigate, id) => {
  e.preventDefault();
  const url = window.location.pathname;
  navigate(`${url}/${id}/edit`);
  if (url.includes("journey/")) {
    navigate(`${url}${id}/edit`);
  } else {
    navigate(`${url}/${id}/edit`);
  }
  if (url.includes("objective/")) {
    navigate(`${url}${id}/edit`);
  } else {
    navigate(`${url}/${id}/edit`);
  }
};
const editBtnHandlerMilestone = (e, navigate, id) => {
  e.preventDefault();
  const url = window.location.pathname;

  if (url.includes("milestone/")) {
    navigate(`${url}${id}/edit`);
  } else {
    navigate(`${url}/${id}/edit`);
  }
};

const addBtnAccessHandler = (user_data, module) => {
  return !user_data
    ? true
    : user_data.is_admin === true
    ? false
    : user_data.groups && user_data.groups.length !== 0
    ? user_data.groups[0].name === "Destination Partner"
      ? false
      : //subadmin
      !user_data.user_permission
      ? true
      : !user_data.user_permission.permissions
      ? true
      : !user_data.user_permission.permissions[module]
      ? true
      : !user_data.user_permission.permissions[module].access_level
      ? true
      : user_data.user_permission.permissions[module].access_level === "partial"
      ? true
      : false
    : true; // if group is not available and is_admin is also false then to prevent white screen crash true will be the fall back value
};
const tableActionsAccessHandler = (user_data, table_obj_data, module) => {
  return !user_data
    ? true
    : user_data.is_admin === true
    ? false
    : user_data.groups && user_data.groups.length !== 0
    ? user_data.groups[0].name === "Destination Partner"
      ? !user_data.user_permission
        ? true
        : !user_data.user_permission.permissions
        ? true
        : !user_data.user_permission.permissions[module]
        ? true
        : !user_data.user_permission.permissions[module].access_level
        ? true
        : user_data.user_permission.permissions[module].access_level ===
          "partial"
        ? false
        : user_data.id === table_obj_data.created_by
        ? false
        : true
      : //subadmin
      !user_data.user_permission
      ? true
      : !user_data.user_permission.permissions
      ? true
      : !user_data.user_permission.permissions[module]
      ? true
      : !user_data.user_permission.permissions[module].access_level
      ? true
      : user_data.user_permission.permissions[module].access_level === "partial"
      ? true
      : false
    : true; // if group is not available and is_admin is also false then to prevent white screen crash true will be the fall back value
};

export {
  phoneNumberValidation,
  usernameValidation,
  newPasswordValidation,
  confirmPasswordValidation,
  passwordValidation,
  validateLogin,
  authGuard,
  editUrl,
  viewUrl,
  delElement,
  editCategory,
  editUserDetails,
  editUser,
  editBtnHandler,
  editBtnHandlerMilestone,
  requiredFields,
  paginationUrl,
  zipValidation,
  addBtnAccessHandler,
  tableActionsAccessHandler,
  editMilestone,
  editExperience,
  viewExperienceUrl,
};
