import { Nav, Navbar, Modal, Button } from 'react-bootstrap'
import { React, useState } from 'react'
import logoImg from '../../../Assets/logo.png'
import style from './Header.module.css'
const Header = ({ toggle, isActive, logOut }) => {
  // States for models
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const logoutHandler = () => {
    logOut()
    handleClose()
  }
  return (
    <>
      <Navbar className={style.navBar}>
        {/* Dashboard logo and label */}
        <Navbar.Brand href="/admin/home" onClick={() => localStorage.setItem('header', 'Home')}>
          <div className={style.reloader}>
            <img src={logoImg} alt="logo" className={style.logoImg} />
            <strong>Dashboard</strong>
            &nbsp;
          </div>
        </Navbar.Brand>
        <div className={style.spanBody}>
          <div onClick={toggle}>
            <span className={style.arrowTopBar}></span>
            {isActive === true
              ? (
                <div className={style.leftArrowDiv}>
                  <div className={style.leftArrow}></div>
                  <div className={style.horizontalLine}></div>
                </div>
              )
              : (
                <div className={style.rightArrowDiv}>
                  <div className={style.horizontalLine}></div>
                  <div className={style.rightArrow}></div>
                </div>
              )}
            <span className={style.arrowBottomBar}></span>
          </div>
        </div>
        {/* Setting dropdown button */}

        <Nav className="ms-auto">
          {/* Logout modal button */}
          <Nav.Link href="/admin/profile/" className={style.manageProfile} onClick={() => localStorage.setItem('header', 'Manage Profile')}>
            <span className={style.manageProfileLabel}>Manage Profile</span>
          </Nav.Link>
          <Nav.Link href="#" onClick={handleShow} className={style.logout}>
            <span className={style.logoutLabel}>Logout</span>
            <i className={[style.logoutIcon, 'fa fa-sign-out'].join(' ')} />
          </Nav.Link>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header className={style.popUpHeader} closeButton>
              <Modal.Body>
                <h4>Do you want to Logout?</h4>
              </Modal.Body>
            </Modal.Header>
            <Modal.Footer className={style.popUpFooter}>
              <Button variant="" className={style.btnSecondary} onClick={handleClose}>
                No
              </Button>
              <Button variant="" className={style.btnPrimary} onClick={logoutHandler}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </Nav>
      </Navbar>
    </>
  )
}
export default Header
