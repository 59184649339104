import { Button } from 'react-bootstrap'
import style from './Button.module.css'

const CancelBtn = ({ handelCancel }) => {
  return (
    <>
      <Button variant="danger" type="button" className={style.cancelBtn} onClick={handelCancel}>
        Cancel
      </Button>
    </>
  )
}

export default CancelBtn
