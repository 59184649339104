import { Routes, Route, useNavigate } from 'react-router-dom'
import Login from './Pages/Login/Login'
import PasswordReset from './Pages/PasswordReset/PasswordReset'
import ForgotPassword from './Pages/PasswordReset/ForgotPassword'
import HomeContainer from './Components/Templates/HomeContainer/HomeContainer'
import { useState, useEffect } from 'react'
import { ProtectedRoutes } from './Routes/ProtectedRoutes'
import { NotFound } from './Pages/NotFound/NotFound'
import { ToastContainer } from 'react-toastify'
import './App.module.css'
import { ConnectionLost } from './Pages/ConnectionLost/ConnectionLost'

const App = () => {
  const [isLoggedIn, setisLoggedIn] = useState(false)

  const navigate = useNavigate()

  const getData = (data) => {
    if (data) {
      localStorage.setItem('authToken', data.key)
      localStorage.setItem('header', 'Home')
      localStorage.setItem('UserData', JSON.stringify(data.user_data))
      navigate('/admin/home')
    } else {
      // err handling
    }
  }
  const logOut = () => {
    localStorage.clear()
    navigate('/')
  }
  const getOnLineStatus = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
      ? navigator.onLine
      : true

  const useNavigatorOnLine = () => {
    const [status, setStatus] = useState(getOnLineStatus())

    const setOnline = () => setStatus(true)
    const setOffline = () => setStatus(false)

    useEffect(() => {
      if (!!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
        // Just replace `https://` with `itms://` on your app's AppStore link.
        window.location.replace("itms://apps.apple.com/us/app/passport-atlanta/id1539488600");
      }
      else if (/Android/i.test(navigator.userAgent)) {
        window.location.replace("https://play.google.com/store/apps/details?id=com.passportatl.android");
      }
      window.addEventListener('online', setOnline)
      window.addEventListener('offline', setOffline)

      return () => {
        window.removeEventListener('online', setOnline)
        window.removeEventListener('offline', setOffline)
      }
    }, [])

    return status
  }
  const isOnline = useNavigatorOnLine()

  return (
    <>{isOnline
      ? <Routes>
        <Route path="/" element={<Login getData={getData} />}></Route>
        <Route path="/passwords/reset" element={<PasswordReset />}></Route>
        <Route path="/reset/password/:uid/:token" element={<ForgotPassword />}></Route>
        <Route path="/.well-known/apple-app-site-association"></Route>
        <Route path="/.well-known/assetlinks.json"></Route>
        <Route
          path="/admin/*"
          element={
            <ProtectedRoutes isLoggedIn={isLoggedIn}>
              <HomeContainer logOut={logOut} />
            </ProtectedRoutes>
          }></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      : <ConnectionLost />}

      <ToastContainer position="top-right" autoClose={2000} hideProgressBar={true} closeOnClick draggable={true} />
    </>
  )
}

export default App
