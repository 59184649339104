import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../../../Pages/Home/Home'
import FeedsRoute from '../../../Routes/FeedsRoute'
import RewardsRoute from '../../../Routes/RewardsRoute'
import StampsRoute from '../../../Routes/StampsRoute'
import CategoriesRoute from '../../../Routes/CategoriesRoute'
import JourneysRoute from '../../../Routes/JourneysRoute'
import ExperiencesRoute from '../../../Routes/ExperiencesRoute'
import QuestionsRoute from '../../../Routes/QuestionsRoute'
import MilestonesRoute from '../../../Routes/MilestonesRoute'
import UsersRoute from '../../../Routes/UsersRoute'
import ManageProfile from '../../../Pages/ManageProfile/ManageProfile'
import Styles from './PageDisplay.module.css'
import { NotFound } from '../../../Pages/NotFound/NotFound'
const PageDisplay = (props) => {
  return (
    <>
      <div className={!props.isOpen ? Styles.wrapper : Styles.wrapperActive}>
        <div className={Styles.routeWrapper}>
          <Routes>
            <Route path="home" element={<Home />}></Route>
            <Route path="profile" element={<ManageProfile />}></Route>
            <Route path="feed/*" element={<FeedsRoute />}></Route>
            <Route path="coupon/*" element={<RewardsRoute />}></Route>
            <Route path="stamp/*" element={<StampsRoute />}></Route>
            <Route path="category/*" element={<CategoriesRoute />}></Route>
            <Route path="journey/*" element={<JourneysRoute />}></Route>
            <Route path="objective/*" element={<ExperiencesRoute />}></Route>
            <Route path="question/*" element={<QuestionsRoute />}></Route>
            <Route path="milestone/*" element={<MilestonesRoute />}></Route>
            <Route path="user/*" element={<UsersRoute />}></Route>
            <Route path="profile/*" element={<ManageProfile />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </div>
      </div>
    </>
  )
}

export default PageDisplay
