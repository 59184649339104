import React, { useState, useEffect } from 'react'
import TableCard from '../../Components/Organisms/TableCard/TableCard'
import { AddButton } from '../../Components/Atoms/Buttons/AddButton/AddButton'
import { DownloadButton } from '../../Components/Atoms/Buttons/DownloadButton/DownloadButton'
import ApiService from '../../Utiles/API.service'
import Style from './Milestones.module.css'
import FormTitle from '../../Components/Molecules/FormTitle/FormTitle'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'
import { addBtnAccessHandler } from '../../Helpers/Helper'
import Tooltip from '../../Components/Molecules/Tooltip/Tooltip'
const HeaderList = [{ value: 'Name', type: 'title' }, { value: 'Points', type: 'points' }, { value: 'Actions' }]

const Milestones = () => {
  const [list, setList] = useState('')
  const [milestoneData, setMilestoneData] = useState({ results: [] })
  const [pagination, setPagination] = useState([])
  const [pageCount, setpageCount] = useState(0)
  const [page_size, setPage_Size] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [endIndex, setEndIndex] = useState(1)
  const [startIndex, setStartIndex] = useState(0)
  const [totalItems, setTotalItems] = useState('')
  const [showLoaderState, onShowLoaderState] = useState(false)
  const searchHandler = (event) => {
    setList(event)
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  useEffect(() => {
    if (uData.is_admin === false && uData.groups && uData.groups.length !== 0 && uData.groups[0].name === 'Destination Partner') {
      window.location.replace('/admin/home/')
    }
  }, [])
  const payload = { search: list }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  useEffect(() => {
    localStorage.setItem('header', 'Milestones')
  })
  const header = { Token: window.localStorage.getItem('authToken') }
  useEffect(() => {
    const getPaginateData = async () => {
      await ApiService.get('api/v1/web-milestone/', payload, header, showLoader, (responseData, errorData) => {
        try {
          setMilestoneData(responseData)
          setTotalItems(responseData.count)
          const total = responseData.count
          setpageCount(Math.ceil(total / page_size))
        } catch {
          return errorData
        }
      })
    }
    getPaginateData()
  }, [list])

  const getMilestoneData = async (currentPage) => {
    const response = await ApiService.get(`api/v1/web-milestone/?page=${currentPage}&page_size=${page_size}`, payload, header, showLoader, (responseData, errorData) => {
      setMilestoneData(responseData)
      return milestoneData
    })
  }
  const handlePageClick = async (total) => {
    const currentPage = total.selected + 1

    const commentsFormServer = await getMilestoneData(currentPage)

    setPagination(commentsFormServer)
    setStartIndex((currentPage - 1) * milestoneData.results.length + 1)
    setEndIndex((currentPage - 1) * milestoneData.results.length + milestoneData.results.length)
  }

  const sIndex = (currentPage - 1) * milestoneData.results.length
  const eIndex = sIndex + milestoneData.results.length
  useEffect(() => {
    setEndIndex(eIndex)
    setStartIndex(sIndex)
  }, [eIndex, sIndex])
  const pageHandler = (e) => {
    setPage_Size(e)
  }

  const sortingAsc = (type) => {
    ApiService.get(`api/v1/web-milestone/?ordering=${type}`, payload, header, showLoader, (responseData, errorData) => {
      setMilestoneData(responseData)
    })
  }

  const sortingDesc = (type) => {
    ApiService.get(`api/v1/web-milestone/?ordering=-${type}`, payload, header, showLoader, (responseData, errorData) => {
      setMilestoneData(responseData)
    })
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Milestones", path: "/admin/milestone/", disable: "true" }]} />
      <div className={Style.Container}>
        <div className={Style.tableHead}>
          <AddButton url="create" disableAccess={addBtnAccessHandler(uData, 'milestone')} />
          <Tooltip direction="bottom" content={"Download Milestones"}>
            <DownloadButton searchQuery={list} />
          </Tooltip>
        </div>
        <TableCard
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalItems}
          data={milestoneData.results}
          searchHandler={searchHandler}
          headerList={HeaderList}
          handler={pageHandler}
          totalItems={totalItems}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          sortAsc={sortingAsc}
          sortDesc={sortingDesc}
          selectedPage={currentPage}
        />
      </div>
    </>
  )
}

export default Milestones
