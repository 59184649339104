import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Stamps from '../Pages/Stamps/Stamps'
import AddStamps from '../Pages/Stamps/Add-Stamps/AddStamp'
import EditStamps from '../Pages/Stamps/Edit/EditStamp'
import { NotFound } from '../Pages/NotFound/NotFound'
const StampsRoute = () => {
  return (
    <>
      <Routes>
        <Route index element={<Stamps />}></Route>
        <Route path="create" element={<AddStamps />}></Route>
        <Route path=":id/edit" element={<EditStamps />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  )
}

export default StampsRoute
