import { React, useEffect, useState } from 'react'
import { AddButton } from '../../Components/Atoms/Buttons/AddButton/AddButton'
import { DownloadButton } from '../../Components/Atoms/Buttons/DownloadButton/DownloadButton'
import TableCard from '../../Components/Organisms/TableCard/TableCard'
import apiServices from '../../Utiles/API.service'
import Style from './Stamps.module.css'
import FormTitle from '../../Components/Molecules/FormTitle/FormTitle'
import PageLoader from '../../Components/Molecules/PageLoader/PageLoader'
import { addBtnAccessHandler } from '../../Helpers/Helper'
import Tooltip from '../../Components/Molecules/Tooltip/Tooltip'
const HeaderList = [{ value: 'Name', type: 'name' }, { value: 'Image', type: 'thumbnail_url' }, { value: 'Actions' }]

const Stamps = () => {
  const [list, setList] = useState('')
  const [stampsData, setStampsData] = useState({ results: [{}] })
  const [pagination, setPagination] = useState([])
  const [pageCount, setpageCount] = useState(0)
  const [page_size, setPage_Size] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [endIndex, setEndIndex] = useState(1)
  const [startIndex, setStartIndex] = useState(0)
  const [totalItems, setTotalItems] = useState('')
  const [showLoaderState, onShowLoaderState] = useState(false)

  const searchHandler = (event) => {
    setList(event)
  }

  const payload = { search: list }
  const showLoader = (val) => {
    onShowLoaderState(val)
  }
  let uData = JSON.parse(localStorage.getItem('UserData'))
  const headers = { Token: window.localStorage.getItem('authToken') }
  useEffect(() => {
    localStorage.setItem('header', 'Stamps')
  })
  useEffect(() => {
    const getPaginateData = async () => {
      await apiServices.get('api/v1/stamp/', payload, headers, showLoader, (responseData, errorData) => {
        try {
          setStampsData(responseData)
          setTotalItems(responseData.count)
          const total = responseData.count
          setpageCount(Math.ceil(total / page_size))
        } catch {
          return errorData
        }
      })
    }
    getPaginateData()
  }, [list])

  const sortingAsc = (type) => {
    apiServices.get(`api/v1/stamp/?ordering=${type}`, payload, headers, showLoader, (responseData, errorData) => {
      setStampsData(responseData)
    })
  }

  const sortingDesc = (type) => {
    apiServices.get(`api/v1/stamp/?ordering=-${type}`, payload, headers, showLoader, (responseData, errorData) => {
      setStampsData(responseData)
    })
  }

  const getStampsData = async (currentPage) => {
    await apiServices.get(`api/v1/stamp/?page=${currentPage}&page_size=${page_size}`, payload, headers, showLoader, (responseData, errorData) => {
      setStampsData(responseData)
      return stampsData
    })
  }

  const handlePageClick = async (total) => {
    const currentPage = total.selected + 1

    const commentsFormServer = await getStampsData(currentPage)
    setPagination(commentsFormServer)
    setStartIndex((currentPage - 1) * stampsData.results.length + 1)
    setEndIndex((currentPage - 1) * stampsData.results.length + stampsData.results.length)
  }

  useEffect(() => {
    const sIndex = (currentPage - 1) * stampsData.results.length
    const eIndex = sIndex + stampsData.results.length
  }, [page_size])

  const sIndex = (currentPage - 1) * stampsData.results.length
  const eIndex = sIndex + stampsData.results.length

  useEffect(() => {
    setEndIndex(eIndex)
    setStartIndex(sIndex)
  }, [eIndex, sIndex])

  const pageHandler = (e) => {
    setPage_Size(e)
  }

  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <FormTitle title={[{ title: "Stamps", path: '/admin/stamp', disable: "true" }]} />
      <div className={Style.Container}>
        <div className={Style.tableHead}>
          <AddButton url="create" disableAccess={addBtnAccessHandler(uData, 'stamp')} />
          <Tooltip direction="bottom" content={"Download Stamps"}><DownloadButton searchQuery={list} /></Tooltip>
        </div>
        <TableCard
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalItems}
          data={stampsData.results}
          searchHandler={searchHandler}
          headerList={HeaderList}
          handler={pageHandler}
          totalItems={totalItems}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          sortAsc={sortingAsc}
          sortDesc={sortingDesc}
          selectedPage={currentPage}
        />
      </div>
    </>
  )
}

export default Stamps
