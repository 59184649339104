import React from 'react'
import style from '../Footer/Footer.module.css'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className="text-center">Passport Atlanta &copy;{new Date().getFullYear()}&nbsp;</div>
    </div>
  )
}

export default Footer
