import { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import style from './Atoms.module.css'

const DragDrop = ({ name, removeImage, image, imageAction, onChange, onDrag, file, onDrop, dragAction, isEdit, required, Error }) => {
  const [detailShow, setDetailShow] = useState(false)
  const [val, setVal] = useState('')
  const [error, setError] = useState(false)
  const [errMsg, setErrMsg] = useState()
  useEffect(() => {
    if (Error !== '') {
      setError(true)
      setErrMsg(Error)
    } else {
      setError(false)
    }
  }, [Error])
  const imgFile = file
  const imgSize = imgFile.size / 1000
  const handleHover = (val) => {
    setDetailShow(val)
  }

  return (
    <>
      {/* Logo option, journey dropdown */}
      <Form.Group className={style.logoRow} onDragEnter={onDrag} onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col md={2}>
            <p className={style.logoLabel}>
              {name}
              {!required
                ? null
                : (
                  <span id="email-error" className={style.errorAsterisk}>
                    *
                  </span>
                )}{' '}
            </p>
          </Col>
          <Col>
            <Col md={12}>
              <div className={style.dropZone}>
                <div className={imageAction ? style.dzHiddensingleimg : style.dzInput}>
                  <h6 className={style.dzText}>Drop files here or click to upload</h6>

                  <input
                    type="file"
                    accept="image/*"
                    multiple={false}
                    value={val}
                    onChange={(e) => {
                      onChange(e)
                      setError(false)
                    }}
                    className={style.dzHide}
                  />
                </div>
                  <div className={imageAction ? style.dzImagePreview : style.dzHiddensingleimg}>
                    <div className={style.dzImage}>
                      <img className={style.logoUpload} src={image} alt={image} onMouseOver={() => handleHover(true)} onMouseLeave={() => handleHover(false)} />
                      {imgFile.size && (
                        <div>
                          {detailShow && (
                            <div className={style.dzDetail} onMouseOver={() => handleHover(true)}>
                              <span>
                                <strong className={style.imgSize}>{imgSize} KB</strong>
                              </span>
                              <br />
                              <span className={style.imgName}>{imgFile.name}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className={style.removeImage}>
                      <Link
                        to="#"
                        className={style.removeLink}
                        onClick={(e) => {
                          return (setVal(''), removeImage(e))
                        }}>
                        Remove image
                      </Link>
                    </div>
                  </div>
              </div>
            </Col>
            <Col className={style.editImageCol}>
              <img src={image} alt={imgFile.name} className={isEdit ? style.editImage : style.isEdit} />
            </Col>
            <Row className={style.errContainer}>
              {!error
                ? null
                : (
                  <span id='integer-error' className={style.Error}>
                    {errMsg}
                  </span>
                )}
            </Row>
          </Col>
        </Row>
      </Form.Group>
      <hr />
    </>
  )
}
export default DragDrop
