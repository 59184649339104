import React from 'react'
import NewCategories from '../../../Components/Organisms/AddNewCategories/NewCategories'
import FormTitle from '../../../Components/Molecules/FormTitle/FormTitle'
import Style from '../Category.module.css'
const AddCategory = () => {
  return (
    <>
      <FormTitle title={[{ title: "Categories", path: "/admin/category" }, { title: "Add New Category", path: "/admin/category/create", disable: "true" }]} />
      <div className={Style.Container}>
        <NewCategories />
      </div>
    </>
  )
}

export default AddCategory
