import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Styles from './FeaturedButton.module.css'
import ApiService from '../../../../Utiles/API.service'
import PageLoader from '../../../Molecules/PageLoader/PageLoader'
export const FeaturedButton = (props) => {
  const [checked, setChecked] = useState(false)
  const [disable, setDisable] = useState(false)
  const [showLoaderState, onShowLoaderState] = useState(false)
  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])
  const url = window.location.pathname.split('/')
  const headers = { Token: window.localStorage.getItem('authToken') }
  const markHandler = (data) => {
    const endpoint = `api/v1/${url[2]}/${props.id}/`
    const showLoader = (val) => {
      onShowLoaderState(val)
    }
    setChecked(!data)
    const payloadActive = { is_featured: !data }
    ApiService.patch(
      endpoint,
      payloadActive,
      headers,
      showLoader,
      (responseData, errorData) => {
        if (responseData) {
          setDisable(false)
        }
        else if (errorData) {
          setDisable(false)
        }
      }
    )
  }
  return (
    <>
      {showLoaderState && <PageLoader show={showLoaderState} />}
      <Button
        className={checked ? Styles.btnActive : Styles.btn}
        disabled={props.disableAccess?props.disableAccess:disable}
        onClick={() => {
          return (setDisable(true),
            markHandler(checked))
        }}>
        <i className='fa fa-trophy'></i>
      </Button>
    </>
  )
}
